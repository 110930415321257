/*! _app-friends.scss | Friendkit | © Css Ninja. 2020-2021 */

/* ==========================================================================
Friend lists
========================================================================== */

/*
    0. Layout
    1. Cards
    3. Media Queries
*/

/* ==========================================================================
0. Layout
========================================================================== */

.friends-wrapper {
    position: relative;
    margin: 0 auto;
    top: 40px;
    padding: 0 6%;
    min-height: calc(100vh - 280px);

    &.is-static {
        top: 0;
    }

    &.has-sidebar {
        .card-row {
            .friend-card {
                width: calc(33.3% - 16px);
            }
        }
    }

    .card-row-wrap {
        display: none;

        &.is-active {
            display: block;
        }
    }

    .card-row-placeholder {
        min-height: calc(100vh - 280px);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .card-row {
        margin-top: 20px;
        padding-bottom: 60px;
    }
}

/* ==========================================================================
1. Cards
========================================================================== */

.friend-card {
    position: relative;
    border-radius: 8px;
    padding: 20px;
    transition: box-shadow .3s;

    &.has-shapes {
        overflow: hidden;

        .shape {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            display: none;
            z-index: 0;
        }
    }

    &:hover {
        box-shadow: $light-box-shadow;
    }

    .shape {
        display: none;
    }

    .star-friend {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 44px;
        width: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        cursor: pointer;

        &.is-active {
            svg {
                transform: rotate(360deg);
                stroke: $primary;
                fill: $primary;
            }
        }

        svg {
            height: 20px;
            width: 20px;
            stroke: $placeholder;
            fill: $placeholder;
            transition: all .3s;
            transform: rotate(0);
        }
    }

    .img-container {
        position: relative;
        height: 90px;
        width: 90px;
        margin: 10px auto;
        z-index: 1;

        .avatar {
            display: block;
            border-radius: 50%;

            &.is-placeholder {
                filter: grayscale(1);
                opacity: .3;
            }
        }

        .country {
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
            height: 26px;
            width: 26px;
            border-radius: 50%;
            border: 3px solid $white;
        }
    }

    .friend-info {
        text-align: center;

        h3 {
            font-family: 'Montserrat', sans-serif;
            font-weight: 500;
            font-size: .9rem;
            color: $dark-text;
            line-height: 1;
            transition: color .3s;
        }

        p {
            font-family: Roboto, sans-serif;
            font-size: .85rem;
            color: $muted-grey;
        }
    }

    .friend-stats {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        .stat-block {
            text-align: center;
            padding: 0 20px;

            &:nth-child(2) {
                border-left: 1px solid darken($fade-grey, 3%);
                border-right: 1px solid darken($fade-grey, 3%);
            }

            label {
                display: block;
                text-transform: uppercase;
                font-family: Roboto, sans-serif;
                font-weight: 500;
                font-size: .6rem;
                color: $muted-grey;
            }

            .stat-number {
                font-family: Montserrat, sans-serif;
                font-weight: 600;
                font-size: 1rem;
                color: $dark-text;
            }
        }
    }

    .friend-actions {
        display: flex;
        justify-content: center;
        padding-top: 30px;

        .button {
            margin: 0 4px;

            &:hover {
                svg {
                    stroke: $dark-text;
                }
            }

            svg {
                height: 16px;
                width: 16px;
                stroke: $medium-grey;
                transition: stroke .3s;
            }
        }
    }
}

/* ==========================================================================
3. Media Queries
========================================================================== */

@media screen and (max-width: 1300px) {
    .friends-wrapper {
        padding: 0 !important;
    }
}

@media screen and (max-width: 1100px) {
    .friends-wrapper {
        .card-row {
            .friend-card {
                width: calc(33.3% - 16px);
            }
        }
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .friends-wrapper {
        &.has-sidebar {
            .card-row {
                .friend-card {
                    width: calc(100% - 16px) !important;
                }
            }
        }

        .card-row {
            .friend-card {
                width: calc(50% - 16px);

                .friend-actions {
                    justify-content: center !important;
                }
            }
        }
    }

    .options-nav {
        .is-search {
            max-width: 180px !important;
        }
    }
}

@media screen and (max-width: 767px) {
    .friends-wrapper {
        .card-row {
            .friend-card {
                width: calc(50% - 16px);

                .friend-actions {
                    justify-content: center !important;
                }
            }
        }
    }

    .options-nav {
        .is-friends {
            #show-filters {
                display: none !important;
            }

            .is-page-title h2 {
                font-size: .9rem;
            }

            .end-group {
                display: none !important;
            }
        }
    }
}

@media screen and (max-width: 560px) {
    .friends-wrapper {
        .card-row {
            .friend-card {
                width: calc(100% - 16px);
            }
        }
    }
}
