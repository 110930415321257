/*! _toolbar.scss | Friendkit | © Css Ninja. 2020-2021 */

/* ==========================================================================
Toolbar styles
========================================================================== */

/*
    0. Toolbar v1
    1. Toolbar v1 fixed
    2. Media Queries
*/

/* ==========================================================================
0. Toolbar v1
========================================================================== */

.toolbar-v1 {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1040px;
    margin: 0 auto 1.5rem auto;
    z-index: 9;

    &.is-narrow {
        margin: 0 auto;
    }

    .friendkit-hamburger {
        margin-right: 1rem;
    }

    h1 {
        font-family: $font-main;
        font-size: 1.5rem;
        font-weight: 700;
        color: $dark-text;
    }

    .controls {
        margin-left: auto;
        display: flex;
        align-items: center;
    }
}

/* ==========================================================================
1. Toolbar v1 fixed
========================================================================== */

.toolbar-v1-fixed-wrap {
    position: fixed;
    top: 10px;
    left: 0;
    width: 100%;
    margin-left: 280px;
    width: calc(100% - 280px);
    padding-left: 2rem;
    padding-right: 2rem;
    transform: translateY(-100%) translateY(-15px);
    opacity: 0;
    transition: all .3s;
    z-index: 9;

    &.is-fold {
        width: 100%;
        margin-left: 0;
    }

    &.is-active {
        transform: translateY(0);
        opacity: 1;
    }

    .toolbar-v1 {
        background: $white;
        padding: .5rem 1.5rem;
        border-radius: 1rem;
        border: 1px solid darken($fade-grey, 3%);
        box-shadow: $medium-box-shadow;
        max-width: 940px;

        h1 {
            font-size: 1.25rem;
        }
    }
}

/* ==========================================================================
2. Media Queries
========================================================================== */

@media only screen and (max-width: 767px) {

    .toolbar-v1-fixed-wrap {
        padding-left: .25rem;
        padding-right: .25rem;
        width: 100% !important;
        margin-left: 0 !important;
    }

    .toolbar-v1 {
        h1 {
            display: none;
        }
    }

    /*.toolbar-v1-fixed-wrap, .toolbar-v1 {
        .navbar-item {

            &.is-cart, &.is-account {
                padding-left: 6px !important;
                padding-right: 6px !important;
            }

            &.is-cart {
                .shopping-cart {
                    position: fixed;
                    top: 95px;
                    right: 0 !important;
                    left: 0 !important;
                    margin: 0 auto !important;

                    &:before, &:after {
                        left: 78% !important;
                    }
                }
            }
        }

        .nav-drop {
            position: fixed !important;
            top: 75px !important;
            left: 10px !important;
            right: 0 !important;
            width: calc(100% - 20px) !important;

            &:before, &:after {
                right: 46% !important;
            }
        }
    }*/
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .toolbar-v1-fixed-wrap {
        padding-left: 2rem;
        padding-right: 2rem;
        width: 100% !important;
        margin-left: 0 !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .toolbar-v1-fixed-wrap {
        margin-left: 250px;
        width: calc(100% - 250px);
        padding-left: 2rem;
        padding-right: 2rem;
    }
}