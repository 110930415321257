/*! _navbar.scss | Friendkit | © Css Ninja. 2020-2021 */

/* ==========================================================================
Navbar options
========================================================================== */

/*
    0. Navbar options
*/

/* ==========================================================================
0. Navbar options
========================================================================== */

.options-nav {
    position: fixed;
    top: 58px;
    left: 0;
    width: 100%;
    height: 50px;
    background: $white;
    border-bottom: 1px solid $fade-grey;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    z-index: 5;
    transition: all .3s;

    &.no-shadow {
        box-shadow: none;
    }

    .nav-inner {
        display: flex;

        &.is-friends, &.is-events {
            .option-tabs {
                position: relative;
                display: flex;
                width: 210px;

                .option-tab {
                    height: 50px;
                    width: 33.3%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &.is-active {
                        span {
                            font-weight: 500;
                            color: $dark-text;
                        }

                        &:first-child {
                            ~ .option-naver {
                                margin-left: 0;
                            }
                        }

                        &:nth-child(2) {
                            ~ .option-naver {
                                margin-left: 33.3%;
                            }
                        }

                        &:nth-child(3) {
                            ~ .option-naver {
                                margin-left: 66.6%;
                            }
                        }
                    }

                    span {
                        display: block;
                        color: $placeholder;
                        font-family: Roboto, sans-serif;
                        font-size: .9rem;
                    }
                }

                .option-naver {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 2.6px;
                    width: 33.3%;
                    border-radius: 4px;
                    background: $dark-text;
                    transition: all .3s;
                }
            }

            .end-group {
                position: relative;
                display: flex;
                justify-content: flex-end;
                margin-left: auto;

                .is-icon {
                    border-left: 1px solid darken($fade-grey, 3%);

                    svg {
                        stroke: $muted-grey;
                    }
                }

                .is-friend-count {
                    padding: 0 20px;
                    color: $muted-grey;
                    font-family: Roboto, sans-serif;
                    font-size: .95rem;
                }
            }
        }

        .nav-item {
            height: 50px;
            display: flex;
            align-items: center;

            &.is-page-title {
                padding: 0 20px;
                border-left: 1px solid darken($fade-grey, 3%);
                border-right: 1px solid darken($fade-grey, 3%);

                h2 {
                    font-family: Montserrat, sans-serif;
                    font-weight: bold;
                    font-size: 1rem;
                    color: $dark-text;
                }
            }

            &.is-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50px;

                svg {
                    height: 18px;
                    width: 18px;
                    stroke: $muted-grey;
                }
            }

            &.is-search {
                border-left: 1px solid darken($fade-grey, 3%);
                width: 280px;

                .control {
                    padding: 0 16px;
                    width: 100%;

                    input {
                        border: none;
                    }
                }
            }
        }
    }
}