/*! _groups.scss | Friendkit | © Css Ninja. 2020-2021 */

/* ==========================================================================
Chat window styles
========================================================================== */

/*
    0. Groups grid
    1. Media Queries
*/

/* ==========================================================================
0. Groups grid
========================================================================== */

.groups-grid {
    padding: 2rem 0;
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;

    .grid-header {
        margin-bottom: 1.5rem;
            
        .header-inner {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h2 {
                font-family: $font-main;
                font-size: 1.5rem;
                font-weight: 700;
                color: $dark-text;
            }
        }
    }

    .group-box {
        margin-right: 25px;
        transition: all .4s cubic-bezier(0.175, 0.885, 0, 1);
        background-color: $white;
        width: 100%;
        position: relative;
        border-radius: 12px;
        overflow: hidden;
        box-shadow: $light-box-shadow;
        border: 1px solid darken($fade-grey, 2%);

        &:hover {
            box-shadow: 0px 30px 18px -8px rgba(0, 0, 0,0.1);
            transform: scale(1.05, 1.05);

            .box-info {
                background-color: transparent;
                position: relative;
            }

            .box-info-hover {
                opacity: 1;
            }

            .box-img--hover {
                height: 100%;
                opacity: 0.2;
            }

            .box-category {
                color: $dark-text;
            }
        }

        .box-like {
            width: 18px;
        }
        
        .box-clock {
            width: 15px;
            vertical-align: middle;
            stroke: $dark-text;
        }
        .box-time {
            font-size: 12px;
            color: $dark-text;
            vertical-align: middle;
        }
        
        .box-clock-info {
            float: right;
        }
        
        .box-img {
            visibility: hidden;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            width: 100%;
            height: 235px;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
          
        }
        
        .box-info-hover {
            position: absolute;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px;
            width: 100%;
            opacity: 0;
            z-index: 1;

            > svg {
                height: 18px;
                width: 18px;
                transition: stroke .3s, fill .3s;
                cursor: pointer;

                &:hover {
                    fill: $danger;
                    stroke: $danger;
                }
            }
        }
        
        .box-img--hover {
            transition: 0.2s all ease-out;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            width: 100%;
            position: absolute;
            height: 235px;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            top: 0;
            transition: all .3s;
        }
        
        
        .box-info {
            z-index: 2;
            background-color: $white;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            padding: 16px 24px 24px 24px;
        }
        
        .box-category {
            font-family: $font;
            text-transform: uppercase;
            font-size: .7rem;
            letter-spacing: 2px;
            color: $light-text;
        }
        
        .box-title {
            margin-top: 5px;
            margin-bottom: 10px;
            font-family: $font-main;
            font-weight: 600;
            color: $dark-text;
            line-height: 1.2;
        }
        
        .box-members {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;
            font-family: $font;
            font-weight: 500;

            > a {
                font-weight: 500;
                text-decoration: none;
                color: $accent;
            }

            .members-preview {
                img {
                    display: inline-block;
                    height: 28px;
                    width: 28px;
                    min-width: 28px;
                    border-radius: 50%;
                    margin-left: .25rem;
                }
            }
        }
        
    }
}


/* ==========================================================================
1. Media Queries
========================================================================== */

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .groups-grid {
        .columns {
            display: flex;

            .column {
                min-width: 50%;
                width: 50%;
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .groups-grid {
        .columns {
            .column {
                min-width: 33.3%;
                width: 33.3%;
            }
        }

        .group-box {
            .box-title {
                max-width: 230px;
            }
        }
    }
}