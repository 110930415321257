/*! _modals.scss | Friendkit | © Css Ninja. 2020-2021 */

/* ==========================================================================
Modal styles
========================================================================== */

/*
    1. Base class
    2. Create group modal
    3. Albums Help modal
    4. Albums modal
    5. Videos modal
    6. Change cover and profile picture modals
    7. User photos modal
    8. Crop profile picture modal
    9. End tour modal
    10. No stream modal
    11. Media Queries
    12. Confirm dialog
*/

//import mixins
@import "../abstracts/mixins";

/* ==========================================================================
1. Base class
========================================================================== */

.modal {
  z-index: 1050;

  & .modal-background {
    transition: all 0.3s;
  }

  &.is-xsmall {
    .modal-content {
      width: 480px;
    }
  }

  &.is-small {
    .modal-content {
      width: 540px;
    }
  }

  &.is-medium {
    .modal-content {
      width: 720px;
    }
  }

  &.is-large {
    .modal-content {
      width: 840px;
    }
  }

  &.is-xlarge {
    .modal-content {
      width: 1200px;
    }
  }

  &.is-xxl {
    .modal-content {
      width: 1340px;
    }
  }

  &.is-light-bg {
    .modal-background {
      background: rgba(0, 0, 0, 0.4);
    }
  }

  .card {
    animation: modalScale 0.3s;
    margin-bottom: 0;

    .card-heading {
      display: flex;
      align-items: center;
      padding: 8px 12px;

      h3 {
        font-size: 0.95rem;
        font-weight: 500;
      }

      .close-wrap {
        height: 30px;
        width: 30px;
        margin-left: auto;

        .close-modal {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          width: 30px;
          border-radius: 50%;
          background: transparent;
          cursor: pointer;
          transition: background 0.3s;

          &:hover {
            background: lighten($placeholder, 10%);
          }

          svg {
            height: 18px;
            width: 18px;
            stroke: $medium-text;
          }
        }
      }
    }

    .card-body {
      padding: 8px 12px;
    }
  }
}

/* ==========================================================================
2. Create group modal
========================================================================== */

.create-group-modal {
  .card-heading {
    border-bottom: 1px solid $fade-grey;
  }

  .subheading {
    display: flex;
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid $fade-grey;

    .group-avatar {
      position: relative;
      cursor: pointer;

      input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
      }

      .add-photo {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 42px;
        width: 42px;
        background: lighten($fade-grey, 2%);
        border-radius: 4px;

        svg {
          height: 18px;
          width: 18px;
          stroke: $medium-text;
        }
      }
    }

    .control {
      input {
        margin: 0 10px;
        border: none !important;
      }
    }
  }

  .card-body {
    padding: 0 !important;

    .inner {
      display: flex;
      align-items: flex-start;

      .left-section {
        width: 67%;

        .search-subheader {
          border-bottom: 1px solid $fade-grey;
          padding: 0 12px;
          height: 57px;
          display: flex;
          justify-content: center;
          align-items: center;

          .control {
            width: 100%;
            position: relative;

            input {
              height: 32px;
              border-radius: 0;
              padding-left: 36px;
              font-size: 0.9rem;
              transition: all 0.3s;

              &:focus {
                border-color: $placeholder;

                + .icon svg {
                  stroke: $accent;
                }
              }
            }

            .icon {
              position: absolute;
              top: 0;
              left: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 32px;
              width: 32px;

              svg {
                width: 18px;
                height: 18px;
                stroke: $placeholder;
                transition: all 0.3s;
              }
            }
          }
        }

        .user-list {
          height: 403px;
          padding: 12px 0;
          overflow-y: auto;

          .friend-block {
            display: flex;
            align-items: center;
            margin-bottom: 6px;
            padding: 6px 12px;
            border-radius: 0;
            cursor: pointer;

            &:hover {
              background: lighten($fade-grey, 4%);
            }

            .friend-avatar {
              height: 40px;
              width: 40px;
              border-radius: 50%;
            }

            .friend-name {
              font-size: 0.85rem;
              font-weight: 500;
              color: $dark-text;
              margin: 0 10px;
            }

            .round-checkbox {
              margin-left: auto;
              margin-right: 12px;
            }
          }
        }
      }

      .right-section {
        width: 33%;
        height: 460px;
        background: $light-grey;
        border-left: 1px solid $fade-grey;
        overflow-y: auto;

        .selected-count {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 6px 12px;
          min-height: 57px;

          span {
            display: block;
            font-size: 0.9rem;
            font-weight: 500;
            color: $muted-grey;

            &:first-child {
              text-transform: uppercase;
              font-size: 0.7rem;
            }
          }
        }

        .selected-list {
          padding: 12px;

          .selected-friend-block {
            display: flex;
            align-items: center;
            padding: 6px 0;
            border-radius: 0;

            .image-wrapper {
              position: relative;

              .checked-badge {
                position: absolute;
                bottom: 6px;
                right: -1px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 18px;
                width: 18px;
                border: 2px solid $light-grey;
                border-radius: 50%;
                background: $medium-text;

                svg {
                  height: 8px;
                  width: 8px;
                  stroke: $white-light;
                  stroke-width: 3px;
                }
              }
            }

            .friend-avatar {
              height: 40px;
              width: 40px;
              border-radius: 50%;
            }

            .friend-name {
              font-size: 0.85rem;
              font-weight: 500;
              color: $dark-text;
              margin: 0 14px;
            }
          }
        }
      }
    }
  }

  .card-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 !important;
    padding: 8px;

    button {
      margin-left: 12px;
      line-height: 0;
    }
  }
}

/* ==========================================================================
3. Albums help modal
========================================================================== */

.albums-help-modal,
.videos-help-modal {
  .card {
    overflow: hidden;
  }

  .card-heading {
    border-bottom: 1px solid $fade-grey;
  }

  .card-body {
    .content-block {
      position: absolute;
      height: 248px;
      width: 454px;
      opacity: 0;
      transition: all 0.3s;

      &:first-child {
        transform: translateX(-100%);
      }

      &:nth-child(2) {
        transform: translateX(100%);
      }

      img {
        display: block;
        height: 140px;
        margin: 20px auto;
      }

      .help-text {
        text-align: center;
        max-width: 350px;
        margin: 0 auto;

        h3 {
          font-weight: 500;
        }

        p {
          color: $muted-grey;
          font-size: 0.9rem;
        }
      }

      &.is-active {
        transform: translateX(0);
        opacity: 1;
      }
    }

    .slide-dots {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 258px 0 16px 0;

      .dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $fade-grey;
        margin: 0 3px;

        &.is-active {
          background: $accent;
        }
      }
    }

    .action {
      text-align: center;
      margin-bottom: 16px;

      button {
        line-height: 0;
        width: 160px;
      }
    }
  }
}

/* ==========================================================================
4. Albums modal
========================================================================== */

.albums-modal {
  .modal-content {
    max-width: calc(100% - 40px);
    max-height: calc(100vh - 20px) !important;

    .modal-card {
      max-height: calc(100vh - 20px) !important;
    }
  }

  .card-heading {
    border-bottom: 1px solid $fade-grey;
    justify-content: flex-start !important;

    .button {
      line-height: 0 !important;
      padding: 14px 18px !important;
      margin-left: auto;
      margin-right: 10px;
      cursor: pointer;

      i {
        font-size: 18px;
      }
    }

    .close-wrap {
      margin-left: 0 !important;
    }
  }

  .card-body {
    padding: 0 !important;
    display: flex;

    .left-section,
    .right-section {
      padding: 12px;
      height: 580px;
    }

    .left-section {
      width: 25%;
      background: $light-grey;
      border-right: 1px solid $fade-grey;

      .control {
        margin-bottom: 10px;

        input {
          padding-left: 34px;
        }

        .icon {
          position: absolute;
          top: 0;
          left: 0;
          height: 36px;
          width: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $placeholder;

          svg {
            width: 16px;
            height: 16px;
            transition: all 0.3s;
          }
        }
      }

      .album-form {
        margin-bottom: 20px;
      }

      .tagged-in-album {
        margin: 0 0 10px 0;

        .head {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 3px;

          h4 {
            font-weight: 500;
            font-size: 0.85rem;
            color: $dark-text;
          }

          .button {
            height: 22px;
            width: 22px;
            outline: none;

            svg {
              height: 12px !important;
              width: 12px !important;
              stroke-width: 2.6px;
            }
          }
        }

        p {
          font-size: 0.75rem;
          max-width: 250px;
          color: $muted-grey;
          margin-bottom: 8px;
        }
      }

      .album-tag-list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        .tagged-user {
          position: relative;

          &:not(:first-child) {
            $var: 12;

            @for $i from 1 through 99 {
              &:nth-child(#{$i}) {
                margin-left: -#{$var}px;
              }
            }
          }

          &:hover {
            .remove-tag {
              display: flex;
            }
          }

          img {
            height: 42px;
            width: 42px;
            border-radius: 50%;
            border: 3px solid $light-grey;
          }

          .remove-tag {
            position: absolute;
            top: -5px;
            left: -7px;
            display: none;
            justify-content: center;
            align-items: center;
            height: 22px;
            width: 22px;
            border-radius: 50%;
            border: 2px solid $white-light;
            background: $muted-grey;
            cursor: pointer;

            &:hover {
              background: $red;
            }

            svg {
              height: 12px;
              width: 12px;
              stroke: $white-light;
            }
          }
        }
      }

      .shared-album,
      .album-date {
        margin-bottom: 10px;
        margin-top: 0;

        .head {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 3px;

          h4 {
            font-weight: 500;
            font-size: 0.85rem;
            color: $dark-text;
          }

          .basic-checkbox {
            label {
              &:before {
                margin-right: 0;
              }
            }
          }

          .button {
            height: 22px;
            width: 22px;
            outline: none;

            svg {
              height: 12px !important;
              width: 12px !important;
              stroke-width: 2.6px;
            }
          }
        }

        p {
          font-size: 0.75rem;
          max-width: 250px;
          color: $muted-grey;
          margin-bottom: 8px;
        }
      }

      .album-date {
        margin-top: 20px;
      }
    }

    .right-section {
      width: 75%;
      overflow-y: auto;
    }
  }

  .card-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 !important;
    padding: 8px;

    button {
      margin-left: 6px;
      line-height: 0;
    }

    .dropdown {
      button {
        padding: 0 16px;
        height: 38px;

        svg {
          height: 16px;
          width: 16px;

          &.main-icon {
            margin-right: 4px;
          }
        }

        i {
          font-size: 18px;
          margin-right: 4px;
        }
      }
    }
  }
}

/* ==========================================================================
5. Videos modal
========================================================================== */

.videos-modal {
  .modal-content {
    max-width: calc(100% - 40px);
    max-height: calc(100vh - 20px) !important;
  }

  .modal-card {
    max-height: calc(100vh - 20px) !important;
  }

  .card-heading {
    border-bottom: 1px solid $fade-grey;
    justify-content: flex-start !important;

    .button {
      line-height: 0 !important;
      padding: 14px 18px !important;
      margin-left: auto;
      margin-right: 10px;
      cursor: pointer;

      i {
        font-size: 18px;
        margin-right: 4px;
      }
    }

    .close-wrap {
      margin-left: 0 !important;
    }
  }

  .card-body {
    padding: 0 !important;

    .inner {
      display: flex;
      align-items: stretch;

      .left-section {
        width: 65%;
      }

      .right-section {
        position: relative;
        width: 35%;
        border-left: 1px solid $fade-grey;

        .comment-controls {
          position: absolute;
          bottom: 0;
          left: 0;
          background: darken($light-grey, 2%);
          height: 50px;
          width: 100%;
          border-radius: 0 0 6px 0;
          border-top: 1px solid darken($light-grey, 8%);

          .controls-inner {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0 16px;

            img {
              height: 32px;
              width: 32px;
              border-radius: 50%;
            }

            .control {
              position: relative;
              width: 100%;

              .textarea,
              .emojionearea-editor {
                resize: none;
                height: 36px;
                max-height: 36px;
                min-height: 36px;
                border-radius: 100px;
                overflow: hidden;
                line-height: 1.6;
                font-size: 0.8rem;
                padding-left: 16px;
                margin: 0 6px;
              }

              .emoji-button {
                position: absolute;
                top: 0;
                right: 0;
                height: 36px;
                width: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: none;
                border: none;
                outline: none;
                transition: all 0.3s;
                cursor: pointer;

                &:hover {
                  svg {
                    stroke: $primary;
                  }
                }

                svg {
                  height: 16px;
                  width: 16px;
                  stroke: $light-text;
                  transition: all 0.3s;
                }
              }
            }

            .emojionearea-editor {
              padding-left: 0 !important;

              img {
                height: 18px;
                width: 18px;
                min-height: 18px;
                max-height: 18px;
              }
            }

            .emojionearea {
              overflow: visible !important;
            }

            .emojionearea-picker {
              top: -230px;
              position: absolute;
              left: -50px;
              width: 310px;

              .emojionearea-wrapper {
                width: 310px;

                img {
                  height: 22px;
                  width: 22px;
                  min-height: 22px;
                  max-height: 22px;
                }
              }
            }

            .emojionearea-scroll-area {
              width: 310px;
            }

            .emojionearea .emojionearea-button > div.emojionearea-button-open {
              background-position: 0 -22px;
            }

            .emojionearea
              .emojionearea-picker
              .emojionearea-scroll-area
              .emojibtn {
              width: 24px !important;
              height: 24px !important;
            }
          }
        }

        .header {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 12px;
          width: 100%;

          img {
            height: 42px;
            width: 42px;
            border-radius: 50%;
          }

          .user-meta {
            margin: 0 10px;

            span {
              display: block;

              &:first-child {
                font-size: 0.9rem;
                font-weight: 500;

                small {
                  color: $muted-grey;
                }
              }

              &:nth-child(2) {
                font-size: 0.8rem;
                color: $muted-grey;
              }
            }
          }

          .button {
            line-height: 0;
            margin-left: auto;
            padding: 14px 18px;
          }

          .dropdown {
            .button {
              padding: 18px 6px;
              border: none;
              background: transparent;

              svg {
                height: 18px;
                width: 18px;
              }

              img {
                border-radius: 50%;
              }
            }

            .dropdown-menu {
              margin-top: 10px;
            }
          }
        }

        .inner-content {
          padding: 12px;

          .control {
            width: 100%;

            input {
              padding-left: 34px;

              &:focus {
                + .icon svg {
                  stroke: $accent;
                }
              }
            }

            .icon {
              position: absolute;
              top: 0;
              left: 0;
              height: 32px;
              width: 32px;
              display: flex;
              justify-content: center;
              align-items: center;

              svg {
                height: 18px;
                width: 18px;
                stroke: $placeholder;
                transition: all 0.3s;
              }
            }
          }
        }

        .live-stats {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px 0;
          border-bottom: 1px solid $fade-grey;

          .social-count {
            display: flex;
            align-items: stretch;

            .shares-count,
            .comments-count,
            .likes-count,
            .views-count {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin: 0 3px;

              span {
                display: block;
                font-size: 0.8rem;
                color: $grey-text;
                margin: 0 5px;

                &.views {
                  margin: 0 2px;
                }
              }

              svg {
                height: 14px;
                width: 14px;
                stroke: $grey-text;
              }
            }
          }
        }

        .actions {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 16px 0;

          .action {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            span {
              display: block;
              font-size: 0.8rem;
              margin: 0 4px;
              transition: all 0.3s;
            }

            svg {
              height: 16px;
              width: 16px;
              stroke: $grey-text;
              transition: all 0.3s;
            }

            &:hover {
              span {
                color: $accent;
              }

              svg {
                stroke: $accent;
              }
            }
          }
        }

        .tabs-wrapper {
          .tabs {
            margin-bottom: 0 !important;

            li {
              a {
                font-size: 0.85rem;
                color: $placeholder;
                font-weight: 400;
              }

              &:hover {
                a {
                  border-bottom-color: $muted-grey;
                }
              }

              &.is-active {
                a {
                  font-weight: 500;
                  color: $accent;
                  border-bottom-color: $accent;
                }
              }
            }
          }

          .tab-content {
            background: $light-grey;
            height: 320px;
            padding: 20px 14px;
            overflow-y: auto;

            .is-comment {
              border: none !important;
              padding-top: 0 !important;

              .media-left {
                margin-right: 10px;

                img {
                  border-radius: 50%;
                }
              }

              .media-content {
                background: $white;
                padding: 8px;
                border-radius: 12px;

                .username {
                  font-size: 0.8rem;
                  font-weight: 500;
                }

                p {
                  font-size: 0.75rem;
                  color: $muted-grey;
                }

                .comment-actions {
                  display: flex;
                  align-items: center;
                  padding-top: 8px;

                  span,
                  a {
                    display: block;
                    font-size: 0.75rem;
                  }

                  span {
                    margin: 0 20px;
                    color: $muted-grey;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* ==========================================================================
6. Share modal
========================================================================== */

.share-modal {
  .card-heading {
    padding: 8px !important;
  }

  .card-heading,
  .share-inputs {
    border-bottom: 1px solid $fade-grey;

    .dropdown {
      margin-left: 0 !important;

      .button {
        padding: 10px 12px;
        background: $light-grey;
        color: $dark-text;

        svg {
          height: 16px;
          width: 16px;
          margin: 0 4px;
          stroke: $dark-text;
        }

        i {
          font-size: 20px;
          margin-right: 4px;
        }

        img {
          height: 20px !important;
          width: 20px !important;
          border-radius: 50% !important;
        }
      }

      .dropdown-menu {
        width: 320px;

        .dropdown-content {
          .dropdown-item {
            cursor: pointer;

            &:hover {
              background: $smokey;
            }
          }

          img {
            height: 36px !important;
            width: 36px !important;
            border-radius: 50% !important;
            margin-right: 10px;
          }

          i {
            font-size: 22px;
            margin-right: 16px;
            color: $muted-grey;
          }

          .media-content {
            h3 {
              font-size: 0.85rem;
            }

            small {
              color: $muted-grey;
            }
          }
        }
      }

      &.is-active {
        .button {
          background: $accent;
          color: $white-light;

          svg {
            stroke: $white-light;
          }
        }
      }
    }
  }

  .share-inputs {
    border-bottom: none !important;

    .field {
      margin-bottom: 0 !important;

      .easy-autocomplete {
        input {
          padding-left: 85px;
        }
      }
    }

    .control {
      border-bottom: 1px solid $fade-grey;

      &.no-border {
        border-bottom-color: transparent;
      }

      input {
        border: none;
        padding-left: 85px;
      }

      .input-heading {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        width: 70px;
        padding: 0 6px;
        background: $muted-grey;
        color: $white-light;
        font-size: 0.8rem;
      }

      .page-controls {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .page-selection {
          padding: 8px;

          .page-selector {
            img {
              display: block;
              position: relative;
              top: -1px;
              margin-right: 6px;
            }
          }
        }

        .alias {
          padding: 8px;

          img {
            display: block;
            height: 24px;
            width: 24px;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .card-body {
    > .control {
      position: relative;

      .textarea {
        resize: none;
        border: none;
        padding-right: 42px;
      }

      .emoji-button {
        position: absolute;
        top: 0;
        right: 0;
        height: 41px;
        width: 41px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        border: none;
        outline: none;
        transition: all 0.3s;
        cursor: pointer;

        &:hover {
          svg {
            stroke: $primary;
          }
        }

        svg {
          height: 16px;
          width: 16px;
          stroke: $light-text;
          transition: all 0.3s;
        }
      }
    }

    .shared-publication {
      margin-top: 20px;

      .featured-image {
        img {
          display: block;
        }
      }

      .publication-meta {
        border: 1px solid $fade-grey;
        background: $light-grey;
        padding: 20px;

        .inner-flex {
          display: flex;
          align-items: flex-start;
          margin-bottom: 20px;

          img {
            height: 40px;
            width: 40px;
            border-radius: 50%;
          }

          p {
            font-size: 0.8rem;
            padding: 0 16px;

            @include multiLineEllipsis(
              $lineHeight: 1.2em,
              $lineCount: 3,
              $bgColor: $light-grey
            );
          }
        }

        .publication-footer {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .stats {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
          }

          .stat-block {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-right: 10px;

            i {
              margin-right: 2px;
              color: $muted-grey;
            }

            small {
              font-size: 0.75rem;
            }
          }

          .publication-origin {
            small {
              text-transform: uppercase;
              color: $muted-grey;
              font-weight: 200;
              font-size: 0.65rem;
            }
          }
        }
      }
    }
  }

  .bottom-share-inputs {
    border-bottom: none !important;

    .field {
      margin-bottom: 0 !important;

      .easy-autocomplete {
        input {
          padding-left: 85px;
        }
      }
    }

    .control {
      border-top: 1px solid $fade-grey;

      &.no-border {
        border-bottom-color: transparent;
      }

      input {
        border: none;
        padding-left: 85px;
      }

      .input-heading {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        width: 70px;
        padding: 0 6px;
        background: $muted-grey;
        color: $white-light;
        font-size: 0.8rem;
      }

      .page-controls {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .page-selection {
          padding: 8px;

          .page-selector {
            img {
              display: block;
              position: relative;
              top: -1px;
              margin-right: 6px;
            }
          }
        }

        .alias {
          padding: 8px;

          img {
            display: block;
            height: 24px;
            width: 24px;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 !important;
    padding: 8px;
    background: $light-grey;
    border-radius: 0 0 6px 6px;

    .action-wrap {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .footer-action {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 34px;
        width: 34px;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.3s;

        i {
          font-size: 18px;
          color: $muted-grey;
        }

        &:hover {
          background: darken($light-grey, 5%);
        }

        &.is-active {
          background: darken($light-grey, 5%);

          i {
            color: $accent;
          }
        }
      }
    }

    button {
      line-height: 0;
      margin-left: 4px;
      height: 32px !important;
      padding: 8px 22px;
    }
  }
}

/* ==========================================================================
6. Change cover and profile picture modals
========================================================================== */

.change-cover-modal,
.upload-image-modal,
.new-story-modal {
  .card {
    .card-heading {
      padding: 16px 28px;

      h3 {
        font-size: 1rem;
      }

      .small-avatar {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        border: 1px solid $placeholder;
        transition: all 0.3s;

        img {
          display: block;
          height: 24px;
          width: 24px;
          border-radius: 50%;
          box-shadow: $light-box-shadow;
        }
      }
    }

    .card-body {
      .selection-placeholder {
        padding: 0 20px 30px 20px;

        .selection-box {
          padding: 40px;
          width: 100%;
          border: 1px solid $fade-grey;
          border-radius: 4px;
          background: $white;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: all 0.3s;

          &:hover {
            box-shadow: $light-box-shadow;

            .box-content {
              img {
                -webkit-filter: grayscale(0);
                filter: grayscale(0);
                opacity: 1;
              }

              .box-text {
                span:first-child {
                  color: $accent;
                }
              }
            }
          }

          .box-content {
            text-align: center;

            img {
              display: block;
              max-height: 120px;
              margin: 0 auto;
              opacity: 0.5;
              transition: all 0.3s;
              -webkit-filter: grayscale(1);
              filter: grayscale(1);
            }

            .box-text {
              margin: 10px 0;

              span {
                display: block;

                &:first-child {
                  font-weight: 500;
                  color: $dark-text;
                  transition: color 0.3s;
                }

                &:nth-child(2) {
                  font-size: 0.9rem;
                  color: $muted-grey;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* ==========================================================================
7. User photos modal
========================================================================== */

.user-photos-modal {
  .card {
    .card-heading {
      padding: 20px;

      h3 {
        font-size: 1rem;
      }
    }

    .card-body {
      padding: 0 20px;

      .tabs {
        margin-bottom: 0;

        ul {
          border-color: transparent;

          li {
            &.is-active {
              background: lighten($fade-grey, 5%);
              border-radius: 8px;
              border: 1px solid lighten($fade-grey, 4%);

              a {
                color: $dark-text;
                font-weight: 500;
              }
            }

            a {
              font-size: 0.8rem;
              border-bottom-color: transparent !important;
            }
          }
        }
      }

      .tab-content {
        padding: 20px 0;
        max-height: 395px;
        overflow-y: auto;

        .album-info {
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          h4 {
            font-weight: 600;
            color: $dark-text;

            small {
              font-size: 0.9rem;
              color: $muted-grey;
              font-weight: 400;
            }
          }

          .close-nested-photos {
            font-size: 0.9rem;
            font-weight: 500;
          }
        }
      }

      .image-grid,
      .album-image-grid {
        animation: fadeInLeft 0.5s;

        .column {
          padding: 0.5rem;
        }

        .grid-image {
          position: relative;

          &:hover {
            img {
              box-shadow: $heavy-box-shadow;
            }

            .inner-overlay {
              background: rgba($dark-text, 0.2) !important;
            }
          }

          input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
            z-index: 1;

            &:checked + .inner {
              .inner-overlay {
                background: rgba($dark-text, 0.5);
              }

              .indicator {
                display: flex;
              }
            }
          }

          .inner {
            position: relative;

            img {
              display: block;
              border-radius: 4px;
              transition: all 0.3s;
            }

            .inner-overlay {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba($dark-text, 0.1);
              border-radius: 4px;
              transition: all 0.3s;
              z-index: 0;
            }

            .indicator {
              position: absolute;
              top: 8px;
              right: 8px;
              display: none;
              justify-content: center;
              align-items: center;
              height: 26px;
              width: 26px;
              border-radius: 50%;
              background: $accent;
              border: 2px solid $white;
              z-index: 0;

              svg {
                height: 12px;
                width: 12px;
                stroke: $white-light;
                stroke-width: 3px;
              }
            }
          }
        }
      }

      .album-wrapper {
        width: 100%;
        border: 1px solid $fade-grey;
        border-radius: 4px;
        padding: 12px;
        background: $white;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          transform: translateY(-5px);
          box-shadow: $light-box-shadow;

          .image-count {
            svg {
              stroke: $accent !important;
            }

            span {
              color: $accent !important;
            }
          }
        }

        .album-image {
          img {
            display: block;
            border: 4px;
          }
        }

        .album-meta {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .album-title {
            margin-top: 6px;

            span {
              display: block;

              &:first-child {
                min-width: 0;
                font-size: 0.9rem;
                font-weight: 600;
                color: $dark-text;
              }

              &:nth-child(2) {
                font-size: 0.8rem;
                color: $muted-grey;
              }
            }
          }

          .image-count {
            display: flex;
            align-items: center;
            margin-top: 6px;

            svg {
              height: 20px;
              width: 20px;
              stroke: $placeholder;
              transition: all 0.3s;
            }

            span {
              display: block;
              font-size: 0.9rem;
              font-weight: 500;
              color: $muted-grey;
              margin: 0 4px;
              transition: 0.3s;
            }
          }
        }
      }
    }

    .card-footer {
      padding: 20px;
      margin: 0;
      border: none;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}

/* ==========================================================================
8. Crop profile picture modal
========================================================================== */

.upload-crop-profile-modal,
.upload-crop-cover-modal {
  .card {
    .card-heading {
      padding: 20px;

      h3 {
        font-size: 1rem;
      }
    }

    .card-body {
      padding: 0 20px;

      .profile-uploader-box,
      .cover-uploader-box {
        display: block;
        width: 100%;
        height: 300px;
        padding: 40px;
        border: 3px dashed $fade-grey;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          .inner-content {
            position: relative;

            img {
              opacity: 1;
              -webkit-filter: grayscale(0);
              filter: grayscale(0);
            }
          }
        }

        .inner-content {
          display: block;

          img {
            display: block;
            max-height: 110px;
            margin: 0 auto;
            opacity: 0.5;
            -webkit-filter: grayscale(1);
            filter: grayscale(1);
            transition: all 0.3s;
          }

          span {
            display: block;
            text-align: center;
            color: $placeholder;
            padding: 10px 0;
          }
        }

        input {
          display: none;
        }
      }

      .croppie-container {
        .cr-boundary {
          background-image: url(http://cdn.thedesigninspiration.com/wp-content/uploads/2014/07/Icon-Pattern-l.jpg);
          background-repeat: repeat;
          background-size: cover;
        }

        .cr-viewport {
          box-shadow: 0 0 2000px 2000px rgba(0, 0, 0, 0.1);
        }

        .cr-slider {
          cursor: pointer;
        }
      }

      .upload-help {
        display: flex;
        justify-content: center;
        align-items: center;

        .profile-reset,
        .cover-reset {
          display: block;
          color: $muted-grey;
          padding: 4px 12px;
          border-radius: 4px;
          font-size: 0.9rem;
          background: $fade-grey;
          transition: all 0.3s;

          &:hover {
            background: lighten($fade-grey, 3%);
            color: $dark-text;
          }
        }
      }
    }

    .card-footer {
      border-top: none;
      justify-content: center;
      margin: 20px;
    }
  }
}

/* ==========================================================================
9. End tour modal
========================================================================== */

.end-tour-modal {
  .modal-content {
    .card {
      .card-body {
        .image-wrap {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 90px;
          width: 90px;
          border-radius: 50%;
          background: lighten($fade-grey, 5%);
          margin: 0 auto 20px auto;

          img {
            display: block;
            height: 50px;
            width: 50px;
          }
        }

        h3 {
          font-weight: 500;
          font-size: 1.1rem;
          font-family: "montserrat", sans-serif;
        }

        p {
          font-size: 0.9rem;
          color: $muted-grey;
          max-width: 330px;
          margin: 0 auto;
        }

        .action {
          margin: 20px 0;

          .button {
            max-width: 180px;
            margin: 0 auto;
          }
        }
      }
    }
  }
}

/* ==========================================================================
10. No stream modal
========================================================================== */

.no-stream-modal {
  .modal-content {
    .card {
      .card-body {
        .image-wrap {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto 20px auto;

          img {
            display: block;
            width: 200px;
          }
        }

        h3 {
          font-weight: 500;
          font-size: 1.1rem;
          font-family: "montserrat", sans-serif;
        }

        p {
          font-size: 0.9rem;
          color: $muted-grey;
          max-width: 330px;
          margin: 0 auto;
        }

        .action {
          margin: 20px 0;

          .button {
            max-width: 180px;
            margin: 0 auto;
          }
        }
      }
    }
  }
}

.video-story-modal,
.image-story-modal {
  .modal-content {
    .card {
      .card-heading {
        padding: 16px 28px;

        h3 {
          font-size: 1rem;
        }

        .small-avatar {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 8px;
          height: 32px;
          width: 32px;
          border-radius: 50%;
          border: 1px solid $placeholder;
          transition: all 0.3s;

          img {
            display: block;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            box-shadow: $light-box-shadow;
          }
        }
      }

      .card-body {
        padding: 10px 30px 30px 30px;

        .story-meta {
          p {
            font-family: "Roboto", sans-serif;
            font-size: 0.75rem;
            color: lighten($dark-text, 12%);
            margin-bottom: 1.5rem;
          }

          select {
            width: 100%;
          }
        }

        .story-upload {
          .preview-block {
            .upload-placeholder {
              display: block;
              width: 100%;
              max-width: 220px;
              margin: 0 auto;
            }

            .image-upload-placeholder {
              display: block;
              width: 100%;
              max-width: 140px;
              margin: 0 auto;
            }

            .preview-image-container {
              position: relative;
              max-width: 260px;
              margin: 0 auto;

              .delete {
                position: absolute;
                top: 4px;
                right: 4px;
              }

              .preview-image {
                display: block;
                max-width: 260px;
                min-height: 160px;
                margin: 0 auto;
                border-radius: 12px;
                box-shadow: $light-box-shadow;
                object-fit: cover;
              }
            }
          }

          .input-block {
            padding: 16px 0;
            max-width: 260px;
            margin: 0 auto;

            input {
              width: 100%;
              background: $fade-grey;
              outline: none !important;
              border-radius: 6px;
            }
          }

          input[type="file"] {
            cursor: pointer !important;
          }

          input[type="file"]::-webkit-file-upload-button {
            background: $white;
            border: 0;
            padding: 1em 2em;
            cursor: pointer;
            color: $dark-text;
            border-radius: 6px;
            border: 1px solid darken($fade-grey, 3%);
            box-shadow: $light-box-shadow;
            outline: none !important;
            margin-right: 12px;
          }

          input[type="file"]::-ms-browse {
            background: $white;
            border: 0;
            padding: 1em 2em;
            cursor: pointer;
            color: $dark-text;
            border-radius: 6px;
            border: 1px solid darken($fade-grey, 3%);
            box-shadow: $light-box-shadow;
            outline: none !important;
            margin-right: 12px;
          }
        }
      }
    }
  }
}

.tokenize {
  .tokens-container {
    .token {
      span {
        font-size: 0.75rem;
        line-height: 2;
      }

      .dismiss {
        top: 1px;
      }
    }
  }
}

.tokenize-dropdown {
  .dropdown-menu {
    background: $white !important;
    border-radius: 6px !important;
    border: 1px solid darken($fade-grey, 3%) !important;
    box-shadow: $light-box-shadow !important;
    height: 144px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px !important;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px !important;
      background: rgba(0, 0, 0, 0.2) !important;
    }

    li {
      margin: 0 8px;
      padding: 6px 10px;
      border-radius: 6px;

      &:hover {
        background: lighten($fade-grey, 4%);

        a {
          color: $accent;
        }
      }

      a {
        color: $muted-grey;
        font-size: 0.85rem !important;
        font-weight: 400;
      }
    }
  }
}

/* ==========================================================================
11. Media Queries
========================================================================== */

@media (max-width: 767px) {
  .modal {
    &.albums-modal,
    &.videos-modal {
      .card {
        .card-heading {
          h3 {
            display: none;
          }

          .button {
            margin-left: auto;
          }
        }

        .card-body {
          flex-direction: column;

          .left-section {
            display: none;
            width: 100%;
          }

          .right-section {
            width: 100%;
          }
        }
      }
    }

    &.albums-help-modal,
    &.videos-help-modal {
      img {
        height: 110px !important;
      }
    }

    &.albums-modal {
      .modal-content,
      .modal-card {
        max-height: calc(100vh - 20px);
      }
    }

    .modal-content {
      max-height: calc(100vh - 80px) !important;
      // max-width: 340px;
      max-width: 80vw;

      .content-block {
        width: 314px;

        img {
          height: 135px;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .modal {
    &.albums-modal {
      .card {
        .card-body {
          .album-date,
          .tagged-in-album,
          .shared-album {
            .head {
              h4 {
                font-size: 0.75rem;
              }

              p {
                font-size: 0.7rem;
              }
            }
          }
        }
      }
    }
  }
}

/* ==========================================================================
12. Confirm Dialog
========================================================================== */

.fancybox-confirm {
  padding: 2.5rem !important;

  .title {
    font-family: $font-main;
    font-size: 1.5rem;
  }
}
