/*! _feed.scss | Friendkit | © Css Ninja. 2020-2021 */

/* ==========================================================================
Main feed styles
========================================================================== */

/*
    1. General Layout
    2. Options Summary
    3. Tag list
    4. Autocompletes
    5. Feed v1 left menu
    6. Media Queries
*/

/* ==========================================================================
1. General Layout
========================================================================== */

.card {
    &.is-new-content {
        .tabs-wrapper {
            border-radius: 6px;
        }

        .tabs {
            border-radius: 6px 6px 0 0;

            ul {
                background: $white-smoke;
            }

            li {
                background: $white-smoke;

                &.is-active {
                    a {
                        color: $medium-text;
                        font-weight: 500;

                        svg {
                            stroke: $medium-text;
                        }
                    }
                }

                &.close-wrap {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    .close-publish {
                        margin: 0 16px;
                        display: none;
                        justify-content: center;
                        align-items: center;
                        height: 30px;
                        width: 30px;
                        border-radius: 50%;
                        background: transparent;
                        cursor: pointer;
                        transition: background .3s;

                        &:hover {
                            background: lighten($placeholder, 10%);
                        }

                        svg {
                            height: 18px;
                            width: 18px;
                            stroke: $medium-text;
                        }
                    }
                }
            }

            a {
                font-size: .9rem;
                color: #888da8;
                padding: .75em !important;

                svg {
                    stroke: #888da8;
                }
            }
        }

        .compose {
            padding: 16px;
            border-bottom: 1px solid $fade-grey;

            .compose-form {
                display: flex;
                justify-content: flex-start;
                align-items: stretch;
            }

            .feed-upload {
                .upload-wrap {
                    position: relative;
                    height: auto;
                    width: 140px;

                    .remove-file {
                        position: absolute;
                        top: -4px;
                        right: -4px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 30px;
                        width: 30px;
                        border-radius: 50%;
                        background: $white;
                        cursor: pointer;
                        transition: background .3s;

                        &:hover {
                            background: lighten($placeholder, 10%);
                        }

                        svg {
                            height: 16px;
                            width: 16px;
                            stroke: $medium-text;
                        }
                    }

                    img {
                        display: inline-block;
                        width: 100%;
                        height: auto;
                        object-fit: cover;
                        border-radius: .5rem;
                    }
                }
            }

            img {
                height: 42px;
                width: 42px;
                border-radius: 50%;
            }

            .control {
                width: 100%;
                margin-left: 20px;

                .textarea {
                    padding: 0;
                    border-color: transparent;
                    resize: none;
                }
            }
        }

        .compose-options {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 8px;
            border-radius: 0 0 6px 6px;
            background: $white;
            cursor: pointer;

            .is-full {
                width: 100% !important;
                margin: 0 auto !important;
            }

            .is-narrower {
                padding: .3rem .5rem !important;
            }

            .compose-option {
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 6px 16px;
                margin-right: 10px;
                background: lighten($placeholder, 16%);
                border-radius: 500px;
                font-size: .85rem;
                color: $grey-text;
                transition: all .3s;

                &.is-centered {
                    margin: 0 auto;
                }

                &:hover {
                    background: lighten($placeholder, 14%);

                    svg {
                        stroke: $dark-text;
                    }
                }

                span {
                    display: block;
                    padding: 0 8px;
                }

                svg {
                    height: 20px;
                    width: 20px;
                    transition: all .3s;
                }

                img {
                    display: block;
                    height: 20px;
                    width: 20px;
                }

                input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    opacity: 0;
                    cursor: pointer;
                }
            }
        }

        .more-wrap {
            display: none;
            align-items: center;
            padding: 8px;
            border-top: 1px solid $fade-grey;
            border-radius: 0 0 6px 6px;
            background: $white-light;

            .is-more {
                line-height: 0;
                height: 38px;
                padding: 6px;
                margin-right: 6px;

                span {
                    margin: 0 8px 0 4px;
                }

                svg {
                    width: 16px;
                    height: 16px;
                }
            }

            .is-solid {
                line-height: 0;
            }
        }

        .hidden-options {
            display: none;
            background: #f5f6f7;
            padding: 8px;
            border-top: 1px solid $fade-grey;
            height: auto;

            &.is-opened {
                height: 350px !important;
                overflow: auto !important;

                &::-webkit-scrollbar {
                    width: 4px;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    background: rgba(0, 0, 0, 0.2);
                }
            }

            .target-channels {
                .channel {
                    display: flex;
                    align-items: center;
                    padding: 6px 8px;
                    border-radius: 4px;

                    &:hover {
                        background: darken(#f5f6f7, 3%);
                    }

                    .channel-icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 40px;
                        width: 40px;
                        margin: 0 10px;
                        border-radius: 50%;
                        background: $white;
                        border: 1px solid $fade-grey;

                        svg {
                            height: 18px;
                            width: 18px;
                            stroke: $muted-grey;
                            stroke-width: 1px;
                        }
                    }

                    .story-icon {
                        position: relative;
                        height: 40px;
                        width: 40px;
                        margin: 0 10px;
                        border-radius: 50%;
                        background: $fade-grey;
                        border: 1px solid $placeholder;

                        .plus-icon {
                            position: absolute;
                            bottom: -1px;
                            right: -8px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 22px;
                            width: 22px;
                            border: 2px solid $fade-grey;
                            border-radius: 50%;
                            background: $primary;

                            svg {
                                height: 10px;
                                width: 10px;
                                stroke-width: 3px;
                                stroke: $white-light;
                            }
                        }
                    }

                    .channel-name {
                        font-size: .85rem;
                        font-weight: 500;
                        color: $dark-text;
                    }

                    .dropdown {
                        margin-left: auto;

                        button {
                            line-height: 0;
                            height: 36px;
                            padding: 6px;

                            span {
                                margin: 0 4px;
                            }

                            svg {
                                width: 16px;
                                height: 16px;
                            }

                            i {
                                font-size: 1.1rem;
                            }
                        }
                    }
                }
            }

            .friends-list {
                margin-top: 10px;
                border-top: 1px solid darken($fade-grey, 4%);

                .list-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 16px 10px;

                    span {
                        color: $medium-text;
                        font-size: .8rem;
                        font-weight: 500;
                    }

                    .actions {
                        display: flex;
                        align-items: center;

                        a {
                            display: block;
                            margin-left: 8px;
                            font-size: .8rem;
                            font-weight: 500;
                            color: $medium-text;

                            &:first-child {
                                margin-left: auto;
                            }

                            &:hover {
                                color: $accent;
                            }

                            &.search-trigger {
                                height: 24px;
                                width: 24px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }

                            svg {
                                height: 16px;
                                width: 16px;
                                stroke: $medium-text;
                            }
                        }
                    }

                    .control {
                        position: relative;

                        .input {
                            padding-left: 36px;
                            border-radius: 100px;
                            transition: all .3s;
                            font-size: .9rem;

                            &:focus {
                                border-color: $fade-grey;
                            }
                        }

                        span {
                            position: absolute;
                            top: 0;
                            left: 4px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 36px;
                            width: 36px;

                            svg {
                                height: 18px;
                                width: 18px;
                                stroke: $placeholder;
                            }
                        }
                    }
                }

                .list-body {
                    padding: 0 6px;

                    .friend-block {
                        display: flex;
                        align-items: center;
                        margin-bottom: 6px;
                        padding: 6px 0;
                        border-radius: 4px;
                        cursor: pointer;

                        &:hover {
                            .round-checkbox label {
                                border-color: $accent;
                            }
                        }

                        .friend-avatar {
                            height: 38px;
                            width: 38px;
                            margin: 0 10px;
                            border-radius: 50%;
                        }

                        .friend-name {
                            font-size: .85rem;
                            font-weight: 500;
                            color: $dark-text;
                        }
                    }
                }
            }
        }

        &.is-highlighted {
            position: relative !important;
            z-index: 1001 !important;

            .close-publish {
                display: flex !important;
            }

            .more-wrap {
                display: flex;
            }

            .hidden-options {
                display: block;
            }
        }
    }

    &.is-post {
        &.is-nested {
            position: relative;
            margin-top: 20px !important;
            border-bottom-color: transparent;
            border-radius: 6px 6px 0 0;
        }

        &.is-simple {
            .post-actions {
                flex-direction: row-reverse !important;
            }

            .card-footer {
                padding-top: 10px;
            }
        }

        &.has-nested {
            .content-wrap {
                .card-footer {
                    margin-top: 16px;
                }
            }
        }

        .card-heading, .card-body {
            padding: 16px 16px 0 16px;
        }

        .card-footer {
            border: none;
            padding: 16px;
            margin: 0;
        }

        .user-block {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            img {
                width: 42px;
                height: 42px;
                border-radius: 50%;
            }

            a, .time {
                display: block;
            }

            a {
                font-size: .9rem;
                font-weight: 500;
                color: $dark-text;
                transition: color .3s;

                &:hover {
                    color: lighten($dark-text, 8%);
                }
            }

            .time {
                font-size: .8rem;
                color: $muted-grey;
            }

            .user-info {
                padding: 0 10px;

                a {
                    line-height: 1.1;

                    span {
                        color: $accent;
                        font-weight: 500;
                    }
                }
            }
        }

        .content-wrap {
            .post-text {
                p {
                    color: $medium-text;
                    font-size: .9rem;

                    a {
                        color: $dark-text;
                        font-weight: 500;

                        &:hover {
                            color: $accent;
                        }
                    }
                }
            }

            .action-wrap {
                position: relative;
                margin-top: -22px !important;

                .like-wrapper {
                    position: absolute;
                    bottom: -27px;
                    right: 26px;
                    max-height: 54px;
                }

                .fab-wrapper {
                    position: absolute;
                    bottom: -21px;
                    max-height: 43px;

                    &.is-share {
                        right: 84px;
                    }

                    &.is-comment {
                        right: 131px;
                    }
                }
            }

            .post-image {
                position: relative;
                margin: 10px 0;

                img {
                    display: block;
                    border-radius: 3px;
                }

                .like-wrapper {
                    position: absolute;
                    bottom: -27px;
                    right: 0;
                    max-height: 54px;
                }

                .fab-wrapper {
                    position: absolute;
                    bottom: -21px;
                    max-height: 43px;

                    &.is-share {
                        right: 58px;
                    }

                    &.is-comment {
                        right: 105px;
                    }
                }

                .triple-grid {
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;

                    a {
                        &.is-half {
                            display: block;
                            width: calc(50% - .25rem) !important;
                            height: 100%;
                        }
                    }

                    img {
                        display: block;
                        margin-bottom: .5rem;
                        object-fit: cover;
                        width: 100%;
                        height: 50%;
                    }

                    .like-wrapper {
                        position: absolute;
                        bottom: -18px;
                        right: 18px;
                        max-height: 54px;
                    }

                    .fab-wrapper {
                        position: absolute;
                        bottom: -13px;
                        max-height: 43px;

                        &.is-share {
                            right: 76px;
                        }

                        &.is-comment {
                            right: 123px;
                        }
                    }
                }

                .masonry-grid {
                    position: relative;
                    display: flex;
                    align-items: center;

                    img {
                        display: block;
                        margin-bottom: .5rem;
                        object-fit: cover;
                    }

                    .masonry-column-left {
                        height: 100%;
                        width: calc(61% - .5rem);
                    }

                    .masonry-column-right {
                        width: 39%;
                        height: 100%;
                        margin-left: .5rem;
                    }

                    .like-wrapper {
                        position: absolute;
                        bottom: -18px;
                        right: 0;
                        max-height: 54px;
                    }

                    .fab-wrapper {
                        position: absolute;
                        bottom: -13px;
                        max-height: 43px;

                        &.is-share {
                            right: 58px;
                        }

                        &.is-comment {
                            right: 105px;
                        }
                    }
                }
            }

            .post-link {
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 12px;
                border: 1px solid $fade-grey;
                border-radius: 6px;
                background: lighten($fade-grey, 5%);
                margin: 16px 0 10px 0;

                &.is-video {
                    .link-image {
                        position: relative;

                        .video-overlay {
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                            width: 100%;
                            border-radius: 6px;
                            background: rgba($sidebar, 0.6);
                        }

                        .video-button {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            height: 46px;
                            width: 46px;
                            background: $primary;
                            border-radius: 50%;
                            margin: 0 auto;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            z-index: 2;
                            cursor: pointer;

                            img {
                                position: relative;
                                display: block;
                                width: 20px;
                                height: 20px;
                                left: 2px;
                                z-index: 3;
                            }

                            &:after {
                                content: '';
                                position: absolute;
                                top: calc(50% - 12px);
                                left: calc(50% - 12px);
                                height: 24px;
                                width: 24px;
                                border-radius: 50%;
                                background: $primary;
                                animation: wave 1.6s infinite;
                                animation-duration: 2s;
                                transform-origin: center center;
                                z-index: 0;
                            }
                        }
                    }
                }

                .link-image {
                    > img {
                        display: block;
                        height: 130px;
                        width: 180px;
                        min-width: 180px;
                        border-radius: 6px;
                        object-fit: cover;
                    }
                }

                .link-content {
                    margin-left: 12px;

                    h4 {
                        margin-bottom: 4px;

                        a {
                            font-size: 1.1rem;
                            font-weight: 500;
                            color: $medium-text;

                            &:hover {
                                color: $accent;
                            }
                        }
                    }

                    p {
                        font-size: .9rem;
                        color: $muted-grey;
                    }

                    small {
                        text-transform: uppercase;
                        font-size: .65rem;
                        color: $medium-text;
                        margin-top: 8px;
                    }
                }

                .like-wrapper {
                    position: absolute;
                    bottom: -25px;
                    right: 0;
                    max-height: 54px;
                }

                .fab-wrapper {
                    position: absolute;
                    bottom: -20px;
                    max-height: 43px;

                    &.is-share {
                        right: 58px;
                    }

                    &.is-comment {
                        right: 105px;
                    }
                }
            }

            .post-actions {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-top: 10px;

                .like-wrapper {
                    margin: 0 2px;

                    .like-button {
                        width: 36px;
                        height: 36px;
                        border: none;
                    }

                    .like-overlay {
                        top: 0 !important;
                        left: 0 !important;
                        width: 36px;
                        height: 36px;
                    }

                    i {
                        font-size: 16px;
                    }
                }

                .fab-wrapper {
                    margin: 0 2px;

                    .small-fab {
                        width: 36px;
                        height: 36px;
                        border: none;

                        svg {
                            height: 16px;
                            width: 16px;
                        }
                    }
                }
            }

            .card-footer {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .likers-group {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    img {
                        height: 38px;
                        width: 38px;
                        border-radius: 50%;
                        border: 3px solid $white;

                        &:not(:first-child) {
                            margin-left: -12px;
                        }
                    }

                    .no-like {
                        width: 38px;
                        height: 38px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        svg {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }

                .likers-text {
                    margin-left: 10px;
                    line-height: 1.4;

                    p {
                        font-size: .8rem;
                        color: $grey-text;

                        a {
                            color: $dark-text;
                            font-weight: 500;

                            &:hover {
                                color: $accent;
                            }
                        }
                    }
                }

                .social-count {
                    margin-left: auto;
                    display: flex;
                    align-items: stretch;

                    .shares-count, .comments-count, .likes-count {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        margin: 0 3px;

                        span {
                            display: block;
                            font-size: .9rem;
                            color: $grey-text;
                            margin: 0 5px;
                        }

                        svg {
                            height: 18px;
                            width: 18px;
                            stroke: $grey-text;
                        }
                    }
                }
            }
        }

        .comments-wrap {
            .comments-heading {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 16px;

                h4 {
                    font-size: 1rem;
                    font-weight: 500;
                    color: $medium-text;
                }

                .close-comments {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    background: transparent;
                    cursor: pointer;
                    transition: background .3s;

                    &:hover {
                        background: lighten($placeholder, 10%);
                    }

                    svg {
                        height: 18px;
                        width: 18px;
                        stroke: $medium-text;
                    }
                }
            }

            .comments-body {
                padding: 16px;
                max-height: 450px;
                overflow-y: auto;

                .comments-placeholder {
                    text-align: center;

                    img {
                        display: block;
                        max-width: 80px;
                        margin: 0 auto;
                        opacity: .7;
                    }

                    h3 {
                        margin-top: 10px;
                        font-weight: 500;
                        font-size: 1.1rem;
                        color: $dark-text;
                    }

                    p {
                        font-size: .9rem;
                        color: $muted-grey;
                    }
                }

                .is-comment {
                    align-items: stretch;

                    .media-left {
                        img {
                            height: 36px;
                            width: 36px;
                            min-width: 36px;
                            border-radius: 50%;
                        }
                    }

                    .media-content {
                        a {
                            font-size: .85rem;
                            font-weight: 500 !important;
                            display: block;
                            color: $dark-text;
                            line-height: 1.2;

                            &:hover {
                                color: $primary;
                            }
                        }

                        .time {
                            display: block;
                            font-size: .7rem;
                            color: $grey-text;
                            margin-bottom: 10px;
                        }

                        p {
                            font-size: .9rem !important;
                            color: $medium-text;
                        }

                        .controls {
                            padding: 8px 0;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;

                            .like-count {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                cursor: pointer;

                                span {
                                    display: block;
                                    font-size: .8rem;
                                    color: $grey-text;
                                    margin: 0 5px;
                                }

                                svg {
                                    position: relative;
                                    top: -1px;
                                    height: 12px;
                                    width: 12px;
                                    stroke: $grey-text;
                                }

                                &:hover {
                                    span {
                                        color: $primary;
                                    }

                                    svg {
                                        stroke: $primary;
                                    }
                                }
                            }

                            .reply, .edit {
                                margin: 0 5px;

                                a {
                                    font-size: .75rem;
                                    color: $grey-text;

                                    &:hover {
                                        color: $primary;
                                    }
                                }
                            }
                        }
                    }

                    .media-right {
                        svg {
                            height: 18px;
                            width: 18px;
                            stroke: $medium-text;
                        }

                        .dropdown {
                            .button {
                                padding: 4px !important;
                                border-color: transparent !important;
                            }

                            .media {
                                border: none !important;
                                padding-top: 0 !important;
                            }
                        }
                    }

                    .is-comment {
                        .media-left {
                            position: relative;

                            &:after {
                                content: '';
                                position: absolute;
                                top: 52px;
                                left: 0;
                                right: 0;
                                margin: 0 auto;
                                width: 1.4px;
                                height: calc(100% - 46px);
                                background: $fade-grey;
                            }

                            &:before {
                                content: '';
                                position: absolute;
                                top: calc(50% + 20px);
                                left: 0;
                                right: 0;
                                margin: 0 auto;
                                height: 13px;
                                width: 13px;
                                border: 1px solid $fade-grey;
                                border-radius: 50%;
                                background: $white;
                                box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06) !important;
                                z-index: 1;
                            }
                        }
                    }
                }

                .load-more {
                    .load-more-button {
                        background: lighten($placeholder, 10%);
                        width: 80px;
                        padding: 5px 16px;
                        border-radius: 500px;
                        border: none;
                        cursor: pointer;
                        transition: background-color .3s;

                        &:active, &:focus {
                            outline: none;
                        }

                        &:hover {
                            background: lighten($placeholder, 14%);
                        }

                        svg {
                            width: 22px;
                            height: 22px;
                            stroke: $grey-text;
                        }
                    }
                }
            }

            .card-footer {
                margin: 0 !important;
                padding: 16px;

                .post-comment {
                    width: 100%;

                    .media-left {
                        img {
                            border-radius: 50%;
                        }
                    }

                    .media-content {
                        .field {
                            margin-bottom: 0;
                        }

                        .textarea {
                            border-color: darken($fade-grey, 3%);
                            border-bottom: none;
                            border-radius: 4px 4px 0 0;
                            resize: none;
                        }
                    }

                    .actions {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 8px;
                        border-left: 1px solid $fade-grey;
                        border-right: 1px solid $fade-grey;
                        border-bottom: 1px solid $fade-grey;
                        border-radius: 0 0 4px 4px;

                        .toolbar {
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                        }

                        .action {
                            margin-right: 15px;
                            width: 18px;
                            height: 18px;
                            cursor: pointer;

                            svg {
                                width: 18px;
                                height: 18px;
                                stroke: $grey-text;
                                transition: all .3s;
                            }

                            &:hover {
                                svg {
                                    stroke: $primary;
                                }
                            }

                            &.is-auto {
                                margin-left: auto;
                            }

                            &.is-upload {
                                position: relative;

                                input {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    opacity: 0;
                                    cursor: pointer;
                                }
                            }
                        }
                    }

                    .emojionearea-scroll-area {
                        &::-webkit-scrollbar {
                            width: 4px;
                        }

                        &::-webkit-scrollbar-thumb {
                            border-radius: 10px;
                            background: rgba(0, 0, 0, 0.2);
                        }
                    }
                }
            }
        }
    }

    .card-body {
        .page-block, .add-friend-block {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 16px;

            &:not(:first-child) {
                border-top: 1px solid $fade-grey;
            }

            img {
                height: 40px;
                width: 40px;
                max-height: 40px;
                border-radius: 50%;
            }

            .page-meta {
                padding: 0 10px;
                line-height: 1.3;

                span {
                    display: block;

                    &:first-child {
                        font-size: .85rem;
                        color: $dark-text;
                        font-weight: 500;
                    }

                    &:nth-child(2) {
                        font-size: .7rem;
                        color: $medium-text;
                    }
                }
            }

            .add-page, .add-friend {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 36px;
                height: 36px;
                margin-left: auto;
                border-radius: 50%;
                transition: all .3s;
                cursor: pointer;

                svg {
                    width: 18px;
                    height: 18px;
                    stroke: $grey-text;
                    transition: all .3s;
                }

                &:hover {
                    background: lighten($fade-grey, 5%);

                    svg {
                        stroke: $accent;
                    }
                }
            }

            .checkmark-wrapper {
                margin-left: auto;
                margin-right: 8px;
            }
        }

        .story-block {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 16px 16px;

            &:not(:first-child) {
                border-top: 1px solid $fade-grey;
            }

            .img-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 48px;
                width: 48px;
                max-height: 48px;
                min-width: 48px;
                border-radius: 50%;
                border: 1px solid $grey-text;

                img {
                    height: 38px;
                    width: 38px;
                    border-radius: 50%;
                }
            }

            .add-story {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 44px;
                width: 44px;
                max-height: 44px;
                min-width: 44px;
                border-radius: 50%;
                border: 2px dashed $fade-grey;
                transition: all .3s;

                svg {
                    width: 18px;
                    height: 18px;
                    stroke: $fade-grey;
                }

                &:hover {
                    border: 2px solid $primary;

                    svg {
                        stroke: $primary;
                    }
                }
            }

            .story-meta {
                padding: 0 10px;

                span {
                    display: block;

                    &:first-child {
                        font-size: .85rem;
                        color: $dark-text;
                        font-weight: 500;
                    }

                    &:nth-child(2) {
                        font-size: .8rem;
                        color: $medium-text;
                    }
                }
            }
        }
    }
}

/* ==========================================================================
2. Options summary
========================================================================== */

.options-summary {
    position: relative;
    display: inline-block;
    padding-bottom: 2px;
    width: auto;
    color: $grey-text;
    background: lighten($placeholder, 16%);
    border-radius: 500px;
    transition: all .3s;

    &:hover {
        background: lighten($placeholder, 14%);
    }

    .tagged-friend + span small {
        display: none !important;
    }

    span {
        padding: 2px 8px;

        a {
            font-size: .8rem;
        }
    }

    .mood-display {
        padding: 0 4px;
        cursor: pointer;

        img {
            position: relative;
            top: 2px;
            height: 14px !important;
            width: 14px !important;
        }

        span {
            font-size: .8rem;
            color: $accent;
            margin: 0 4px;
        }

        .action-text {
            color: $dark-text;
            margin-right: 4px;
        }
    }
}

/* ==========================================================================
3. Tag list
========================================================================== */

.tag-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 8px;
    margin-bottom: 8px;

    &.no-margin {
        margin: 0 !important;
    }

    .tag-control {
        width: auto !important;
        padding-right: 6px;
        margin: 0 0 4px 0 !important;

        &.is-spaced {
            padding: 4px;
        }

        .is-link {
            background: $grey-text !important;
        }
    }
}

/* ==========================================================================
4. Autocompletes
========================================================================== */

.is-autocomplete {
    justify-content: center;

    &.is-active, &:hover {
        background: transparent !important;
    }

    &.is-dropup {
        .easy-autocomplete-container {
            top: -260px !important;
            width: calc(100% - 40px);
            margin: 0 auto;
            right: 0;
        }
    }

    .control {
        margin: 0 !important;
        position: relative;

        &.has-margin {
            margin-top: 8px !important;
        }

        &.is-location-wrapper, &.is-gif-wrapper {
            input {
                height: 36px;
                padding-left: 36px;
                font-size: .9rem;
                box-shadow: none;
                color: $muted-grey;
                transition: all .3s;

                &:focus {
                    border-color: $fade-grey;

                    + .icon {
                        color: $primary;
                    }
                }
            }
        }

        &.is-gif-wrapper {
            position: relative;

            input {
                &:focus {
                    ~ .gif-dropdown {
                        opacity: 1;
                        pointer-events: all;
                    }
                }
            }

            .gif-dropdown {
                position: absolute;
                top: 36px;
                left: 0;
                right: 0;
                width: 100%;
                margin: 0 auto;
                height: 320px;
                overflow-y: auto;
                background: $white;
                border: 1px solid $fade-grey;
                border-radius: 4px;
                z-index: 40;
                opacity: 0;
                pointer-events: none;
                transition: all .3s;

                &::-webkit-scrollbar {
                    width: 4px;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    background: rgba(0, 0, 0, 0.2);
                }

                .inner {
                    position: relative;
                    display: flex;
                }

                .gif-block {
                    display: block;
                    margin: 0 auto;
                    width: 33.3%;
                    padding: 2px;

                    img {
                        display: block;
                        border-radius: 0 !important;
                        width: 100%;
                        height: auto;
                        margin-bottom: 4px;
                    }
                }
            }
        }

        .icon, .close-icon {
            position: absolute;
            top: 0;
            left: 0;
            height: 36px;
            width: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $placeholder;

            svg {
                width: 16px;
                height: 16px;
                transition: all .3s;
            }
        }

        .close-icon {
            left: unset !important;
            right: 0 !important;
            cursor: pointer;

            &:hover {
                svg {
                    color: $dark-text;
                }
            }
        }

        .input-block {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            font-size: .8rem;
            color: $white-light;
            padding: 0 10px;
            height: 36px;
            width: auto;
            border-radius: 4px 0 0 4px;
            background: $medium-text;
            transition: all 0.3s;
            cursor: pointer;

            &:hover {
                background: lighten($medium-text, 5%);
            }
        }

        .easy-autocomplete {
            width: 100% !important;

            input {
                height: 36px;
                padding-left: 36px;
                font-size: .9rem;
                box-shadow: none;
                color: $muted-grey;
                transition: all .3s;

                //Modifiers
                &.is-subactivity {
                    padding-left: 74px !important;
                }

                &:focus {
                    border-color: $fade-grey;

                    + .icon {
                        color: $primary;
                    }
                }
            }

            .template-wrapper {
                display: flex !important;
                justify-content: flex-start;
                align-items: center;

                .avatar-wrapper {
                    position: relative;
                    width: 36px;
                    height: 36px;

                    &.is-smaller {
                        width: 22px !important;
                        height: 22px !important;

                        .autocpl-avatar {
                            width: 22px !important;
                            height: 22px !important;
                        }
                    }

                    .autocpl-avatar {
                        width: 36px;
                        height: 36px;
                        border-radius: 50%;

                        &.is-squared {
                            border-radius: 8px !important;
                        }
                    }

                    .avatar-badge {
                        position: absolute;
                        bottom: 0;
                        right: -3px;
                        width: 18px;
                        height: 18px;
                        border: 1.4px solid $white;
                        border-radius: 50%;
                    }
                }

                .icon-wrapper {
                    position: relative;
                    width: 22px;
                    height: 22px;

                    .autocpl-avatar {
                        width: 22px;
                        height: 22px;
                        border-radius: 50% !important;
                    }
                }

                .entry-text {
                    margin-left: 10px;
                    line-height: 1.3;

                    span {
                        font-size: 0.8rem;
                        color: $medium-text;
                    }
                }

                .next-icon {
                    margin-left: auto !important;

                    i {
                        font-size: 20px;
                        color: $grey-text;
                    }
                }

                .is-description {
                    font-size: .7rem;
                }

                .right-content {
                    margin-left: auto;
                    font-size: .75rem;
                    color: $muted-grey;
                }
            }
        }
    }

    li {
        height: auto !important;
        padding-top: 8px;
        padding-bottom: 8px;
        border-left: none !important;
        border-right: none !important;
    }

    .easy-autocomplete-container {
        top: 120% !important;
        border: none !important;
        z-index: 10 !important;

        ul {
            border-radius: 4px !important;
            box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06) !important;
        }
    }
}

/* ==========================================================================
5. Feed v1 left menu
========================================================================== */

.feed-menu-v1 {
    .main-menu {
        padding-right: 30px;

        li {
            transition: all .3s;

            &.is-active {
                a {
                    border-color: $accent;

                    span {
                        color: $dark-text;
                        font-weight: 500;
                    }

                    svg {
                        stroke: $dark-text;
                    }

                    .close-icon {
                        transform: rotate(90deg);
                        background: $fade-grey;

                        svg {
                            stroke: $dark-text;
                        }
                    }
                }
            }

            &:hover {
                .close-icon {
                    background: $fade-grey;

                    svg {
                        stroke: $dark-text;
                    }
                }
            }

            a {
                display: flex;
                align-items: center;
                padding: 8px 16px;
                border-left: 2px solid transparent;

                span {
                    display: block;
                    color: $dark-text;
                    font-size: .9rem;
                    margin-left: 10px;
                }

                svg {
                    position: relative;
                    height: 18px;
                    width: 18px;
                    stroke: $medium-text;
                }

                .close-icon {
                    height: 28px;
                    width: 28px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: auto;
                    border-radius: 50%;
                    transition: all .3s;

                    svg {
                        stroke: $placeholder;
                        height: 16px;
                        width: 16px;
                    }
                }
            }
        }
    }

    .submenu {
        padding-left: 50px;
        margin-bottom: 10px;
        display: none;

        li {
            &.is-subactive {
                a {
                    color: $dark-text;
                    font-weight: 500;
                }
            }

            a {
                display: flex;
                align-items: center;
                padding: 6px;
                font-size: .85rem;
                color: $medium-text;
                border: none !important;
                transition: color .3s;

                .tag {
                    margin-left: auto;
                    display: inherit;
                    max-height: 19px;
                    font-size: .75rem;
                    border-radius: 100px;
                    margin-right: 10px;
                    background: $accent;
                    color: $white !important;
                }
            }
        }
    }
}

/* ==========================================================================
6. Media Queries
========================================================================== */

@media (max-width: 767px) {
    .card {
        &.is-post {
            .likers-text {
                display: none;
            }

            .post-link {
                flex-direction: column;

                .link-image {
                    margin: 0 auto;
                    width: 100%;
                    height: 100%;
                    max-height: 180px;

                    > img {
                        width: 100% !important;
                        height: 100% !important;
                        max-height: 180px;
                        object-fit: cover;
                    }
                }

                .link-content {
                    margin-left: 0 !important;
                    padding: 16px 6px;
                }
            }
        }
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .compose .compose-form img {
        height: 36px !important;
        width: 36px !important;
    }

    .card {
        .page-block, .add-friend-block {
            img {
                height: 32px !important;
                width: 32px !important;
            }
        }

        .story-block {
            .img-wrapper {
                height: 40px !important;
                width: 40px !important;
                max-height: 40px !important;
                min-width: 40px !important;

                img {
                    height: 32px !important;
                    width: 32px !important;
                }
            }

            .add-story {
                height: 38px !important;
                width: 38px !important;
                max-height: 38px !important;
                min-width: 38px !important;
            }
        }
    }

    .list-placeload {
        .img {
            height: 32px !important;
            width: 32px !important;
            min-width: 32px !important;
        }
    }

    .stories-placeload {
        .img {
            height: 38px !important;
            width: 38px !important;
            max-height: 38px !important;
            min-width: 38px !important;
        }
    }
}
