/*! _app-login.scss | Crowdkit | © Css Ninja. 2020-2021 */

/* ==========================================================================
Login styles
========================================================================== */

/*
    0. Login page layout
    1. Minimal login page layout
    2. Media Queries
*/

/* ==========================================================================
0. Login page layout
========================================================================== */

.login-wrapper {
    .hero {
        &.is-login {
            position: relative;
            background: $accent;
            background: linear-gradient(180deg, lighten($accent, 16%), $accent);
            background-size: 400% 400%;
            animation: gradientShift 12s ease infinite;

            .left-caption {
                max-width: 400px;
                margin: 0 auto;

                h2 {
                    font-family: 'Montserrat', sans-serif;
                    font-weight: bold;
                    font-size: 3.2rem;
                    color: $white-smoke;
                    line-height: 1.3;
                    text-shadow: 4px 4px darken($accent, 8%), 8px 8px darken($accent, 8%);
                }
            }
        }

        &.form-hero {
            position: relative;

            .logo-wrap {
                position: absolute;
                top: calc(50% - 50px);
                left: -50px;
                height: 100px;
                width: 100px;
                border-radius: 50%;
                background: $white;
                display: flex;
                justify-content: center;
                align-items: center;
                transform: rotate(32deg);

                .wrap-inner {
                    height: 86px;
                    width: 86px;
                    border-radius: 50%;
                    background: $accent;
                    background: linear-gradient(180deg, lighten($accent, 16%), $accent);
                    background-size: 400% 400%;
                    animation: gradientShift 45s ease infinite;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        display: block;
                        max-width: 54px;
                    }
                }
            }
        }
    }

    .form-wrapper {
        max-width: 540px;
        min-width: 380px;
        margin: 0 auto;

        .avatar {
            position: relative;
            height: 110px;
            width: 110px;
            border-radius: 50%;
            margin: 0 auto 20px auto;
            border: 1.4px solid $placeholder;
            display: flex;
            justify-content: center;
            align-items: center;

            .badge {
                position: absolute;
                top: 0;
                right: 0;
                height: 32px;
                width: 32px;
                border-radius: 50%;
                border: 3px solid $white;
                display: flex;
                justify-content: center;
                align-items: center;
                background: $green;

                svg {
                    height: 14px;
                    width: 14px;
                    stroke: $white;
                    stroke-width: 4px;
                }
            }

            img {
                display: block;
                height: 94px;
                width: 94px;
                border-radius: 50%;
            }
        }

        .login-form {
            .field {
                .control {
                    position: relative;

                    .input {
                        height: 40px;
                        padding-left: 40px;

                        &:focus {
                            border-color: $accent;

                            + .input-icon {
                                svg {
                                    stroke: $accent;
                                }
                            }
                        }
                    }

                    .input-icon {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 40px;
                        width: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        svg {
                            height: 18px;
                            width: 18px;
                            stroke: $placeholder;
                            transition: stroke .3s;
                        }
                    }

                    button, .button {
                        height: 46px;
                        font-size: .95rem;
                    }
                }
            }
        }

        .forgot-password {
            padding: 1.5rem !important;

            a {
                color: $muted-grey;
                font-size: .9rem;

                &:hover {
                    color: $accent;
                }
            }
        }
    }
}

/* ==========================================================================
1. Minimal login page layout
========================================================================== */

.login-container {
    height: calc(100vh - 100px);
    padding: 20px;

    &.is-centered {
        padding-top: 5rem;

        .login-form {
            margin: 0 auto;
        }
    }

    .login-image {
        max-width: 620px;
        margin: 0 auto;
    }

    .form-title {
        font-family: 'Roboto', sans-serif;
        font-size: 1.5rem;
        color: $dark-text;
        font-weight: 300;
    }

    .form-subtitle {
        font-family: 'Roboto', sans-serif;
        font-size: .9rem;
        color: $light-text;
        margin-bottom: 1rem;
    }

    .login-form {
        max-width: 380px;

        .columns {
            padding-top: 1rem;
    
            .column {
                padding-top: .35rem;
                padding-bottom: .35rem;
            }
        }
    

        .field {
            &.is-flex {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 0 16px 0;

                .switch-block {
                    display: flex;
                    align-items: center;

                    .f-switch {
                        transform: scale(0.9);
                    }

                    .meta {
                        margin-left: 6px;

                        p {
                            font-size: .9rem;
                            color: $muted-grey;
                        }
                    }
                }

                a {
                    font-family: 'Roboto', sans-serif;
                    font-size: .9rem;
                    color: $light-text;
                    transition: color .3s;

                    &:hover {
                        color: $primary;
                    }
                }
            }
        }

        .account-link {
            a {
                font-family: 'Roboto', sans-serif;
                font-size: .8rem;
                color: $light-text;
                transition: color .3s;

                &:hover {
                    color: $primary;
                }
            }
        }
    }
}

/* ==========================================================================
2. Media Queries
========================================================================== */

@media (max-width: 767px) {
    .login-wrapper {
        .logo-wrap {
            display: none !important;
        }

        .form-wrapper {
            min-width: 320px !important;
        }
    }

    .login-container {
        .image-column {
            display: none;
        }

        .form-title, .form-subtitle {
            text-align: center;
        }

        .form-title {
            padding-top: 60px;
        }
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .login-wrapper {
        .hero.is-login {
            .left-caption h2 {
                font-size: 2.5rem;
            }
        }
    }

    .login-container {
        .columns {
            padding-top: 60px;
        }
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .login-wrapper {
        .logo-wrap {
            display: none !important;
        }
    }

    .login-container {
        .image-column {
            display: none;
        }

        .column {
            min-width: 50%;
        }

        .form-title, .form-subtitle {
            text-align: center;
        }

        .form-title {
            padding-top: 60px;
        }

        .login-form {
            max-width: 360px;
            margin: 0 auto;
        }
    }
}
