/*! _datepicker.scss | Friendkit | © Css Ninja. 2020-2021 */

/* ==========================================================================
Datepicker styles
========================================================================== */

/*
    0. Container
    1. Dropdown
    2. Panel
*/

/* ==========================================================================
0. Container
========================================================================== */

.datepicker-container {
    font-size: 12px;
    line-height: 30px;
    padding: 20px;
    border-radius: 5px;
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 210px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #fff;
    direction: ltr !important;
    -ms-touch-action: none;
    touch-action: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

.datepicker-container:before,
.datepicker-container:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    content: ' ';
    border: 5px solid transparent;
}

/* ==========================================================================
1. Dropdown
========================================================================== */

.datepicker-dropdown {
    position: absolute;
    z-index: 999999 !important;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    border: 1px solid #ccc;
    -webkit-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
}

.datepicker-inline {
    position: static;
}

.datepicker-top-left:before,
.datepicker-top-left:after,
.datepicker-top-right:before,
.datepicker-top-right:after {
    top: -5px;
    left: 10px;
    border-top: 0;
}

.datepicker-top-left:before,
.datepicker-top-right:before {
    border-bottom-color: #ccc;
}

.datepicker-top-left:after,
.datepicker-top-right:after {
    top: -4px;
    border-bottom-color: #fff;
}

.datepicker-bottom-left:before,
.datepicker-bottom-left:after,
.datepicker-bottom-right:before,
.datepicker-bottom-right:after {
    bottom: -5px;
    left: 10px;
    border-bottom: 0;
}

.datepicker-bottom-left:before,
.datepicker-bottom-right:before {
    border-top-color: #ccc;
}

.datepicker-bottom-left:after,
.datepicker-bottom-right:after {
    bottom: -4px;
    border-top-color: #fff;
}

.datepicker-top-right:before,
.datepicker-top-right:after,
.datepicker-bottom-right:before,
.datepicker-bottom-right:after {
    right: 10px;
    left: auto;
}

/* ==========================================================================
2. Panel
========================================================================== */

.datepicker-panel > ul:before,
.datepicker-panel > ul:after {
    display: table;
    content: ' ';
}

.datepicker-panel > ul:after {
    clear: both;
}

.datepicker-panel > ul {
    width: 102%;
    margin: 0;
    padding: 0;
}

.datepicker-panel > ul:first-child li {
    line-height: 25px;
}

.datepicker-panel > ul:first-child li:nth-child(2) {
    line-height: 28px !important;
}

.datepicker-panel > ul > li {
    float: left;
    width: 30px;
    height: 30px;
    margin: 0;
    padding: 0;
    list-style: none;
    cursor: pointer;
    text-align: center;
    background-color: #fff;
}

.datepicker-panel > ul > li:hover {
    background-color: #ededed;
    color: #444 !important;
    border-radius: 50px;
}

.datepicker-panel > ul > li.muted,
.datepicker-panel > ul > li.muted:hover {
    color: #999;
}

.datepicker-panel > ul > li.highlighted {
    color: #5d4394 !important;
    border-radius: 50px;
}

.datepicker-panel > ul > li.highlighted:hover {
    opacity: 0.8;
    color: #5d4394 !important;
}

.datepicker-panel > ul > li.picked,
.datepicker-panel > ul > li.picked:hover {
    color: #fff !important;
    background-color: $accent;
    border-radius: 50px;
}

.datepicker-panel > ul > li.picked {
    animation-name: gelatine;
    animation-duration: 0.5s;
    animation-timing-function: ease-in;
}

.datepicker-panel > ul > li.disabled,
.datepicker-panel > ul > li.disabled:hover {
    cursor: default;
    color: #ccc;
    background-color: #fff;
}

.datepicker-panel > ul > li.disabled.highlighted,
.datepicker-panel > ul > li.disabled:hover.highlighted {
    background-color: #e6f2ff;
}

.datepicker-panel > ul > li[data-view='years prev'],
.datepicker-panel > ul > li[data-view='year prev'],
.datepicker-panel > ul > li[data-view='month prev'],
.datepicker-panel > ul > li[data-view='years next'],
.datepicker-panel > ul > li[data-view='year next'],
.datepicker-panel > ul > li[data-view='month next'],
.datepicker-panel > ul > li[data-view='next'] {
    font-size: 18px;
}

.datepicker-panel > ul > li[data-view='years current'],
.datepicker-panel > ul > li[data-view='year current'],
.datepicker-panel > ul > li[data-view='month current'] {
    width: 150px;
}

.datepicker-panel > ul[data-view='years'] > li,
.datepicker-panel > ul[data-view='months'] > li {
    line-height: 52.5px;
    width: 52.5px;
    height: 52.5px;
}

.datepicker-panel > ul[data-view='week'] > li,
.datepicker-panel > ul[data-view='week'] > li:hover {
    cursor: default;
    background-color: #fff;
}

.datepicker-hide {
    display: none;
}
