/*! _app-landing.scss | Friendkit | © Css Ninja. 2020-2021 */

/* ==========================================================================
Landing page styles
========================================================================== */

/*
    1. Landing Hero
    2. Landing page content
    3. Side Features
    4. Demos Section
    5. CTA Section
    6. Media Queries
*/

//import mixins
@import "../abstracts/mixins";

/* ==========================================================================
1. Landing Hero
========================================================================== */

.landing-hero-wrapper {
    position: relative;
    background-color: $white;
    background-image: -webkit-linear-gradient(40deg, $white 50%, lighten($accent, 5%) 50%);

    #particles-js {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }

    .landing-caption {
        h2 {
            font-family: $font-main;
            font-size: 5rem;
            font-weight: bold;
            color: $white-smoke;
            line-height: 1;
            text-shadow: 5px 5px darken($accent, 8%), 10px 10px darken($accent, 8%);
        }

        h3 {
            font-family: $font-main;
            font-size: 1.8rem;
            font-weight: 300;
            color: $white-smoke;
            padding: 0 10px;
            text-shadow: 2px 2px darken($accent, 8%), 5px 5px darken($accent, 8%);
        }

        .button {
            margin: 20px 10px;
            height: 48px;
            text-transform: uppercase;
            font-size: .95rem;
            min-width: 190px;
            color: $accent;

            &:hover {
                box-shadow: $accent-box-shadow;
            }
        }
    }
}

.header-logo {
    height: 44px;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid $accent;
    box-shadow: $accent-box-shadow;
    background: $accent;
    margin: 10px auto;

    img {
        display: block;
        height: 32px;
        width: 32px;
    }
}

/* ==========================================================================
2. Landing page content
========================================================================== */

.landing-wrapper {
    &.is-grey {
        background: lighten($fade-grey, 7%);
    }

    .made-with {
        max-width: 640px;
        margin: 0 auto 3rem auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .made-block {
            width: calc(25% - 10px);
            margin: 10px;

            .block-icon {
                height: 90px;
                width: 90px;
                margin: 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid $fade-grey;
                border-radius: 50%;
                box-shadow: $light-box-shadow;

                img {
                    display: block;
                    max-width: 60px;
                }
            }

            .block-title {
                text-align: center;
                margin-top: 12px;

                h3 {
                    font-family: $font-main;
                    font-weight: 600;
                    color: $dark-text;
                }
            }
        }
    }

    .screen-wrapper {
        border-bottom: 1.4px solid $fade-grey;
        //overflow: hidden;

        &.is-spaced {
            margin: 5rem 0;
        }

        &.has-plant {
            position: relative;

            .plant-1 {
                position: absolute;
                bottom: -1rem;
                right: -5rem;
                max-height: 250px;
                max-width: 170px;
            }

            .plant-2 {
                position: absolute;
                bottom: -1rem;
                left: -4rem;
                max-height: 250px;
                max-width: 170px;
            }
        }

        img:not(.plant) {
            display: block;
            border-radius: 8px 8px 0 0;
            border: 1px solid $fade-grey;
            border-bottom: none;
            box-shadow: $light-box-shadow;
            width: 100%;
            max-width: 940px;
            margin: 0 auto;
        }
    }

    .landing-icon-box {
        text-align: center;
        margin-bottom: 20px;

        svg {
            height: 30px;
            width: 30px;
            stroke-width: 1.4px;
            margin: 0 auto;
        }

        img {
            display: block;
            height: 54px;
            width: 54px;
            margin: 0 auto 4px auto;
        }

        h3 {
            font-family: $font-main;
            color: $dark-text;
            font-weight: 500;
            font-size: .95rem;
        }

        p {
            font-size: .9rem;
            color: $muted-grey;
        }
    }
}

.section {
    &.is-grey {
        background: lighten($fade-grey, 7%);
    }
}

/* ==========================================================================
3. Side Features
========================================================================== */

.side-feature {
    h3 {
        font-family: $font-main;
        font-weight: bold;
        color: $dark-text;
        font-size: 2rem;
    }

    p {
        color: $muted-grey;
        font-size: 1rem;
        max-width: 440px;
    }

    img {
        display: block;
        max-width: 460px;
        margin: 0 auto;
    }
}

/* ==========================================================================
4. Demos Section
========================================================================== */

.demo-section {
    margin-bottom: 100px;

    .demo-section-title {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        img {
            display: block;
            width: 100%;
            max-width: 100px;
        }

        .title-meta {
            margin-left: 12px;
            line-height: 1.2;

            h3 {
                font-family: $font-main;
                font-weight: 500;
                font-size: 1.25rem;
                text-transform: capitalize;
                color: $dark-text;
                margin-bottom: .25rem;
                padding: 0;
            }

            p {
                font-family: $font;
                font-size: .9rem;
                color: $light-text;
            }
        }
    }
}

.demo-wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: $light-box-shadow;

    &:hover {
        .circle-overlay {
            transform: scale(35);
        }

        .demo-title {
            opacity: 1;
            transform: translateY(0);
            transition-delay: 0 !important;
        }

        .demo-link {
            color: $white;
            opacity: 1;
            transform: translateY(0);
            transition-delay: 0 !important;
        }
    }

    .new-tag {
        position: absolute;
        bottom: 10px;
        right: 10px;
        background: red;
        color: $white;
        font-family: "Roboto", sans-serif;
        padding: 6px 16px;
        border-radius: 8px;
        text-transform: uppercase;
        font-size: .8rem;
        z-index: 8;
    }

    img {
        border: 1px solid darken($fade-grey, 3%);
        border-radius: 4px;
        display: block;
    }

    .circle-overlay {
        position: absolute;
        top: -60px;
        left: -60px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: $primary;
        transform: scale(1);
        transition: all 0.5s;
        z-index: 1;
    }

    .demo-info {
        position: absolute;
        margin: 0 auto;
        top: 35%;
        left: 0;
        right: 0;
        z-index: 2;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .demo-title {
        color: $white;
        font-family: $font-main;
        font-size: 1.5rem;
        font-weight: 600;
        padding-bottom: 0;
        opacity: 0;
        transform: translateY(20px);
        transition: all 0.3s;
        transition-delay: 0.1s;
    }

    .demo-link {
        opacity: 0;
        transform: translateY(20px);
        transition: all 0.3s;
        transition-delay: 0.2s;

        a {
            color: $white;
            font-size: 1rem;

            i {
                position: relative;
                top: 8px;
                opacity: 0;
                transform: translateX(0);
                transition: all 0.3s ease-out;
            }

            svg {
                position: relative;
                top: 5px;
                height: 20px;
                width: 20px;
                opacity: 0;
                transform: translateX(0);
                transition: all 0.3s ease-out;
            }

            &:hover i, &:hover svg {
                opacity: 1;
                transform: translateX(5px);
            }
        }
    }
}

.demos-wrapper {
    background: lighten($fade-grey, 7%);

    .demos-title {
        h3 {
            font-family: $font-main;
            font-weight: bold;
            font-size: 1.6rem;
            color: $dark-text;
        }
    }

    .demos-list {
        padding: 80px 0;

        .demo-item {
            position: relative;
            display: block;
            overflow: hidden;

            &:hover {
                img {
                    filter: grayscale(0);
                    opacity: 1;
                    box-shadow: $light-box-shadow;
                }

                .go-arrow {
                    transform: translate(0, 0);
                }
            }

            .go-arrow {
                position: absolute;
                bottom: -20px;
                right: -20px;
                height: 80px;
                width: 80px;
                background: darken($accent, 5%);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                transform: translate(80px, 80px);
                box-shadow: $accent-box-shadow;
                transition: all .3s;
                z-index: 2;

                &:hover {
                    background: $primary;
                    box-shadow: $primary-box-shadow;
                }

                svg {
                    position: relative;
                    top: -6px;
                    left: -6px;
                    height: 22px;
                    width: 22px;
                    stroke: $white;
                }
            }

            img {
                display: block;
                border: 1px solid $fade-grey;
                filter: grayscale(0.8);
                opacity: .85;
                transition: all .3s;
            }
        }

        h3 {
            padding: 16px 0;
            color: $dark-text;
            text-transform: uppercase;
            font-weight: 500;
            font-size: .8rem;
            font-family: $font-main;
            transition: color .3s;
        }
    }
}

/* ==========================================================================
5. CTA Section
========================================================================== */

.cta-wrapper {
    .cta-title {
        text-align: center;

        h3 {
            font-family: $font-main;
            font-weight: bold;
            font-size: 1.6rem;
            color: $dark-text;
        }

        .custom-button {
            display: flex;
            align-items: center;
            margin: 10px auto;
            max-width: 200px;
            border: 2px solid #D3F4ED;
            border-radius: 100px;
            transition: all .3s;

            &:hover {
                background: #D3F4ED;
                box-shadow: $light-box-shadow;

                span {
                    color: $dark-text;
                }
            }

            img {
                display: block;
                height: 50px;
                width: 50px;
            }

            span {
                display: block;
                font-family: $font-main;
                font-weight: 500;
                font-size: 1rem;
                color: #8EDFCF;
                margin-left: 12px;
                transition: color .3s;
            }
        }
    }

    .people-img {
        display: block;
        max-width: 480px;
        margin: 0 auto;
    }
}

/* ==========================================================================
6. Media Queries
========================================================================== */

@media (max-width: 767px) {
    .landing-hero-wrapper {
        .landing-caption {
            text-align: center;

            h2 {
                margin-top: 40px;
                font-size: 2.6rem;
                color: $accent;
                text-shadow: 5px 5px lighten($accent, 44%), 10px 10px lighten($accent, 44%);
            }

            h3 {
                color: $accent;
                text-shadow: 2px 2px lighten($accent, 44%), 4px 4px lighten($accent, 44%);
            }

            .buttons .button {
                margin-left: auto !important;
                margin-right: auto !important;
            }
        }
    }

    .side-feature {
        img {
            max-width: 340px;
        }
    }

    .landing-wrapper {
        .made-with {
            flex-wrap: wrap;

            .made-block {
                width: calc(45% - 10px);
            }
        }

        .screen-wrapper {

            &.has-plant {
                .plant-1, .plant-2 {
                    max-height: 160px;
                    max-width: 110px;
                }

                .plant-1  {
                    right: -2rem;
                }

                .plant-2 {
                    left: -2rem;
                }
            }

            img {
                max-width: 100%;
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .landing-hero-wrapper {
        .landing-caption {
            text-align: center;

            img {
                max-width: 580px;
            }

            h2 {
                color: $accent;
                text-shadow: 5px 5px lighten($accent, 44%), 10px 10px lighten($accent, 44%);
            }

            h3 {
                color: $accent;
                text-shadow: 2px 2px lighten($accent, 44%), 4px 4px lighten($accent, 44%);
            }

            .buttons .button {
                margin-left: auto !important;
                margin-right: auto !important;
            }
        }
    }

    .landing-wrapper {
        &.icons-section {
            .columns {
                display: flex;
            }
        }

        .screen-wrapper {
            img {
                max-width: 100%;
            }
        }
    }

    .side-feature {
        h3, p {
            text-align: center;
            margin: 0 auto;
        }

        img {
            max-width: 450px;
            margin: 0 auto;
            display: block;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .cta-wrapper {
        .people-img {
            max-width: 600px;
        }
    }

    #tour-start {
        display: none !important;
    }
}
