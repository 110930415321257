/*! _app-demo.scss | Friendkit | © Css Ninja. 2020-2021 */

/* ==========================================================================
Demo & elements Styles
========================================================================== */

/*
    0. Elements Sidebar
    1. Elements Wrap
    2. Media Queries
*/

/* ==========================================================================
0. Elements Sidebar
========================================================================== */

.elements-sidebar {
    position: fixed;
    top: 58px;
    left: 0;
    width: 300px;
    height: calc(100% - 58px);
    background: $white;
    border-right: 1px solid $fade-grey;

    .header {
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0 20px;

        h3 {
            text-transform: uppercase;
        }
    }

    .elements-list {
        position: relative;
        height: calc(100% - 60px);
        overflow-y: auto;

        .element-menu {
            border-top: 1px solid $fade-grey;

            .menu-trigger {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px;

                &:hover, &.is-active {
                    background: lighten($fade-grey, 5%);

                    span {
                        color: $dark-text;
                    }

                    svg {
                        stroke: $dark-text;
                    }
                }

                &.is-active {
                    svg {
                        transform: rotate(90deg);
                    }
                }

                span {
                    display: block;
                    color: $muted-grey;
                    transition: color .3s;
                }

                svg {
                    height: 16px;
                    width: 16px;
                    stroke: $muted-grey;
                    transition: all .3s;
                }
            }

            .submenu-wrap {
                display: none;
                padding: 10px 20px;

                ul {
                    padding-left: 30px;

                    li {
                        a {
                            display: flex;
                            padding: 6px 0;
                            color: $muted-grey;
                            font-size: .9rem;

                            &.is-active {
                                color: $accent;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ==========================================================================
1. Elements Wrap
========================================================================== */

.elements-wrap {
    width: calc(100% - 300px);
    min-height: 100vh;
    margin-left: 300px;
    padding: 56px 30px 30px 30px;

    .element-block {
        margin-top: 30px;
        width: 100%;
        padding: 30px;
        background: $white;
        border: 1px solid $fade-grey;
        border-radius: 8px;

        h3 {
            font-family: 'Montserrat', sans-serif;
            font-weight: bold;
            font-size: 1.1rem;
            color: $dark-text;
        }

        p {
            margin: 10px 0;
            font-size: .9rem;
            color: $muted-grey;
        }

        .ui-elements {
            margin-top: 20px;
        }
    }

    .code-block {
        margin-top: 30px;
        width: 100%;
        background: $sidebar;
        border: 1px solid $sidebar;
        border-radius: 8px;
    }
}

/* ==========================================================================
2. Media Queries
========================================================================== */

@media (max-width: 767px) {
    .elements-sidebar {
        display: none;
    }

    .elements-wrap {
        width: 100%;
        margin-left: 0;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .elements-sidebar {
        display: none;
    }

    .elements-wrap {
        width: 100%;
        margin-left: 0;
    }
}
