/*! all.scss | Friendkit | © Css Ninja. 2020-2021 */

/* ==========================================================================
Imports file
========================================================================== */

/*
    0. Pages imports
*/

/* ==========================================================================
0. Pages imports
========================================================================== */

@import "./chat";
@import "./feed";
@import "./posts-feed";
@import "./inbox";
@import "./profile";
@import "./profile-minimal";
@import "./friends";
@import "./groups";
@import "./events";
@import "./news";
@import "./map";
@import "./questions";
@import "./videos";
@import "./stories";
@import "./commerce";
@import "./landing";
@import "./signup";
@import "./login";
@import "./settings";
@import "./demo";