.tinderCards__cardContainer {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  height: 74vh;

  .card {
    position: relative;
    width: 350px;
    height: 60vh;
    box-shadow: 0px 4px 4px 0px rgba(30, 30, 30, 0.1);
    border-radius: 20px;
    overflow: hidden;
    border: none;

    .image-container {
      height: calc(100% - 140px);

      .card-image {
        background-size: cover;

        width: 100%;
        height: 100%;
      }
    }

    .user-profile {
      height: 100px;
      padding: 16px;
    }
  }

  .card.detail {
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }

    .image-container {
      height: calc(100% - 250px);
    }
  }

  .swipe {
    position: absolute;
  }

  .action-buttons {
    position: absolute;
    bottom: -20px;
    width: 350px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    text-align: center;

    .icon-button {
      width: 60px;
      height: 60px;
      padding: 15px;

      border-radius: 50%;
      border: 1px solid #aaa;
      margin-left: 14px;
      margin-right: 14px;

      transition: all 0.5s;

      &.ignore {
        border-color: #e84118;
        color: #e84118;

        &:hover {
          background-color: #e84118;
          color: #fff;
        }
      }

      &.like {
        border-color: #00a8ff;
        color: #00a8ff;

        &:hover {
          background-color: #00a8ff;
          color: #fff;
        }
      }
    }
  }
}
