/*! _animations.scss | Friendkit | © Css Ninja. 2020-2021 */

/* ==========================================================================
Project wide reusable animations
========================================================================== */

/*
    1. Bouncy animation
    2. Progress bar
    3. Opacity
    4. Fade In Left
    5. Wave
    6. Gelatine
    7. Modal scale
    8. Rotate
*/

/* ==========================================================================
1. Bouncy animation
========================================================================== */

@-webkit-keyframes bouncy {
    from, to {
        -webkit-transform: scale(1, 1);
    }

    25% {
        -webkit-transform: scale(0.9, 1.1);
    }

    50% {
        -webkit-transform: scale(1.1, 0.9);
    }

    75% {
        -webkit-transform: scale(0.95, 1.05);
    }
}

@keyframes bouncy {
    from, to {
        transform: scale(1, 1);
    }

    25% {
        transform: scale(0.9, 1.1);
    }

    50% {
        transform: scale(1.1, 0.9);
    }

    75% {
        transform: scale(0.95, 1.05);
    }
}

.bouncy {
    -webkit-animation: bouncy 0.6s;
    animation: bouncy 0.6s;
    -webkit-animation-duration: 0.6s;
    animation-duration: 0.6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

/* ==========================================================================
2. Progress bar
========================================================================== */

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }

    to {
        background-position: 0 0;
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }

    to {
        background-position: 0 0;
    }
}

/* ==========================================================================
3. Opacity
========================================================================== */

@-webkit-keyframes opacity-shift {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@keyframes opacity-shift {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

/* ==========================================================================
4. Fade In
========================================================================== */

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation: fadeIn 500ms ease-in-out;
    animation: fadeIn 500ms ease-in-out;
}

/* ==========================================================================
4. Fade In Left
========================================================================== */

@-webkit-keyframes fadeInLeft {
    from {
        -webkit-transform: translate3d(20px, 0, 0);
        transform: translate3d(20px, 0, 0);
        opacity: 0;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes fadeInLeft {
    from {
        -webkit-transform: translate3d(20px, 0, 0);
        transform: translate3d(20px, 0, 0);
        opacity: 0;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.preFadeInLeft {
    opacity: 0;
}

.fadeInLeft {
    opacity: 0;
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}

/* ==========================================================================
6. Fade in Up animation
========================================================================== */

@-webkit-keyframes fadeInUp {
    from {
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes fadeInUp {
    from {
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

//Settings
.preFadeInUp {
    opacity: 0;
}

.fadeInUp {
    opacity: 0;
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

/* ==========================================================================
5. Wave
========================================================================== */

@keyframes wave {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    25% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(4.5);
        opacity: 0;
    }
}

/* ==========================================================================
6. Gelatine
========================================================================== */

@-webkit-keyframes gelatine {
    from, to {
        -webkit-transform: scale(1, 1);
    }

    25% {
        -webkit-transform: scale(0.9, 1.1);
    }

    50% {
        -webkit-transform: scale(1.1, 0.9);
    }

    75% {
        -webkit-transform: scale(0.95, 1.05);
    }
}

@keyframes gelatine {
    from, to {
        transform: scale(1, 1);
    }

    25% {
        transform: scale(0.9, 1.1);
    }

    50% {
        transform: scale(1.1, 0.9);
    }

    75% {
        transform: scale(0.95, 1.05);
    }
}

.gelatine {
    -webkit-animation: gelatine 0.6s;
    animation: gelatine 0.6s;
    -webkit-animation-duration: 0.6s;
    animation-duration: 0.6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

/* ==========================================================================
7. Modal scale
========================================================================== */

@keyframes modalScale {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

/* ==========================================================================
8. Rotate
========================================================================== */

@keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
    }
}

@keyframes gradientShift {
    0% {
        background-position: 50% 0%;
    }

    50% {
        background-position: 50% 100%;
    }

    100% {
        background-position: 50% 0%;
    }
}
