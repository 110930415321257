/*! _app-signup.scss | Friendkit | © Css Ninja. 2020-2021 */

/* ==========================================================================
Signup styles
========================================================================== */

/*
    0. Signup page layout
    1. Crop Modal
    2. Media Queries
*/

/* ==========================================================================
0. Signup page layout
========================================================================== */

.fake-nav {
    height: 55px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $white;

    img {
        max-width: 48px;
    }
}

.signup-wrapper {
    min-height: 100vh;
    position: relative;

    .process-bar-wrap {
        padding: 30px 0 0 0;
    }

    .process-bar {
        height: 18px;
        background: $white;
        border-radius: 100px;
        max-width: 520px;
        margin: 0 auto;
        display: flex;
        align-items: center;

        .progress-wrap {
            position: relative;
            margin: 0 12px;
            width: calc(100% - 24px);
            height: 6px;
            border-radius: 100px;

            .track, .bar {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                border-radius: 100px;
            }

            .track {
                background: lighten($medium-grey, 5%);
                width: 100%;
            }

            .bar {
                background: $accent;
                width: 0;
                transition: width .4s;
            }

            .dot {
                position: absolute;
                top: calc(50% - 19px);
                height: 38px;
                width: 38px;
                background: $white;
                border-radius: 50%;
                border: 1.4px solid $fade-grey;
                box-shadow: $light-box-shadow;
                display: flex;
                justify-content: center;
                align-items: center;
                pointer-events: none;

                &.is-first {
                    left: -19px;
                }

                &.is-second {
                    left: calc(25% - 19px);
                }

                &.is-third {
                    left: calc(50% - 19px);
                }

                &.is-fourth {
                    left: calc(75% - 19px);
                }

                &.is-fifth {
                    right: -19px;
                }

                &.is-active {
                    border-color: $accent;
                    box-shadow: $accent-box-shadow;

                    svg {
                        stroke: $accent;
                    }
                }

                &.is-current {
                    border-color: $primary;
                    box-shadow: $primary-box-shadow;

                    svg {
                        stroke: $primary;
                    }
                }

                svg {
                    height: 16px;
                    width: 16px;
                    stroke: $placeholder;
                    transition: stroke .3s;
                }
            }
        }
    }

    .outer-panel {
        display: flex;
        align-items: center;
        min-height: calc(100vh - 133px);

        .outer-panel-inner {
            width: 100%;
        }
    }

    .process-title {
        max-width: 840px;
        margin: 0 auto;
        text-align: center;

        h2 {
            font-family: 'Montserrat', sans-serif;
            font-weight: 400 !important;
            font-size: 1.2rem;
        }

        .step-title {
            animation: fadeInUp .5s;
            display: none;

            &.is-active {
                display: block;
            }
        }
    }

    .process-panel-wrap {
        display: none;
        animation: fadeInLeft .5s;
        max-width: 1040px;
        margin: 0 auto;
        padding: 20px 0;

        &.is-active {
            display: block;
        }

        &.is-narrow {
            max-width: 540px;
        }

        .account-type {
            width: 100%;
            background: $white;
            border: 1px solid $fade-grey;
            border-radius: 8px;
            padding: 30px;
            text-align: center;

            .type-image {
                position: relative;

                .type-bg {
                    margin-top: -5%;
                }

                .type-illustration {
                    position: absolute;
                    top: -15%;
                    left: 0;
                    transform: scale(1.25);
                }
            }

            > img {
                margin: 0 auto 20px auto;
                max-height: 140px;
            }

            h3 {
                font-family: $font-main;
                font-weight: 600 !important;
                font-size: 1rem;
                padding: .5rem 0;
            }

            p {
                color: $light-text;
                font-size: .9rem;
            }

            .button {
                margin-top: 20px;
                border-width: 1.4px;
                min-height: 42px;
                color: $muted-grey;

                &:hover, &.is-loading {
                    border-color: $accent;
                    background: $accent;
                    color: $white;
                    box-shadow: $accent-box-shadow;
                }
            }
        }

        .form-panel {
            width: 100%;
            background: $white;
            border: 1px solid $fade-grey;
            border-radius: 8px;
            padding: 30px;

            .field {
                border: 1px solid $fade-grey;
                padding: 4px 8px 8px 8px;
                border-radius: 6px;
                background: lighten($fade-grey, 8%);
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }

                label {
                    text-transform: uppercase;
                    font-size: .7rem;
                    font-weight: 500;
                    color: $medium-text;
                    padding-left: 8px;
                }

                .control {
                    input {
                        border: none;
                        height: 30px;
                        background: lighten($fade-grey, 8%);
                    }
                }
            }

            .success-image {
                display: block;
                max-width: 120px;
                margin: 0 auto;
            }

            .success-text {
                text-align: center;
                margin: 12px auto;
                max-width: 370px;

                h3 {
                    font-weight: 500;
                    color: $dark-text;
                }

                p {
                    font-size: .9rem;
                    color: $muted-grey;
                }

                .button {
                    margin: 20px auto 0 auto;
                    max-width: 280px;
                    border: 1.4px solid $accent;
                    color: $accent;

                    &:hover, &.is-loading {
                        border-color: $accent;
                        background: $accent;
                        color: $white;
                        box-shadow: $accent-box-shadow;
                    }
                }
            }
        }

        .photo-upload {
            .preview {
                position: relative;
                height: 120px;
                width: 120px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                border: 1.4px solid $placeholder;
                margin: 0 auto;

                img {
                    display: block;
                    height: 100px;
                    width: 100px;
                    border-radius: 50%;
                }

                .upload-button {
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 36px;
                    width: 36px;
                    border-radius: 50%;
                    border: 3px solid $white;
                    background: $placeholder;
                    transition: all .3s;

                    &:hover {
                        background: $primary;
                    }

                    svg {
                        height: 14px;
                        width: 14px;
                        stroke: $white;
                        stroke-width: 3px;
                    }
                }
            }

            .limitation {
                margin-top: 20px;
                text-align: center;

                small {
                    color: $muted-grey;
                }
            }
        }

        .buttons {
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .button {
                min-width: 90px;

                &.is-next:hover {
                    border-color: $accent;
                    background: $accent;
                    color: $white-smoke;
                    box-shadow: $accent-box-shadow;
                }
            }
        }
    }
}

/* ==========================================================================
1. Crop Modal
========================================================================== */

.crop-modal {
    .modal-card {
        max-width: 100%;
    }

    .modal-card-head {

        h3 {
            font-family: 'Montserrat', sans-serif;
            font-size: 1rem;
            font-weight: 600;
        }

        .close-wrap {
            height: 30px;
            width: 30px;
            margin-left: auto;

            .close-modal {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 30px;
                width: 30px;
                border-radius: 50%;
                border: none;
                background: transparent;
                cursor: pointer;
                transition: background .3s;

                &:hover {
                    background: lighten($placeholder, 10%);
                }

                svg {
                    height: 18px;
                    width: 18px;
                    stroke: $medium-text;
                }
            }
        }
    }

    .modal-card-body {
        position: relative;
        min-height: 330px;
        overflow: hidden;

        .cropper-wrapper {
            margin: 0 auto;

            .button {
                min-width: 70px;
                height: 34px;
                border-radius: 100px;
                outline: none !important;
                background: $primary;
                border-color: $primary;
                color: $white;
            }
        }
    }
}

/* ==========================================================================
12. Media Queries
========================================================================== */

@media (max-width: 767px) {
    .signup-wrapper {
        .outer-panel {
            .outer-panel-inner {
                max-width: 330px;
                margin: 0 auto;

                .account-type {
                    position: relative;
                    text-align: left;

                    img {
                        display: none;
                    }

                    .button {
                        position: absolute;
                        top: 0;
                        left: 0;
                        margin: 0;
                        height: 100%;
                        width: 100%;
                        opacity: 0;
                    }
                }
            }
        }

        .process-bar-wrap {
            max-width: 320px;
            margin: 0 auto;
        }
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .signup-wrapper {
        .outer-panel {
            .outer-panel-inner {
                max-width: 740px;
                margin: 0 auto;

                .columns {
                    display: flex;

                    .account-type {
                        padding: 20px;

                        img {
                            height: 110px;
                        }
                    }
                }
            }
        }
    }
}
