/*! _app-events.scss | Friendkit | © Css Ninja. 2020-2021 */

/* ==========================================================================
Events Page
========================================================================== */

/*
    1. Events page layout
    2. Event page layout
    3. Media Queries
*/

/* ==========================================================================
1. Events page layout
========================================================================== */

.events-wrapper {
    position: absolute;
    top: 58px;
    left: 0;
    width: 100%;
    height: calc(100vh - 58px);
    background: $white;

    &.is-pushed {
        top: 150px;
        height: calc(100vh - 150px);

        .left-panel, .right-panel {
            top: 150px;
            height: calc(100vh - 150px);
        }
    }

    .left-panel {
        position: fixed;
        top: 58px;
        left: 0;
        width: 260px;
        height: calc(100vh - 58px);
        background: $white;
        border-right: 1px solid darken($fade-grey, 3%);

        .left-panel-inner {
            position: relative;
            height: 100%;
            width: 100%;
            padding-bottom: 60px;

            a {
                display: flex;
                align-items: center;
                padding: 20px;
                border-bottom: 1px solid darken($fade-grey, 4%);
                transition: all .3s;

                &.is-active {
                    background: lighten($fade-grey, 4%);

                    span {
                        &.tag {
                            background: $accent !important;
                            color: $white !important;
                        }

                        &.date-block svg {
                            stroke: $accent;
                        }
                    }
                }

                span {
                    display: block;

                    &.date-block {
                        display: flex;
                        align-items: center;

                        svg {
                            position: relative;
                            top: -1px;
                            height: 18px;
                            width: 18px;
                            margin-right: 6px;
                            stroke: $placeholder;
                            transition: stroke .3s;
                        }

                        .month {
                            font-family: Montserrat, sans-serif;
                            font-weight: 500;
                            color: $dark-text;
                            font-size: .85rem;
                            line-height: 1;
                            width: 75px;
                        }
                    }

                    &.meta-block {
                        margin-left: auto;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .time {
                            font-size: .8rem;
                            color: $muted-grey;
                            line-height: 1;
                        }

                        .type {
                            display: inline-block;

                            .tag {
                                display: inline-block;
                                border-radius: 100px;
                                line-height: 2;
                                transition: all .3s;
                                background: lighten($fade-grey, 4%);
                                color: $dark-text;
                            }
                        }
                    }
                }
            }

            .add-event {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 60px;
                display: flex;
                align-items: center;
                padding: 0 20px;
                background: $white;
                transition: opacity .3s;

                .button {
                    height: 42px;
                    width: 100%;
                    border: 2px dashed $placeholder;
                    color: $muted-grey;

                    &:hover {
                        border: 2px solid $primary;
                        background: $primary;
                        color: $white-light;
                        box-shadow: $primary-box-shadow;
                    }
                }
            }
        }
    }

    .right-panel {
        position: fixed;
        top: 58px;
        right: 0;
        width: 380px;
        height: calc(100vh - 58px);
        background: $white;
        border-left: 1px solid darken($fade-grey, 3%);

        .panel-header {
            display: flex;
            align-items: center;
            height: 60px;
            width: 100%;
            padding: 0 30px;

            h3 {
                font-size: 1rem;
                font-family: Montserrat, sans-serif;
                font-weight: 600;
            }
        }

        .panel-body {
            position: relative;
            height: calc(100% - 60px);
            overflow-y: auto;
            padding: 0 20px 20px 20px;

            .activity-block {
                display: flex;
                align-items: flex-start;
                padding: 10px 0;

                img {
                    display: block;
                    height: 32px;
                    width: 32px;
                    min-width: 32px;
                    border-radius: 50%;
                }

                .activity-meta {
                    margin-left: 10px;

                    p {
                        font-size: .85rem;
                        color: $dark-text;

                        a {
                            font-weight: 500;
                            color: $dark-text;
                            transition: color .3s;

                            &:hover {
                                color: $accent;
                            }
                        }
                    }

                    span {
                        display: block;
                        font-size: .85rem;
                        color: $muted-grey;
                    }
                }
            }
        }
    }

    .wrapper-inner {
        position: relative;
        width: calc(100% - 640px);
        height: 100%;
        margin-left: 260px;
        margin-right: 380px;

        .event-list {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            transition: all .3s;

            .event-item {
                position: relative;
                width: 100%;
                padding: 40px 60px;
                background: $white;
                border-bottom: 1px solid $fade-grey;
                display: flex;

                .event-inner-wrap {
                    position: relative;

                    .event-options {
                        position: absolute;
                        top: 5px;
                        right: 0;

                        &:hover {
                            .button svg {
                                animation: rotation 2s infinite linear;
                            }
                        }

                        .button {
                            background: transparent;
                            border: none;
                            padding: 0;
                            height: 28px;
                            width: 28px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            svg {
                                color: $medium-text;
                            }
                        }
                    }
                }

                .event-title {
                    font-size: 2rem;
                    font-family: Montserrat, sans-serif;
                    font-weight: 800;
                    color: $dark-text;
                    line-height: 1.1;
                    max-width: 520px;
                }

                .event-subtitle {
                    display: flex;
                    margin: 40px 0 10px 0;

                    svg {
                        height: 20px;
                        width: 20px;
                        margin-right: 6px;
                    }

                    h3 {
                        color: $muted-grey;
                    }
                }

                .event-content {
                    display: flex;
                    align-items: flex-start;

                    .event-owner {
                        display: flex;
                        align-items: center;
                        min-width: 80px;

                        img {
                            display: block;
                            height: 44px;
                            width: 44px;
                            min-width: 44px;
                            border-radius: 50%;
                            margin: 0 auto;
                        }

                        .owner-meta {
                            margin-left: 12px;

                            span {
                                display: block;

                                &:first-child {
                                    font-family: Montserrat, sans-serif;
                                    font-weight: 600;
                                    font-size: .9rem;
                                    color: $dark-text;
                                }

                                &:nth-child(2) {
                                    font-size: .9rem;
                                    color: $muted-grey;
                                }
                            }
                        }
                    }

                    .event-description {
                        p {
                            color: $medium-text;
                            margin-bottom: 10px;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }

                .event-participants {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;

                    .participants-group {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        img {
                            height: 38px;
                            width: 38px;
                            border-radius: 50%;
                            border: 3px solid $white;

                            &:not(:first-child) {
                                margin-left: -12px;
                            }
                        }
                    }

                    .participants-text {
                        margin-left: 10px;

                        p {
                            font-size: .8rem;
                            color: $grey-text;

                            a {
                                color: $dark-text;

                                &:hover {
                                    color: $accent;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ==========================================================================
1. Events page layout
========================================================================== */

.event-page-wrapper {
    padding-bottom: 60px;
    
    .event-cover {
        img {
            display: block;
            max-height: 380px;
            width: 100%;
            object-fit: cover;
            object-position: bottom;
        }
    }

    .event-content {
        position: relative;
        max-width: 940px;
        margin: -80px auto 20px auto;
        z-index: 1;

        .event-head {
            display: flex;
            align-items: stretch;
            border-radius: 8px;
            background: $white;
            border: 1px solid darken($fade-grey, 3%);
            box-shadow: $light-box-shadow;

            .left {
                position: relative;
                width: 60%;
                padding: 30px;

                .button-separator {
                    position: absolute;
                    top: 50%;
                    right: -25px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 50px;
                    width: 50px;
                    border-radius: 50%;
                    background: $white;
                    transform: translateY(-50%);
                    border: 1px solid darken($fade-grey, 3%);
                    box-shadow: $light-box-shadow;

                    svg {
                        height: 18px;
                        width: 18px;
                        stroke-width: 3px;
                        stroke: $light-text;
                    }
                }

                h2 {
                    font-family: "Montserrat", sans-serif;
                    font-size: 1.7rem;
                    font-weight: 700;
                    color: $dark-text;
                }

                h3 {
                    font-family: 'Roboto', sans-serif;
                    color: $light-text;
                    margin-bottom: 1rem;
                }

                .info-block {
                    margin-bottom: 1rem;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .info-head {
                        display: flex;
                        align-items: center;

                        .event-icon {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 16px;
                            width: 16px;
                            min-width: 16px;
                            border-radius: 50%;

                            svg {
                                height: 16px;
                                width: 16px;
                                stroke-width: 1.6px;
                                stroke: $accent;
                            }
                        }

                        > span {
                            display: inline-block;
                            color: $light-text;
                            font-size: .9rem;
                            margin-left: 6px;
                        }
                    }

                    .info-body {
                        p, a {
                            font-family: 'Roboto', sans-serif;
                            font-size: .9rem;
                            color: $dark-text;
                        }

                        .socials {
                            margin-top: 8px;

                            a {
                                margin-right: 6px;
                                color: $light-text;

                                &:hover {
                                    color: $accent;
                                }

                                svg {
                                    stroke-width: 1.6px;
                                    height: 20px;
                                    width: 20px;
                                }
                            }
                        }
                    }
                }
            }

            .right {
                width: 40%;
                border-left: 2px dashed darken($fade-grey, 4%);
                padding: 30px 40px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                background: lighten($fade-grey, 7%);

                h2 {
                    font-family: "Montserrat", sans-serif;
                    font-size: 1.4rem;
                    font-weight: 700;
                    color: $dark-text;
                    margin-bottom: 1rem;
                    margin-top: 6px;
                }

                .subscribe-block {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    p {
                        font-family: 'Roboto', sans-serif;
                        font-size: .8rem;
                        color: $dark-text;
                    }

                    img {
                        display: block;
                        max-width: 110px;
                    }
                }

                .condition {
                    padding: 20px 0 0 0;
                    text-transform: uppercase;
                    font-family: 'Roboto', sans-serif;
                    font-size: .7rem;
                    font-weight: 500;
                    color: $dark-text;
                }
            }
        }
    }

    .event-participants {
        padding: 20px 0;
        max-width: 940px;
        margin: 0 auto;

        .container-inner {
            display: flex;

            .participant {
                margin: 8px;
                width: calc(10% - 16px);
                display: flex;
                justify-content: center;

                .participant-avatar {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 60px;
                    width: 60px;
                    min-width: 60px;
                    background: $white;
                    border-radius: 50%;
                    border: 1.6px solid $placeholder;
                    opacity: .6;
                    filter: grayscale(1);
                    transition: all .3s;

                    &:hover {
                        border-color: $primary;
                        opacity: 1;
                        filter: grayscale(0);
                    }

                    .more-overlay {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        height: 50px;
                        width: 50px;
                        transform: translate(-50%, -50%);
                        border-radius: 50%;
                        background: rgba($accent, 1);
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        span {
                            font-family: 'Roboto', sans-serif;
                            color: $white;
                        }
                    }

                    img {
                        display: block;
                        height: 50px;
                        width: 50px;
                        border-radius: 50%;
                    }
                }
            }
        }
    }

    .event-details {
        display: flex;
        align-items: stretch;

        .left {
            width: 60%;
            padding: 0 30px 30px 30px;
            border-right: 1px solid darken($fade-grey, 4%);

            .details-block {
                margin-bottom: 40px;

                &:last-child {
                    margin-bottom: 0;
                }

                h3 {
                    font-family: "Montserrat", sans-serif;
                    font-size: 1.4rem;
                    font-weight: 700;
                    color: $dark-text;
                    margin-bottom: 1rem;
                    margin-top: 10px;
                }

                p {
                    font-family: 'Roboto', sans-serif;
                    font-size: .9rem;
                    color: $dark-text;
                    margin-bottom: 10px;
                }

                .video-block-wrapper {
                    margin: 0 6px 6px 6px;
                }

                .photo-group {
                    display: flex;
                    flex-wrap: wrap;

                    a {
                        display: block;
                        margin: 6px;
                        width: calc(33.3% - 12px);

                        img {
                            border-radius: 8px;
                            height: 110px;
                            object-fit: cover;
                        }
                    }
                }
            }
        }

        .right {
            width: 40%;
            padding: 0 30px;

            .event-owner {
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                img {
                    height: 60px;
                    width: 60px;
                    border-radius: 50%;
                }

                .meta {
                    margin-left: 12px;

                    span {
                        display: block;
                        font-family: 'Roboto', sans-serif;

                        &:first-child {
                            font-size: .85rem;
                            color: $light-text;
                        }

                        &:nth-child(2) {
                            font-size: 1.1rem;
                            font-weight: 500;
                            color: $dark-text;
                        }
                    }
                }
            }

            .side-block {
                margin-bottom: 1rem;

                &:last-child {
                    margin-bottom: 0;
                }

                .side-head {
                    display: flex;
                    align-items: center;

                    .event-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 16px;
                        width: 16px;
                        min-width: 16px;
                        border-radius: 50%;
                        margin-right: 6px;

                        svg {
                            height: 16px;
                            width: 16px;
                            stroke-width: 1.6px;
                            stroke: $accent;
                        }
                    }

                    > span {
                        display: inline-block;
                        color: $light-text;
                        font-size: .9rem;
                    }
                }

                .side-body {
                    a {
                        font-family: 'Roboto', sans-serif;
                        font-size: .9rem;
                        color: $primary;
                    }
                }
            }
        }
    }
}

/* ==========================================================================
3. Media Queries
========================================================================== */

@media only screen and (max-width: 1300px) {
    .events-wrapper {
        .right-panel {
            width: 310px !important;
        }

        .wrapper-inner {
            width: calc(100% - 570px) !important;
            margin-right: 310px !important;

            .event-list {
                .event-item {
                    padding: 40px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .events-wrapper {
        .right-panel {
            display: none !important;
        }

        .wrapper-inner {
            width: calc(100% - 260px) !important;
            margin-right: 0 !important;

            .event-list {
                .event-item {
                    padding: 40px 60px;
                }
            }
        }
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .events-wrapper {
        .right-panel, .left-panel {
            display: none !important;
        }

        .wrapper-inner {
            width: calc(100%) !important;
            margin-right: 0 !important;
            margin-left: 0 !important;

            .event-list {
                .event-item {
                    padding: 40px 60px;
                    height: auto;

                    .event-title {
                        font-size: 2rem !important;
                    }
                }
            }
        }
    }

    .event-page-wrapper {
        .event-content {
            .event-head {
                margin: 0 16px;

                .left {
                    width: 50%;

                    h2 {
                        line-height: 1.2;
                        margin-bottom: 6px;
                    }
                }

                .right {
                    width: 50%;
                }
            }

            .event-participants {
                max-width: 740px;
                padding: 30px 0;
            }
        }
    }
}

@media (max-width: 767px) {
    .events-wrapper {
        .right-panel, .left-panel {
            display: none !important;
        }

        .wrapper-inner {
            width: calc(100%) !important;
            margin-right: 0 !important;
            margin-left: 0 !important;

            .event-list {
                .event-item {
                    padding: 40px 30px;
                    height: auto;

                    .event-title {
                        font-size: 1.6rem !important;
                        max-width: 280px;
                    }

                    .event-inner-wrap {
                        .event-content {
                            .event-owner {
                                display: none;
                            }
                        }

                        .event-options {
                            top: 0px;
                            right: 0px;
                        }

                        .event-participants {
                            margin-left: 0 !important;
                        }
                    }
                }
            }
        }
    }

    .event-page-wrapper {
        
        .event-content {
            padding: 0 10px;

            .event-head {
                flex-direction: column;
                padding: 0;

                .left {
                    width: 100%;
                    padding-bottom: 50px;

                    h2 {
                        line-height: 1.2;
                        margin-bottom: 6px;
                    }

                    .button-separator {
                        top: unset;
                        bottom: -25px;
                        transform: translate(0, 0) rotate(90deg);
                        right: 0;
                        left: 0;
                        margin: 0 auto;
                    }
                }

                .right {
                    padding-top: 40px;
                    padding-left: 30px;
                    padding-right: 30px;
                    width: 100%;
                    border-top: 2px dashed darken($fade-grey, 4%);
                    border-left: none;
                }
            }

            .event-participants {
                max-width: 100%;
                width: 100%;
                margin: 0;
        
                .container-inner {
                    overflow-x: auto;
        
                    .participant {
                        min-width: 60px;
                    }
                }
            }

            .event-details {
                flex-direction: column-reverse;

                .left {
                    width: 100%;
                    padding: 20px 10px 10px 10px;
                    border: none;
                }

                .right {
                    width: 100%;
                    padding: 10px 10px 30px 10px;
                    border-right: none;
                    border-bottom: 1px solid darken($fade-grey, 4%);
                }
            }
        }
    }
}
