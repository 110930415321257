/*! _components-tabs.scss | Friendkit | © Css Ninja. 2020-2021 */

/* ==========================================================================
Tabs styles
========================================================================== */

/*
    1. Main styles
*/

/* ==========================================================================
1. Main styles
========================================================================== */

.nav-tabs-wrapper {
    .tabs {
        ul {
            &.is-faded {
                border-bottom-color: transparent !important;
            }
        }

        ul li {
            a {
                color: $placeholder;
                border-image-width: 2px;
            }

            &:hover a {
                border-bottom-color: inherit;
            }

            &.is-active a {
                color: $accent;
                border-bottom-color: $accent;
            }
        }
    }

    .tab-content {
        display: none;
        overflow: hidden;
        padding: 0 10px 10px 10px;
        animation: fadeInLeft .5s;

        &.is-active {
            display: block;
        }
    }
}
