//Main variables
@import "abstracts/variables";

//Bulma source import
@import "../sass/bulma.sass";

//Layout imports
@import "layout/all";

//Navigation imports
@import "navigation/all";

//Components imports
@import "components/all";

//Pages imports
@import "pages/all";

body::-webkit-scrollbar {
  display: none;
}

.cursor-pointer {
  cursor: pointer !important;
}

.border-none {
  border: none !important;
}

#admin-tabs.tabs {
  margin-top: 20px;
  li a.is-active {
    color: $tabs-link-active-color;
    border-bottom-color: $tabs-link-active-border-bottom-color;
  }
}

.settings-form-wrapper {
  justify-content: center;
}

form .help {
  margin-left: 6px;
  margin-bottom: 6px;
}

.circle-avatar {
  border-radius: 50%;
  width: 120px;
  height: 120px;
}

.upload-image-modal {
  video {
    object-fit: cover;
    height: 66vh;
  }
}

.user-preview-modal {
  .modal-content {
    width: 380px !important;
    .wrapper {
      background: transparent;
      border: none;
    }
  }

  .close-modal {
    background: #e8e8e8 !important;
  }

  .user-card {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 350px;
    height: 60vh;
    box-shadow: 0px 4px 4px 0px rgba(30, 30, 30, 0.1);
    border-radius: 20px;
    overflow: hidden;
    border: none;

    .image-container {
      height: calc(100% - 140px);

      .card-image {
        background-size: cover;

        width: 100%;
        height: 100%;
      }
    }

    .user-profile {
      height: 100px;
      padding: 16px;
    }
  }

  .user-card.detail {
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }

    .image-container {
      height: calc(100% - 250px);
    }
  }
}
