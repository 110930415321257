/*! _components-tooltips.scss | Friendkit | © Css Ninja. 2020-2021 */

/* ==========================================================================
Tooltip styles
========================================================================== */

/*
    1. Main styles
*/

/* ==========================================================================
1. Main styles
========================================================================== */

.ggtooltip {
    position: absolute;
    z-index: 1030;
    display: block;
    padding: 5px;
    font-size: 11px;
    opacity: 0;
    filter: alpha(opacity = 0);
    visibility: visible;
}

.ggtooltip.fade {
    opacity: 0;
    -webkit-transition: opacity 0.6s linear;
    -moz-transition: opacity 0.6s linear;
    -o-transition: opacity 0.6s linear;
    transition: opacity 0.6s linear;
}

.ggtooltip.fade.in {
    opacity: 1;
}

.ggtooltip.in {
    opacity: 0.8;
    filter: alpha(opacity = 80);
}

.ggtooltip.top {
    margin-top: -3px;
}

.ggtooltip.right {
    margin-left: 3px;
}

.ggtooltip.bottom {
    margin-top: 3px;
}

.ggtooltip.left {
    margin-left: -3px;
}

.ggtooltip .ggtooltip-inner {
    max-width: 200px;
    padding: 3px 8px;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    background-color: #000000;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: solid 1px #000000;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.ggtooltip .ggtooltip-arrow, .ggtooltip .ggtooltip-arrow-shadow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

.ggtooltip.top .ggtooltip-arrow, .ggtooltip.top .ggtooltip-arrow-shadow {
    bottom: 1px;
    left: 50%;
    margin-left: -5px;
    border-top-color: #000000;
    border-width: 5px 5px 0;
}

.ggtooltip.top .ggtooltip-arrow-shadow {
    bottom: 0;
}

.ggtooltip.right .ggtooltip-arrow, .ggtooltip.right .ggtooltip-arrow-shadow {
    top: 50%;
    left: 1px;
    margin-top: -5px;
    border-right-color: #000000;
    border-width: 5px 5px 5px 0;
}

.ggtooltip.right .ggtooltip-arrow-shadow {
    left: 0;
}

.ggtooltip.left .ggtooltip-arrow, .ggtooltip.left .ggtooltip-arrow-shadow {
    top: 50%;
    right: 1px;
    margin-top: -5px;
    border-left-color: #000000;
    border-width: 5px 0 5px 5px;
}

.ggtooltip.left .ggtooltip-arrow-shadow {
    right: 0;
}

.ggtooltip.bottom .ggtooltip-arrow, .ggtooltip.bottom .ggtooltip-arrow-shadow {
    top: 1px;
    left: 50%;
    margin-left: -5px;
    border-bottom-color: #000000;
    border-width: 0 5px 5px;
}

.ggtooltip.bottom .ggtooltip-arrow-shadow {
    top: 0;
}
