/*! _app-videos.scss | Friendkit | © Css Ninja. 2020-2021 */

/* ==========================================================================
Videos styles
========================================================================== */

/*
    0. Videos Sidebar (Desktop)
    1. Videos Home Layout
    2. Player Layout
    3. Media Queries
*/

/* ==========================================================================
0. Videos Sidebar (Desktop)
========================================================================== */

.videos-wrapper {
    .videos-sidebar {
        position: fixed;
        top: 57px;
        left: 0;
        height: calc(100% - 56px);
        background: lighten($fade-grey, 7%);
        border-right: 1px solid $fade-grey;
        transform: translateX(-100%);
        z-index: 10;
        transition: all .3s;

        &.is-active {
            transform: translateX(0);
        }

        .videos-sidebar-inner {
            position: relative;
            height: 100%;
            width: 100%;

            .user-block {
                position: relative;
                margin: 20px 20px 0 20px;
                border-bottom: 1px solid darken($fade-grey, 4%);
                text-align: center;
                height: 200px;

                .close-videos-sidebar {
                    position: absolute;
                    top: -12px;
                    right: -10px;
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    display: none;
                    align-items: center;
                    justify-content: center;
                    transition: all .3s;

                    &:hover {
                        background: $white;

                        svg {
                            stroke: $dark-text;
                        }
                    }

                    svg {
                        height: 20px;
                        width: 20px;
                        color: $muted-grey;
                        transition: stroke .3s;
                    }
                }

                .avatar-wrap {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 74px;
                    height: 74px;
                    min-width: 74px;
                    border: 1.4px solid lighten($placeholder, 4%);
                    border-radius: 50%;
                    margin: 0 auto 6px auto;

                    img {
                        display: block;
                        width: 58px;
                        height: 58px;
                        min-width: 58px;
                        border-radius: 50%;
                    }

                    .badge {
                        position: absolute;
                        bottom: 2px;
                        right: -3px;
                        height: 24px;
                        width: 24px;
                        border-radius: 50%;
                        border: 2px solid $white-smoke;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: $green;

                        svg {
                            height: 10px;
                            width: 10px;
                            stroke: $white-smoke;
                            stroke-width: 4px;
                        }
                    }
                }

                h4 {
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 700;
                    font-size: .9rem;
                    color: $dark-text;
                }

                p {
                    font-size: .8rem;
                    color: $muted-grey;
                }

                .user-stats {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    padding: 20px 0;

                    .stat-block {
                        text-align: center;

                        span {
                            display: block;

                            &:first-child {
                                text-transform: uppercase;
                                font-weight: 600;
                                font-size: .65rem;
                                color: $dark-text;
                            }

                            &:last-child {
                                font-size: .9rem;
                                color: $muted-grey;
                            }
                        }
                    }
                }
            }

            .user-menu {
                position: absolute;
                top: 200px;
                left: 0;
                width: 100%;
                height: calc(100% - 230px);

                .user-menu-inner {
                    position: relative;
                    height: 100%;
                    max-height: 100%;
                    width: 100%;
                    overflow-y: auto;

                    .separator {
                        margin: 0 30px;
                        border-bottom: 1px solid darken($fade-grey, 4%);
                    }

                    .menu-block {
                        padding: 20px 0;

                        ul {
                            li {
                                display: flex;
                                align-items: center;
                                padding: 14px 40px;
                                border-left: 4px solid transparent;

                                &.is-active {
                                    border-color: $accent;
                                }

                                a {
                                    display: flex;
                                    align-items: center;

                                    span {
                                        display: block;
                                        color: $dark-text;
                                        font-family: 'Montserrat', sans-serif;
                                        font-size: .8rem;
                                        font-weight: 600;
                                    }

                                    svg {
                                        height: 18px;
                                        width: 18px;
                                        stroke: $placeholder;
                                        margin-right: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ==========================================================================
1. Videos Home Layout
========================================================================== */

.videos-wrapper {
    &.is-home {
        padding-left: 0;
        padding-right: 0;

        .home-wrapper {
            position: relative;
            top: -2px;

            .mobile-sidebar-trigger {
                position: absolute;
                z-index: 9;
                top: 10px;
                left: 30px;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                display: none;
                align-items: center;
                justify-content: center;
                transition: all .3s;

                &.is-home-v2 {
                    position: absolute;
                    top: 23px;
                    left: 34px;
                    z-index: 5;

                    svg {
                        stroke: $muted-grey;
                    }
                }

                &:hover {
                    svg {
                        stroke: $white;
                    }
                }

                svg {
                    height: 20px;
                    width: 20px;
                    color: $white-smoke;
                    transition: stroke .3s;
                }
            }

            .video-header-wrap {
                margin-bottom: 0;

                .slick-dots {
                    bottom: 20px !important;

                    li {
                        margin: 0;

                        button {
                            &:before {
                                color: $white-smoke;
                            }
                        }
                    }
                }
            }

            .video-header {
                position: relative;
                width: 100%;
                padding-top: 56.25%;

                &:hover {
                    .cover-overlay {
                        background: rgba($dark-text, 0.6);
                    }
                }

                .cover {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    display: block;
                    object-fit: cover;
                    z-index: 0;
                }

                .cover-overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    background: rgba($dark-text, 0.4);
                    transition: all .3s;
                    z-index: 1;
                }

                .cover-caption {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    z-index: 2;

                    .caption-inner {
                        position: relative;
                        height: 100%;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        padding-left: 30px;

                        .video-caption {
                            width: 100%;
                            display: flex;
                            justify-content: flex-end;

                            .caption-block {
                                width: 50%;

                                h2 {
                                    font-family: 'Montserrat', sans-serif;
                                    font-size: 2.4rem;
                                    font-weight: 700;
                                    color: $white-smoke;
                                }

                                .video-meta {
                                    display: flex;

                                    span {
                                        display: block;
                                        color: $white-smoke;
                                        padding-right: 30px;
                                        font-family: 'Montserrat', sans-serif;
                                        font-size: .85rem;
                                        font-weight: 500;

                                        &.rating {
                                            display: flex;
                                            align-items: center;

                                            svg {
                                                height: 14px;
                                                width: 14px;
                                                stroke: $white-smoke;
                                                fill: $white-smoke;
                                                margin: 0 2px;
                                            }
                                        }
                                    }
                                }

                                .description {
                                    font-family: 'Roboto', sans-serif;
                                    font-size: .85rem;
                                    color: $white-smoke;
                                    padding: 12px 0;
                                    max-width: 400px;
                                }

                                .caption-actions {
                                    display: flex;

                                    //justify-content: space-between;
                                    align-items: center;
                                    padding: 10px 0;
                                    max-width: 400px;

                                    .button {
                                        min-width: 130px;
                                        margin-right: 30px;

                                        &:hover {
                                            background: $accent;
                                            border-color: $accent;
                                            box-shadow: $accent-box-shadow;
                                        }
                                    }

                                    .trailer-button {
                                        display: flex;
                                        align-items: center;

                                        &:hover {
                                            svg {
                                                opacity: 1;
                                                stroke: lighten($accent, 28%);
                                            }

                                            span {
                                                margin-left: 5px;
                                                color: lighten($accent, 28%);
                                            }
                                        }

                                        svg {
                                            opacity: 0;
                                            stroke: $white-smoke;
                                            height: 16px;
                                            width: 16px;
                                            transition: all .3s;
                                        }

                                        span {
                                            color: $white-smoke;
                                            font-size: .85rem;
                                            transition: all 0.3s;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .home-content {
        padding: 30px 40px;

        .collections-header {
            display: flex;

            > a {
                margin-right: 30px;
                font-family: 'Montserrat', sans-serif;
                font-size: .85rem;
                font-weight: 600;
                color: $placeholder;
                padding-bottom: 5px;
                border-bottom: 2px solid transparent;
                transition: all .3s;

                &.is-active {
                    color: $dark-text;
                    border-color: $accent;
                }
            }

            .search-button {
                height: 36px;
                width: 36px;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                cursor: pointer;

                &:hover {
                    svg {
                        stroke: $dark-text;
                    }
                }

                svg {
                    position: relative;
                    top: 2px;
                    height: 16px;
                    width: 16px;
                    stroke: $placeholder;
                    transition: stroke .3s;
                }
            }
        }

        .collections-wrap {
            display: none;
            animation: fadeInLeft .5s;
            padding: 30px 0;

            &.is-active {
                display: block;
            }

            .collection {
                .header {
                    display: flex;
                    align-items: center;

                    h4 {
                        font-family: 'Montserrat', sans-serif;
                        font-size: 1rem;
                        font-weight: 700;
                        color: $dark-text;
                    }

                    a {
                        padding: 0 24px;
                        color: $muted-grey;
                        font-size: .8rem;
                    }
                }

                .video-collection {
                    display: flex;
                    flex-wrap: wrap;
                    padding: 10px 0 20px 0;
                    margin-left: -10px;
                    margin-right: -10px;

                    //margin-bottom: 20px;

                    .episode {
                        margin: 10px;
                        width: calc(25% - 20px);

                        .episode-thumbnail {
                            position: relative;

                            &:hover {
                                .episode-overlay {
                                    background: rgba($dark-text, 0.4);
                                }

                                .play-button {
                                    opacity: 1;
                                    transform: scale(1) rotate(0);
                                }
                            }

                            .episode-overlay {
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 100%;
                                width: 100%;
                                border-radius: 6px;
                                background: rgba($dark-text, 0);
                                transition: all .3s;
                                z-index: 1;
                            }

                            .episode-duration {
                                position: absolute;
                                bottom: 10px;
                                left: 10px;
                                padding: 3px 6px;
                                background: black;
                                border-radius: 4px;
                                color: $white-smoke;
                                font-size: .7rem;
                                z-index: 2;
                            }

                            .play-button {
                                position: absolute;
                                top: calc(50% - 20px);
                                left: calc(50% - 20px);
                                height: 40px;
                                width: 40px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                z-index: 3;
                                opacity: 0;
                                transform: scale(0.7) rotate(-180deg);
                                transition: all .3s;

                                svg {
                                    height: 38px;
                                    width: 38px;
                                    stroke-width: 1px;
                                    stroke: $white-smoke;
                                }
                            }

                            img {
                                display: block;
                                border-radius: 6px;
                            }
                        }

                        .episode-meta {
                            display: flex;

                            //align-items: center;
                            margin-top: 8px;

                            img {
                                display: block;
                                height: 32px;
                                width: 32px;
                                min-width: 32px;
                                border-radius: 50%;
                            }

                            .info {
                                margin-left: 8px;

                                span {
                                    display: block;
                                    line-height: 1.2;

                                    &:first-child {
                                        font-family: 'Montserrat', sans-serif;
                                        font-weight: 600;
                                        font-size: .85rem;
                                        color: $dark-text;
                                    }

                                    &:last-child {
                                        font-family: 'Roboto', sans-serif;
                                        font-size: .8rem;
                                        color: $muted-grey;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ==========================================================================
2. Player Layout
========================================================================== */

.videos-wrapper {
    &.has-player {

        .player-wrapper {
            position: relative;
            top: -2px;
            display: flex;

            .video-side {
                flex-grow: 2;
            }
        }

        .meta-box {
            padding: 30px;
            background: $white;

            .video-title {
                font-family: 'Montserrat', sans-serif;
                font-size: 1.2rem;
                font-weight: 600;
                color: $dark-text;
            }

            .video-subtitle {
                display: flex;
                justify-content: space-between;
                padding: 6px 0;

                .views-count {
                    color: $muted-grey;
                }

                .quick-actions {
                    display: flex;
                    align-items: center;

                    .quick-action {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 36px;
                        width: 36px;
                        margin: 0 3px;
                        border-radius: 50%;
                        transition: all .3s;

                        &:hover {
                            background: lighten($fade-grey, 4%);

                            svg {
                                stroke: $dark-text;
                            }
                        }

                        &.is-mobile {
                            display: none;
                        }

                        svg {
                            height: 20px;
                            width: 20px;
                            stroke: $placeholder;
                            transition: stroke .3s;
                        }
                    }
                }
            }

            .video-owner {
                display: flex;
                align-items: center;

                img {
                    display: block;
                    height: 44px;
                    width: 44px;
                    min-width: 44px;
                    border-radius: 50%;
                }

                .meta {
                    margin-left: 12px;

                    span {
                        display: block;

                        &:first-child {
                            font-family: 'Montserrat', sans-serif;
                            font-size: .9rem;
                            font-weight: 600;
                            color: $dark-text;
                        }

                        &:nth-child(2) {
                            color: $muted-grey;
                            font-size: .8rem;
                        }
                    }
                }

                .button {
                    margin-left: auto;
                    background: $accent;
                    border-color: $accent;
                    color: $white;
                    border-radius: 100px;
                    min-width: 130px;

                    &:hover {
                        box-shadow: $accent-box-shadow;
                    }
                }
            }
        }

        .video-description {
            hr {
                margin: 1rem 0;
            }

            h4 {
                font-family: 'Montserrat', sans-serif;
                font-size: .9rem;
                font-weight: 600;
                color: $dark-text;
                margin-bottom: 10px;
            }

            p, ul li {
                font-family: 'Roboto', sans-serif;
                color: lighten($dark-text, 28%);
                font-size: .95rem;
                max-width: 640px;
            }

            .additional-description {
                display: none;
                padding-top: 30px;
            }

            .video-description-more {
                display: block;
                font-size: 12px;
                font-weight: 500;
                color: $muted-grey;
                text-transform: uppercase;
                transition: color .3s;

                &:hover {
                    color: $accent;
                }
            }
        }

        .comments-wrap {
            padding: 20px 40px;
        }

        .comments-count {
            padding: 20px 0;
        }

        .comment-box {
            max-width: 720px;
        }

        .comment-box, .is-comment {
            .avatar-wrap {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 54px;
                height: 54px;
                min-width: 54px;
                border: 1.4px solid $placeholder;
                border-radius: 50%;

                &.is-smaller {
                    width: 44px;
                    height: 44px;
                    min-width: 44px;
                    border-width: 1px;

                    img {
                        width: 32px;
                        height: 32px;
                        min-width: 32px;
                    }

                    .badge {
                        right: -3px;
                        height: 18px;
                        width: 18px;

                        svg {
                            height: 8px;
                            width: 8px;
                            stroke-width: 4px;
                        }
                    }
                }

                img {
                    display: block;
                    width: 40px;
                    height: 40px;
                    min-width: 40px;
                    border-radius: 50%;
                }

                .badge {
                    position: absolute;
                    top: -2px;
                    right: -5px;
                    height: 22px;
                    width: 22px;
                    border-radius: 50%;
                    border: 2px solid $white-smoke;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: $green;

                    svg {
                        height: 10px;
                        width: 10px;
                        stroke: $white-smoke;
                        stroke-width: 4px;
                    }
                }
            }

            .control {
                position: relative;

                .textarea {
                    padding-bottom: 58px;
                    border-color: transparent;
                    transition: all .3s;

                    &:focus {
                        box-shadow: $light-box-shadow;
                    }
                }

                .button {
                    position: absolute;
                    bottom: 10px;
                    right: 10px;

                    &:hover {
                        background: $accent;
                        border-color: $accent;
                        color: $white-smoke;
                        border-radius: 100px;
                        box-shadow: $accent-box-shadow;
                    }
                }
            }
        }

        .is-comment {
            padding: 16px 0;
            margin: 0 !important;

            .comment-meta {
                h4 {
                    a {
                        color: $dark-text;
                        transition: color .3s;

                        &:hover {
                            color: $accent;
                        }
                    }

                    small {
                        color: $muted-grey;
                        font-size: .85rem;
                    }
                }

                p {
                    max-width: 640px;
                    font-size: .95rem;
                    color: lighten($dark-text, 23%);

                    .mention {
                        color: $accent;
                        font-weight: 500;
                    }
                }
            }

            .comment-stats-wrap {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                max-width: 640px;

                .comment-stats {
                    display: flex;

                    .stat {
                        display: flex;
                        align-items: center;
                        padding: 0 4px;

                        &.is-likes {
                            svg {
                                position: relative;
                                top: -2px;
                            }
                        }

                        &.is-dislikes {
                            svg {
                                position: relative;
                                top: 2px;
                            }
                        }

                        span {
                            display: block;
                            font-size: .9rem;
                            color: $muted-grey;
                        }

                        svg {
                            height: 18px;
                            width: 18px;
                            min-width: 20px;
                            stroke: $placeholder;
                        }
                    }
                }

                .comment-actions {
                    display: flex;
                    align-items: center;
                    height: 40px;
                    width: 96px;

                    .comment-action {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 30px;
                        width: 30px;
                        min-width: 30px;
                        margin: 0 2px;
                        border: 1px solid $fade-grey;
                        border-radius: 50%;
                        transition: all .3s;

                        &:nth-child(2) {
                            svg {
                                position: relative;
                                top: 2px;
                            }
                        }

                        &.is-like {
                            &:hover {
                                border-color: $green;

                                svg {
                                    stroke: $green;
                                }
                            }
                        }

                        &.is-dislike {
                            &:hover {
                                border-color: $red;

                                svg {
                                    stroke: $red;
                                }
                            }
                        }

                        &.is-reply {
                            &:hover {
                                border-color: $dark-text;

                                svg {
                                    stroke: $dark-text;
                                }
                            }
                        }

                        svg {
                            height: 14px;
                            width: 14px;
                            stroke: $placeholder;
                            transition: stroke .3s;
                        }
                    }
                }
            }

            .nested-replies {
                .header {
                    display: flex;
                    align-items: center;
                    padding: 0 4px;

                    &.is-active {
                        svg {
                            transform: rotate(180deg);
                        }
                    }

                    span {
                        display: block;
                        font-size: .9rem;
                        font-weight: 500;
                        color: $dark-text;
                    }

                    svg {
                        height: 16px;
                        width: 16px;
                        margin-left: 4px;
                        stroke: $dark-text;
                        transition: all .3s;
                    }
                }

                .nested-comments {
                    display: none;
                    padding: 10px 0;

                    .is-nested {
                        margin: 0;
                    }
                }
            }
        }

        .related-list {
            margin: 0 !important;
            padding: 20px;

            .related-label {
                padding-top: 20px;
                margin-top: 20px;
                border-top: 1px solid $fade-grey;
            }

            .autoplay, .related-label {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 20px;

                .autoplay-title, .related-title {
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 600;
                    color: $dark-text;
                    text-transform: uppercase;
                    font-size: .75rem;
                }

                .autoplay-toggle {
                    display: flex;
                    align-items: center;

                    span {
                        position: relative;
                        top: -1px;
                        display: block;
                        font-size: .7rem;
                        text-transform: uppercase;
                        color: $muted-grey;
                    }

                    .f-switch {
                        transform: scale(0.8);
                    }

                    .close-related-videos {
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        display: none;
                        align-items: center;
                        justify-content: center;
                        transition: all .3s;

                        &:hover {
                            background: $white;

                            svg {
                                stroke: $dark-text;
                            }
                        }

                        svg {
                            height: 16px;
                            width: 16px;
                            color: $muted-grey;
                            transition: stroke .3s;
                        }
                    }
                }
            }

            .related-card {
                + .media {
                    border-top: none;
                    margin-top: 0;
                }

                .media-left {
                    margin-right: .75rem;

                    img {
                        display: block;
                        border-radius: 6px;
                        object-fit: cover;
                        width: 130px;
                        min-width: 130px;
                        height: auto;
                        max-height: 88px;
                        box-shadow: $light-box-shadow;
                    }
                }

                .video-meta {
                    .related-title {
                        display: block;
                        font-family: 'Montserrat', sans-serif;
                        font-size: .85rem;
                        font-weight: 600;
                        max-width: 200px;
                        color: $dark-text;
                        transition: color .3s;

                        &:hover {
                            color: $accent;
                        }
                    }

                    .submeta {
                        display: flex;
                        align-items: center;

                        img {
                            display: block;
                            height: 26px;
                            width: 26px;
                            min-width: 26px;
                            border-radius: 50%;
                        }

                        .info {
                            margin-left: 6px;

                            span {
                                display: block;
                            }

                            .video-views, .video-account {
                                display: block;
                                color: $muted-grey;
                            }

                            .video-account {
                                font-size: .7rem;
                            }

                            .video-views {
                                font-size: .6rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ==========================================================================
3. Media Queries
========================================================================== */

@media (min-width: 1301px) {
    .videos-sidebar {
        width: 280px;
    }

    .videos-wrapper {
        &.is-home {
            &.is-full {
                .home-wrapper {
                    padding-top: 1rem;
                    width: 100% !important;
                    margin-left: 0 !important;

                    .home-content {
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }

            .home-wrapper {
                width: calc(100% - 280px);
                margin-left: 280px;
            }
        }

        &.has-player {
            &.is-full {
                .player-wrapper {
                    padding-top: 1rem;
                    width: 100% !important;
                    margin-left: 0 !important;

                    .meta-box {
                        background: transparent !important;
                    }
                }
            }

            .player-wrapper {
                width: calc(100% - 280px);
                margin-left: 280px;
            }
        }
    }
}

@media (max-width: 1300px) {
    .videos-sidebar {
        width: 250px;
    }

    .videos-wrapper {
        &.is-home {

            &.is-full {
                .home-wrapper {
                    padding-top: 1rem;
                    width: 100% !important;
                    margin-left: 0 !important;

                    .home-content {
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }

            .home-wrapper {
                width: calc(100% - 250px);
                margin-left: 250px;
            }
        }

        &.has-player {
            &.is-full {
                .player-wrapper {
                    padding-top: 1rem;
                    width: 100% !important;
                    margin-left: 0 !important;

                    .meta-box {
                        background: transparent !important;
                    }
                }
            }

            .player-wrapper {
                width: calc(100% - 250px);
                margin-left: 250px;
            }
        }
    }
}

@media (max-width: 767px) {
    .videos-sidebar {
        .videos-sidebar-inner {
            .user-block {
                .close-videos-sidebar {
                    display: flex !important;
                }
            }
        }
    }

    .videos-wrapper {
        &.is-home {
            .home-wrapper {
                width: 100% !important;
                margin: 0 !important;

                .mobile-sidebar-trigger {
                    display: flex;

                    &.is-home-v2 {
                        left: 20px;
                    }
                }

                .video-header {
                    .cover-caption {
                        .caption-inner {
                            padding: 0;

                            .video-caption {
                                .caption-block {
                                    width: 90%;

                                    h2 {
                                        font-size: 1.4rem;
                                    }

                                    .video-meta {
                                        span {
                                            &.rating {
                                                display: none;
                                            }
                                        }
                                    }

                                    .description, .caption-actions {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .home-content {
                padding: 30px;

                .collections-header {
                    &.is-home-v2 {
                        padding-left: 38px;
                    }
                }

                .collections-wrap {
                    .collection {
                        .video-collection {
                            .episode {
                                width: calc(100% - 20px);
                            }
                        }
                    }
                }
            }
        }

        &.has-player {
            .player-wrapper {
                width: 100% !important;
                margin: 0 !important;

                .video-side {
                    .meta-box {
                        margin: 0;

                        &.video-description {
                            p {
                                font-size: .85rem;
                            }
                        }

                        &.video-meta {
                            .video-title {
                                font-size: 1rem;
                            }
                        }

                        .video-subtitle {
                            flex-direction: column;

                            .quick-actions {
                                margin-left: -10px;

                                .quick-action {
                                    &.is-mobile {
                                        display: flex;
                                    }
                                }
                            }
                        }

                        .video-owner {
                            .button {
                                display: none;
                            }
                        }
                    }

                    .comments-wrap {
                        padding: 20px;

                        .is-comment {
                            .comment-meta {
                                h4 a {
                                    font-size: .85rem;
                                }

                                p {
                                    font-size: .85rem;
                                }
                            }
                        }
                    }
                }

                .related-side {
                    position: fixed;
                    right: 0;
                    max-height: calc(100% - 58px);
                    overflow-y: auto;
                    transform: translateX(100%);
                    transition: all .3s;

                    &.is-opened {
                        transform: translateX(0);
                    }

                    .close-related-videos {
                        display: flex !important;
                    }
                }
            }
        }
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .videos-sidebar {
        .videos-sidebar-inner {
            .user-block {
                .close-videos-sidebar {
                    display: flex !important;
                }
            }
        }
    }

    .videos-wrapper {
        &.is-home {
            .home-wrapper {
                width: 100% !important;
                margin: 0 !important;

                .mobile-sidebar-trigger {
                    display: flex;
                }

                .video-header {
                    .cover-caption {
                        .caption-inner {
                            .video-caption {
                                .caption-block {
                                    width: 65%;

                                    h2 {
                                        font-size: 1.3rem;
                                    }

                                    .description {
                                        font-size: .75rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .home-content {
                .collections-header {
                    &.is-home-v2 {
                        padding-left: 52px;
                    }
                }

                .collections-wrap {
                    .collection {
                        .video-collection {
                            .episode {
                                width: calc(33% - 20px);

                                &:nth-last-child(4) {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.has-player {
            .player-wrapper {
                width: 100%;
                margin: 0;

                .video-side {
                    .meta-box {
                        .video-subtitle {
                            .quick-actions {
                                .quick-action {
                                    &.is-mobile {
                                        display: flex;
                                    }
                                }
                            }
                        }
                    }
                }

                .related-side {
                    position: fixed;
                    right: 0;
                    max-height: calc(100% - 58px);
                    overflow-y: auto;
                    transform: translateX(100%);
                    transition: all .3s;

                    &.is-opened {
                        transform: translateX(0);
                    }

                    .close-related-videos {
                        display: flex !important;
                    }
                }
            }
        }
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .videos-wrapper {
        &.is-home {
            .home-wrapper {
                .video-header {
                    .cover-caption {
                        .caption-inner {
                            .video-caption {
                                .caption-block {
                                    width: 60%;

                                    h2 {
                                        font-size: 1.8rem;
                                    }

                                    .description {
                                        font-size: .75rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .home-content {
                .collections-wrap {
                    .collection {
                        .video-collection {
                            .episode {
                                width: calc(33% - 20px);

                                &:nth-last-child(4) {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.has-player {
            .player-wrapper {
                .video-side {
                    .meta-box {
                        .video-subtitle {
                            .quick-actions {
                                .quick-action {
                                    &.is-mobile {
                                        display: flex;
                                    }
                                }
                            }
                        }
                    }
                }

                .related-side {
                    position: fixed;
                    right: 0;
                    max-height: calc(100% - 58px);
                    overflow-y: auto;
                    transform: translateX(100%);
                    transition: all .3s;

                    .close-related-videos {
                        display: flex !important;
                    }

                    &.is-opened {
                        transform: translateX(0);
                    }
                }
            }
        }
    }
}
