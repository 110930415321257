/*! _utils.scss | Friendkit | © Css Ninja. 2020-2021 */

/* ==========================================================================
CSS utilities
========================================================================== */

/*
    0. Links
    1. Helpers
    2. Background images
    2. Slimscroll
    4. Animated checkmark
    5. Fancybox
    6. Loaders
    7. Code highlight
    8. Success Icon
    9. Page placeholder
    10. Error page
*/

/* ==========================================================================
0. Links
========================================================================== */

a {
    color: darken($accent, 5%);
    transition: all .3s;

    &:hover {
        color: darken($accent, 5%);
    }

    &.is-inverted {
        color: $accent;
    }

    &.standard-link {
        color: $blue;
        text-decoration: underline;
    }
}

/* ==========================================================================
1. Helpers
========================================================================== */

var {
    font-style: normal;
}

code {
    word-break: break-all;
}

.is-disabled {
    pointer-events: none;
    opacity: 0.4;
    cursor: default !important;
}

.no-click {
    pointer-events: none;
}

.is-vhidden {
    visibility: hidden !important;
}

.is-hidden {
    display: none !important;
}

.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

.ml-auto {
    margin-left: auto !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

/* ==========================================================================
2. Background images
========================================================================== */

.has-background-image {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center;

    &.is-top {
        background-position: top !important;
    }

    &.is-bottom {
        background-position: bottom !important;
    }
}

/* ==========================================================================
3. Slimscroll
========================================================================== */

.has-slimscroll {
    overflow-x: hidden !important;

    &::-webkit-scrollbar {
        width: 6px !important;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px !important;
        background: rgba(0, 0, 0, 0.2) !important;
    }
}

.has-slimscroll-md {
    overflow-x: hidden !important;

    &::-webkit-scrollbar {
        width: 4px !important;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px !important;
        background: rgba(0, 0, 0, 0.2) !important;
    }
}

.has-slimscroll-xs {
    overflow-x: hidden !important;

    &::-webkit-scrollbar {
        width: 2px !important;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px !important;
        background: rgba(0, 0, 0, 0.2) !important;
    }
}

/* ==========================================================================
4. Animated checkmark
========================================================================== */

$color--green: #7ac142;
$curve: cubic-bezier(0.65, 0, 0.45, 1);

.checkmark-wrapper {
    .checkmark__circle {
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke: $accent;
        fill: none;
        animation: stroke 0.6s $curve forwards;
        animation-delay: .4s;
    }

    .checkmark {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        display: block;
        stroke-width: 2;
        stroke: #fff;
        stroke-miterlimit: 10;
        margin: 10% auto;
        box-shadow: inset 0px 0px 0px $accent;
        animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
        animation-delay: .8s;

        &.is-small {
            height: 36px;
            width: 36px;
        }

        &.is-xs {
            height: 24px;
            width: 24px;
        }
    }

    .checkmark__check {
        transform-origin: 50% 50%;
        stroke-dasharray: 48;
        stroke-dashoffset: 48;
        animation: stroke 0.3s $curve 0.8s forwards;
        animation-delay: .8s;
    }
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%, 100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px $accent;
    }
}

/* ==========================================================================
5. Fancybox
========================================================================== */

.fancybox-content {
    display: inline-block;

    &.hidden {
        display: none;
    }
}

.morphing-btn-wrap, #morphing {
    width: 111.425px;
}

@media all and (max-width: 599px) {
    .fancybox-custom-layout {
        .fancybox-stage {
            position: relative;
            height: 240px;
            right: 0;
            left: 0;
            top: 0;
            z-index: 99;
        }

        .fancybox-slide {
            padding: 0 !important;
            width: calc(100% - 44px) !important;

            .fancybox-content {
                width: 100% !important;

                img {
                    margin: 0 20px !important;
                    max-width: calc(100% - 40px) !important;
                    object-fit: cover !important;
                }
            }
        }

        .fancybox-caption {
            width: calc(100% - 44px) !important;
            height: calc(100% - 240px) !important;
            top: 240px !important;
        }
    }
}

.fancybox-custom-layout {
    .fancybox-bg {
        background: $accent;
    }

    .fancybox-slide {
        background: darken($sidebar, 15%);
        padding: 0 40px;

        img {
            border-radius: 6px;
        }
    }

    .fancybox-custom-layout.fancybox-is-open .fancybox-bg {
        opacity: 1;
    }

    .fancybox-caption {
        background: $white;
        bottom: 0;
        color: #6c6f73;
        left: auto;
        padding: 10px 0;
        right: 44px;
        top: 0;
        width: 350px;
        text-align: left;

        &:before {
            display: none;
        }

        .fancybox-caption__body {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            padding-bottom: 50px;
        }

        a {
            text-decoration: none;

            &:hover {
                color: $accent;
            }
        }

        .comment-controls {
            position: absolute;
            bottom: 0;
            left: 0;
            background: lighten($light-grey, 2%);
            height: 50px;
            width: 100%;
            border-radius: 0 0 6px 0;
            border-top: 1px solid darken($light-grey, 8%);

            .controls-inner {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                height: 100%;
                padding: 0 16px;

                img {
                    height: 32px;
                    width: 32px;
                    border-radius: 50%;
                }

                .control {
                    position: relative;
                    width: 100%;

                    .textarea, .emojionearea-editor {
                        resize: none;
                        height: 36px;
                        max-height: 36px;
                        min-height: 36px;
                        border-radius: 100px;
                        overflow: hidden;
                        line-height: 1.6;
                        font-size: .8rem;
                        padding-left: 16px;
                        margin: 0 6px;
                        text-align: left;
                    }

                    .emoji-button {
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: 36px;
                        width: 36px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: none;
                        border: none;
                        outline: none;
                        transition: all .3s;
                        cursor: pointer;

                        &:hover {
                            svg {
                                stroke: $primary;
                            }
                        }

                        svg {
                            height: 16px;
                            width: 16px;
                            stroke: $light-text;
                            transition: all .3s;
                        }
                    }
                }

                .emojionearea-editor {
                    padding-left: 0 !important;

                    img {
                        height: 18px;
                        width: 18px;
                        min-height: 18px;
                        max-height: 18px;
                    }
                }

                .emojionearea {
                    overflow: visible !important;
                }

                .emojionearea-picker {
                    top: -230px;
                    position: absolute;
                    left: -50px;
                    width: 310px;

                    .emojionearea-wrapper {
                        width: 310px;

                        img {
                            height: 22px;
                            width: 22px;
                            min-height: 22px;
                            max-height: 22px;
                        }
                    }
                }

                .emojionearea-scroll-area {
                    width: 310px;
                }

                .emojionearea .emojionearea-button > div.emojionearea-button-open {
                    background-position: 0 -22px;
                }

                .emojionearea .emojionearea-picker .emojionearea-scroll-area .emojibtn {
                    width: 24px !important;
                    height: 24px !important;
                }
            }
        }

        .header {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 12px;
            width: 100%;

            img {
                height: 42px;
                width: 42px;
                border-radius: 50%;
            }

            .user-meta {
                margin: 0 10px;

                span {
                    display: block;

                    &:first-child {
                        font-size: .9rem;
                        font-weight: 500;

                        small {
                            color: $muted-grey;
                        }
                    }

                    &:nth-child(2) {
                        text-align: left;
                        font-size: .8rem;
                        color: $muted-grey;
                    }
                }
            }

            .button {
                line-height: 0;
                margin-left: auto;
                padding: 14px 18px;
            }

            .dropdown {
                .button {
                    padding: 18px 6px;
                    border: none;
                    background: transparent;

                    svg {
                        height: 18px;
                        width: 18px;
                    }
                }

                .dropdown-menu {
                    margin-top: 10px;
                }
            }
        }

        .inner-content {
            padding: 12px;

            .control {
                width: 100%;

                input {
                    padding-left: 34px;

                    &:focus {
                        + .icon svg {
                            stroke: $accent;
                        }
                    }
                }

                .icon {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 32px;
                    width: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    svg {
                        height: 18px;
                        width: 18px;
                        stroke: $placeholder;
                        transition: all .3s;
                    }
                }
            }
        }

        .live-stats {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 0 20px 0;
            border-bottom: 1px solid $fade-grey;

            .social-count {
                display: flex;
                align-items: stretch;

                .shares-count, .comments-count, .likes-count, .views-count {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin: 0 3px;

                    span {
                        display: block;
                        font-size: .8rem;
                        color: $grey-text;
                        margin: 0 5px;

                        &.views {
                            margin: 0 2px;
                        }
                    }

                    svg {
                        height: 14px;
                        width: 14px;
                        stroke: $grey-text;
                    }
                }
            }
        }

        .actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 0 0 0;

            .action {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                span {
                    display: block;
                    font-size: .8rem;
                    margin: 0 4px;
                    transition: all .3s;
                }

                svg {
                    height: 16px;
                    width: 16px;
                    stroke: $grey-text;
                    transition: all .3s;
                }

                &:hover {
                    span {
                        color: $accent;
                    }

                    svg {
                        stroke: $accent;
                    }
                }
            }
        }

        .comments-list {
            background: $light-grey;
            height: calc(100% - 162px);
            padding: 20px 14px;
            overflow-y: auto;

            .is-comment {
                border: none !important;
                padding-top: 0 !important;

                &.is-nested {
                    margin-left: 40px;
                }

                .media-left {
                    margin-right: 10px;

                    img {
                        border-radius: 50%;
                    }
                }

                .media-content {
                    background: $white;
                    padding: 12px;
                    border-radius: 8px;

                    .username {
                        font-size: .8rem;
                        font-weight: 500;
                    }

                    p {
                        font-size: .75rem;
                        color: $muted-grey;
                    }

                    .comment-actions {
                        display: flex;
                        align-items: center;
                        padding-top: 8px;

                        span, a {
                            display: block;
                            font-size: .75rem;
                        }

                        span {
                            margin: 0 10px;
                            color: $muted-grey;
                        }

                        .likes-count {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            margin-left: auto;

                            span {
                                display: block;
                                font-size: .75rem;
                                color: $grey-text;
                                margin: 0 5px;

                                &.views {
                                    margin: 0 2px;
                                }
                            }

                            svg {
                                height: 12px;
                                width: 12px;
                                stroke: $grey-text;
                            }
                        }
                    }
                }
            }
        }
    }

    .fancybox-stage {
        right: 394px;
    }

    .fancybox-toolbar {
        background: $accent;
        bottom: 0;
        left: auto;
        right: 0;
        top: 0;
        width: 44px;
    }

    .fancybox-button {
        background: transparent;

        div {
            padding: 2px;
        }
    }

    .fancybox-button[disabled] {
        color: $white;
    }

    .fancybox-button:not([disabled]) {
        color: $white;
    }

    .fancybox-button--arrow_right {
        right: 308px;
    }
}

@media only screen and (min-width: 600px) {
    .tabs.is-boxed li.is-active a {
        border-color: $fade-grey;
    }
}

/* ==========================================================================
6. Loaders
========================================================================== */

.subloader {
    position: fixed;
    top: 107px;
    left: 0;
    height: calc(100% - 107px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    z-index: -1;
    transition: all .3s;

    &.is-active {
        opacity: 1;
        z-index: 8;
    }

    &.is-grey {
        background: lighten(#eaeaea, 4%);
    }

    &.is-white {
        background: $white;
    }

    .loader {
        height: 5rem;
        width: 5rem;
    }
}

/* ==========================================================================
7. Code highlight
========================================================================== */

pre {
    background-color: transparent !important;
    color: unset;
    font-size: 0.875em;
    overflow-x: auto;
    padding: 0 !important;
    white-space: pre;
    word-wrap: normal;
}

.hljs {
    display: block;
    overflow-x: auto;
    font-size: 0.875em;
    padding: 1.5em;
    background: $sidebar;
    overflow-x: auto;
    white-space: pre;
    border-radius: 8px;
    color: white;
}

.hljs-name,
.hljs-strong {
    font-weight: bold;
    color: #4FC1EA;
}

.hljs-code,
.hljs-emphasis {
    font-style: italic;
}

.hljs-tag {
    color: #909090;
}

.hljs-variable,
.hljs-template-variable,
.hljs-selector-id,
.hljs-selector-class {
    color: #ade5fc;
}

.hljs-string,
.hljs-bullet {
    color: #F39200 !important;
}

.hljs-type,
.hljs-title,
.hljs-section,
.hljs-attribute,
.hljs-quote,
.hljs-built_in,
.hljs-builtin-name {
    color: #ffa;
}

.hljs-attr {
    color: #68BB88;
}

.hljs-number,
.hljs-symbol,
.hljs-bullet {
    color: #d36363;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-literal {
    color: #fcc28c;
}

.hljs-comment,
.hljs-deletion,
.hljs-code {
    color: #888;
}

.hljs-regexp,
.hljs-link {
    color: #c6b4f0;
}

.hljs-meta {
    color: #fc9b9b;
}

.hljs-deletion {
    background-color: #fc9b9b;
    color: #333;
}

.hljs-addition {
    background-color: #a2fca2;
    color: #333;
}

.hljs a {
    color: inherit;
}

.hljs a:focus,
.hljs a:hover {
    color: inherit;
    text-decoration: underline;
}



#message {
    top: -200px;
    transition: all .5s;
}

.fancybox-slide--current #message {
    top: 0;
}

.fancybox-is-closing #message {
    top: 0;
    transform: scale(1.5);
}

@media all and (min-width: 600px) {
    .element-block {
        .fancybox-custom-layout .fancybox-bg {
            background: #fcfaf9;
        }

        .fancybox-custom-layout.fancybox-is-open .fancybox-bg {
            opacity: 1;
        }

        .fancybox-custom-layout .fancybox-caption {
            background: #f1ecec;
            bottom: 0;
            color: #6c6f73;
            left: auto;
            padding: 30px 20px;
            right: 44px;
            top: 0;
            width: 256px;
        }

        .fancybox-custom-layout .fancybox-caption h3 {
            color: #444;
            font-size: 21px;
            line-height: 1.3;
            margin-bottom: 24px;
        }

        .fancybox-custom-layout .fancybox-caption a {
            color: #444;
        }

        .fancybox-custom-layout .fancybox-caption::before {
            display: none;
        }

        .fancybox-custom-layout .fancybox-stage {
            right: 300px;
        }

        .fancybox-custom-layout .fancybox-toolbar {
            background: #3b3b45;
            bottom: 0;
            left: auto;
            right: 0;
            top: 0;
            width: 44px;
        }

        .fancybox-custom-layout .fancybox-button {
            background: transparent;
        }

        .fancybox-custom-layout .fancybox-navigation .fancybox-button div {
            padding: 6px;
            background: #fcfaf9;
            border-radius: 50%;
            transition: opacity .2s;
            box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
            opacity: .7;
        }

        .fancybox-custom-layout .fancybox-navigation .fancybox-button:not([disabled]):hover div {
            opacity: 1;
        }

        .fancybox-custom-layout .fancybox-navigation .fancybox-button[disabled] {
            color: #999;
        }

        .fancybox-custom-layout .fancybox-navigation .fancybox-button:not([disabled]) {
            color: #333;
        }

        .fancybox-custom-layout .fancybox-button--arrow_right {
            right: 308px;
        }
    }
}


/* ==========================================================================
Fancybox examples
========================================================================== */

/*

Advanced example - Morphing modal window

https://codepen.io/fancyapps/pen/rmwyXN

*/

.morphing-btn-wrap {
    display: inline-block;
    position: relative;
    text-align: center;
}

.morphing-btn {
    box-sizing: border-box;
    -webkit-transition: background .3s, color .2s .2s, width .2s 0s;
    -moz-transition: background .3s, color .2s .2s, width .2s 0s;
    -o-transition: background .3s, color .2s .2s, width .2s 0s;
    transition: color .3s .2s, width .2s 0s;
    white-space: nowrap;
}

.morphing-btn_circle {
    color: transparent !important;
    padding-left: 0;
    padding-right: 0;
    -webkit-transition: color .2s 0s, width .3s .2s;
    -moz-transition: color .2s 0s, width .3s .2s;
    -o-transition: color .2s 0s, width .3s .2s;
    transition: color .2s 0s, width .3s .2s;
    width: 35.6px !important;
}

.morphing-btn-clone {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: $primary;
    border-radius: 50%;
    position: fixed;
    -webkit-transition: all 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
    -moz-transition: all 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
    -o-transition: all 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
    transition: all 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
    z-index: 3;
}

.morphing-btn-clone_visible {
    display: block;
    -webkit-transform: scale(1) !important;
    -moz-transform: scale(1) !important;
    -ms-transform: scale(1) !important;
    -o-transform: scale(1) !important;
    transform: scale(1) !important;
}

.fancybox-morphing .fancybox-bg {
    background: $primary;
    opacity: 1;
}

.fancybox-morphing .fancybox-toolbar {
    right: 40px;
    top: 20px;
}

.fancybox-morphing .fancybox-button--close {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    color: $white;
}

.fancybox-morphing .fancybox-button--close:hover {
    background: rgba(0, 0, 0, 0.25);
}

#morphing-content {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: transparent;
    line-height: 2;
    margin: 0;
    padding: 6em 10vw;
    position: relative;
    z-index: 3;
}

#morphing-content * {
    color: $white;
}

/*

Advanced example - Confirm dialog

https://codepen.io/fancyapps/pen/PmpePO

*/

.fc-container .fancybox-bg {
    background: #eee;
}

.fancybox-is-open.fc-container .fancybox-bg {
    opacity: .95;
}

.fc-content {
    box-shadow: 10px 10px 60px -25px;
    max-width: 550px;
}

/* Custom animation */

.fancybox-fx-material.fancybox-slide--previous,
.fancybox-fx-material.fancybox-slide--next {
    opacity: 0;
    transform: translateY(-60px) scale(1.1);
}

.fancybox-fx-material.fancybox-slide--current {
    opacity: 1;
    transform: translateY(0) scale(1);
}

/*

Advanced example - Product quick view

*/

.quick-view-container {
    background: rgba(10, 10, 10, 0.85);
}

.quick-view-content {
    bottom: 0;
    height: calc(100% - 40px);
    left: 0;
    margin: auto;
    max-height: 650px;
    max-width: 980px;
    position: absolute;
    right: 0;
    top: 0;
    width: calc(100% - 40px);
}

.quick-view-carousel {
    background: #fff;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 57%;
}

.quick-view-carousel .fancybox-stage {
    bottom: 30px;
}

.quick-view-aside {
    background: #fff;
    bottom: 30px;
    color: #777;
    left: auto;
    padding: 50px 0 30px 0;
    position: absolute;
    right: 0;
    top: 30px;
    width: 43%;
}

.quick-view-aside::before,
.quick-view-aside::after {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    top: 0;
}

.quick-view-aside::before {
    background: #f4f4f4;
    width: 8px;
}

.quick-view-aside::after {
    background: #e9e9e9;
    width: 1px;
}

.quick-view-aside > div {
    height: 100%;
    overflow: auto;
    padding: 5vh 30px 0 30px;
    text-align: center;
}

.quick-view-aside > div > p {
    font-size: 90%;
}

.quick-view-close {
    background: #f0f0f0;
    border: 0;
    color: #222;
    cursor: pointer;
    font-family: Arial;
    font-size: 14px;
    height: 44px;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    text-indent: -99999px;
    top: 30px;
    transition: all .2s;
    width: 44px;
}

.quick-view-close:hover {
    background: #e4e4e4;
}

.quick-view-close::before,
.quick-view-close::after {
    background-color: #222;
    content: '';
    height: 18px;
    left: 22px;
    position: absolute;
    top: 12px;
    width: 1px;
}

.quick-view-close:before {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.quick-view-close:after {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.quick-view-bullets {
    bottom: 0;
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    text-align: center;
    width: 100%;
    z-index: 99999;
}

.quick-view-bullets li {
    display: inline-block;
    vertical-align: top;
}

.quick-view-bullets li a {
    display: block;
    height: 30px;
    position: relative;
    width: 20px;
}

.quick-view-bullets li a span {
    background: #d4d2d2;
    border-radius: 99px;
    height: 10px;
    left: 50%;
    overflow: hidden;
    position: absolute;
    text-indent: -99999px;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
}

.quick-view-bullets li.active a span {
    background: #ff4453;
}

/* ==========================================================================
6. Hopscotch styles
========================================================================== */

.hopscotch-bubble {
    border: 1px solid $fade-grey !important;
    box-shadow: $light-box-shadow !important;
    border-radius: 2px !important;

    .hopscotch-bubble-container {
        background: $white !important;
        position: relative !important;
        z-index: 2 !important;

        .hopscotch-actions {
            .hopscotch-next {
                height: 32px;
                min-width: 80px;
                background: $white;
                color: $dark-text;
                border-radius: 100px;
                border-color: $placeholder;
                text-shadow: none !important;
                transition: all .3s;

                &:hover {
                    background: $accent;
                    box-shadow: $accent-box-shadow;
                    border-color: $accent;
                    color: $white;
                }
            }
        }
    }

    .hopscotch-arrow {
        &.down {
            .hopscotch-bubble-arrow-border {
                position: relative !important;
                top: -6px !important;
                border-top: 17px solid darken($fade-grey, 5%) !important;
            }
        }

        &.up {
            .hopscotch-bubble-arrow-border {
                position: relative !important;
                top: 6px !important;
                border-bottom: 17px solid darken($fade-grey, 5%) !important;
            }
        }

        &.right {
            .hopscotch-bubble-arrow-border {
                position: relative !important;
                left: -6px !important;
                border-left: 17px solid darken($fade-grey, 5%) !important;
            }
        }

        &.left {
            .hopscotch-bubble-arrow-border {
                position: relative !important;
                right: -6px !important;
                border-right: 17px solid darken($fade-grey, 5%) !important;
            }
        }
    }
}

.hopscotch-highlight {
    position: relative !important;
    z-index: 1001 !important;
}

/* ==========================================================================
8. Success Icon
========================================================================== */

$circle-length: 151px;
$check-length: 36px;

@keyframes scaleAnimation {
    0% {
        opacity: 0;
        transform: scale(1.5);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes drawCircle {
    0% {
        stroke-dashoffset: $circle-length;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes drawCheck {
    0% {
        stroke-dashoffset: $check-length;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

#successAnimationCircle {
    stroke-dasharray: $circle-length $circle-length;
    stroke: $secondary;
}

#successAnimationCheck {
    stroke-dasharray: $check-length $check-length;
    stroke: $secondary;
}

#successAnimationResult {
    fill: $secondary;
    opacity: 0;
}

#successAnimation.animated {
    animation: 1s ease-out 0s 1 both scaleAnimation;

    #successAnimationCircle {
        animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle, 0.3s linear 0.9s 1 both fadeOut;
    }

    #successAnimationCheck {
        animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck, 0.3s linear 0.9s 1 both fadeOut;
    }

    #successAnimationResult {
        animation: 0.3s linear 0.9s both fadeIn;
    }
}



/* ==========================================================================
9. Page placeholder
========================================================================== */

.page-placeholder {
    min-height: 520px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;

    &.is-wider {
        .placeholder-content {
            > p {
                font-size: 1rem;
                max-width: 420px;
            }
        }
    }

    .placeholder-content {
        text-align: center;

        img {
            display: block;
            max-width: 340px;
            margin: 0 auto 12px auto;

            &.is-larger {
                max-width: 440px;
            }
        }

        h3 {
            font-size: 1.3rem;
            font-weight: 600;
            font-family: $font-main;
            color: $dark-text;
        }

        p {
            font-size: 1.1rem;
            max-width: 440px;
            margin: 0 auto 12px auto;
            color: $light-text;

            &.is-large {
                max-width: 540px;
            }

            &.is-larger {
                max-width: 620px;
            }
        }

        .btn {
            margin-bottom: 8px;
        }
    }
}

body {
    &.is-dark {
        .page-placeholder {
            .placeholder-content {
                h3 {
                    color: $white;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .page-placeholder {
        .placeholder-content {
            img {
                max-width: 280px;
            }


        }
    }
}


/* ==========================================================================
10. Error page
========================================================================== */

.error-container {
    .error-wrapper {
        max-width: 840px;
        margin: 0 auto;
        padding-top: 40px;

        .error-inner {
            position: relative;
            max-width: 540px;
            margin: 0 auto;

            .bg-number {
                font-family: $font;
                position: absolute;
                top: -58px;
                left: -100px;
                right: 0;
                margin: 0 auto;
                font-size: 28rem;
                font-weight: 600;
                opacity: .15;
                z-index: 0;
            }

            img, h3, p, .button-wrap {
                position: relative;
                z-index: 1;
            }

            img {
                display: block;
                max-width: 100%;
                margin: 0 auto;
            }

            h3 {
                font-size: 1.5rem;
                font-family: $font-main;
                color: $dark-text;
                font-weight: 600;
                margin-top: 10px;
            }

            p {
                font-family: $font;
                font-size: 1.1rem;
                color: $light-text;
                margin-bottom: 16px;
            }

            .button-wrap {
                .button {
                    min-width: 220px;
                    min-height: 50px;
                }
            }
        }
    }
}

body {
    &.is-dark {
        .error-container {
            .error-wrapper {
                .error-inner {
                    .bg-number {
                        opacity: .9;
                    }

                    h3 {
                        color: $white;
                    }
                }
            }
        }
    }
}


.avatar-wrap {
    position: relative;
    display: inline-block;
    vertical-align: bottom;

    .avatar {
        width: 32px;
        min-width: 32px;
        height: 32px;
        object-fit: cover;
        border-radius: 50%;

        &.is-squared {
            border-radius: 10px !important;
        }
    }
}