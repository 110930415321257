/*! _layout-sidebar-v1.scss | Friendkit | © Css Ninja. 2020-2021 */

/* ==========================================================================
Content placeholder styles
========================================================================== */

/*
    0. Filters panel
*/

/* ==========================================================================
0. Filters panel
========================================================================== */

.filters-panel {
    position: fixed;
    top: 107px;
    left: 0;
    height: calc(100% - 107px);
    width: 300px;
    background: $white;
    border-right: 1px solid darken($fade-grey, 3%);
    transform: translateX(-100%);
    transition: all .3s;
    z-index: 2;

    &.is-active {
        transform: translateX(0);
    }

    .panel-inner {
        position: relative;
        width: 100%;

        .panel-title {
            font-family: Montserrat, sans-serif;
            font-size: .9rem;
            font-weight: 600;
            color: $muted-grey;
            padding: 20px;
            border-bottom: 1px solid $fade-grey;
        }

        .filter-block {
            padding: 20px;
            border-bottom: 1px solid $fade-grey;

            label {
                display: block;
                font-size: .7rem;
                font-weight: 500;
                text-transform: uppercase;
                margin-bottom: 8px;
                color: $dark-text;
            }

            .age-wrap {
                display: flex;
                align-items: center;

                .separator {
                    text-align: center;
                    padding: 0 8px;
                    font-size: .7rem;
                    text-transform: uppercase;
                    color: $muted-grey;
                }

                .field {
                    margin-bottom: 0;
                    max-width: 60px;

                    .control {
                        input {
                            height: 30px;
                            text-align: center;

                            &:focus {
                                border-color: $medium-text;
                            }
                        }
                    }
                }
            }
        }
    }
}
