/*! _navbar-v2.scss | Friendkit | © Css Ninja. 2020-2021 */

/* ==========================================================================
Navbar styles
========================================================================== */

/*
    0. Navbar v2
    1. Media Queries
*/

/* ==========================================================================
0. Navbar v2
========================================================================== */

.navbar-v2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 19;

    .top-nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 80px;
        width: 100%;
        background: $white;
        padding: 0 1.5rem;
        border-bottom: 1px solid $fade-grey;

        .left {
            display: flex;
            align-items: center;

            .brand {
                .navbar-logo {
                    display: block;
                    border-right: 1px solid darken($fade-grey, 3%);

                    img {
                        height: 46px;
                        width: 46px;
                        min-width: 46px;
                        margin-right: 1rem;
                    }
                }
            }

            .control {
                position: relative;
                margin-left: 1rem;
        
                .input {
                    position: relative;
                    height: 38px;
                    width: 320px;
                    padding-right: 85px;
                    padding-left: 38px;
                    padding-bottom: 8px;
                    background: lighten($fade-grey, 6%);
                    border-color: lighten($fade-grey, 6%);
                    transition: all .4s;
                    z-index: 1;
        
                    &:focus {
                        background: $white;
                        box-shadow: $light-box-shadow !important;
                        border-color: darken($fade-grey, 2%);
                    }
                }
        
                .input:not(:valid) ~ .reset-search {
                    opacity: 0;
                    pointer-events: none;
                }
        
                .input:focus ~ .search-icon svg {
                    stroke: $muted-grey !important;
                }
        
                .reset-search {
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 38px;
                    width: 38px;
                    opacity: 1;
                    pointer-events: all;
                    transition: all .3s;
                    z-index: 1;
                    cursor: pointer;
        
                    svg {
                        height: 18px;
                        width: 18px;
                        stroke: $muted-grey;
                    }
                }
        
                .search-icon {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 38px;
                    width: 38px;
                    z-index: 1;
        
                    svg {
                        height: 20px;
                        width: 20px;
                        stroke: $placeholder;
                        transition: all .3s;
                    }
                }
            }
        }

        .right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .mobile-search {
            height: 58px;
            display: flex;
            align-items: center;
            padding: 0 1rem;
            width: 100%;
    
            .control {
                position: relative;
                width: 100%;
    
                .input {
                    width: 100%;
                    height: 42px;
                    padding-left: 42px;
    
                    &:focus {
                        box-shadow: $light-box-shadow;
                        border-color: darken($fade-grey, 3%);

                        + .form-icon {
                            color: $primary;
                        }
                    }
                }
    
                .form-icon {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 42px;
                    width: 42px;
                    z-index: 2;
                    color: $placeholder;
                    transition: color .3s;
    
                    svg {
                        height: 20px;
                        width: 20px;
                        transition: stroke .3s;
                    }
                }
    
                .close-icon {
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 42px;
                    width: 42px;
                    z-index: 2;
                    color: $dark-text;
                    transition: color .3s;
    
                    svg {
                        height: 20px;
                        width: 20px;
                        transition: stroke .3s;
                    }
                }
            }
    
            .tipue-drop-content {
                position: absolute;
                top: 62px;
                width: 100%;
            }
        }
    }

    .sub-nav {
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        height: 70px;
        width: 100%;
        background: lighten($fade-grey, 4%);
        box-shadow: $medium-box-shadow;
        padding: 0 1.5rem;
        border-bottom: 1px solid $fade-grey;
        z-index: 0;

        .sub-nav-tabs {
            width: 100%;
            max-width: 640px;

            .tabs {
                ul {
                    border: none;

                    li {
                        margin: 0 .5rem;

                        &:not(.is-active) {
                            a {
                                border-color: transparent;
                                color: $light-text;
                            }
                        }

                        &.is-active {
                            a {
                                font-weight: 500;
                                color: $accent;
                                border-color: $accent;
                            }
                        }

                        a {
                            font-family: $font;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding-top: 0;
                            padding-bottom: 0;
                            height: 70px;
                            border-width: 4px;
                        }
                    }
                }
            }
        }
    }
}


/* ==========================================================================
1. Media Queries
========================================================================== */

@media only screen and (max-width: 767px) {
    .navbar-v2 {
        .top-nav {
            padding: 0 10px;

            .left {

                .brand {
                    .navbar-logo {
                        border: none;

                        img {
                            height: 36px;
                            width: 36px;
                            min-width: 36px;
                            margin-right: 0;
                        }
                    }
                }

                .control {
                    display: none;
                }
            }
        }

        .sub-nav {
            padding: 0;
        }

        /*.navbar-item {

            &.is-cart, &.is-account {
                padding-left: 6px !important;
                padding-right: 6px !important;
            }

            &.is-cart {
                .shopping-cart {
                    position: fixed;
                    top: 95px;
                    right: 0 !important;
                    left: 0 !important;
                    margin: 0 auto !important;

                    &:before, &:after {
                        left: 78% !important;
                    }
                }
            }
        }

        .nav-drop {
            position: fixed !important;
            top: 75px !important;
            left: 0 !important;
            right: 0 !important;
            width: calc(100% - 20px) !important;

            &:before, &:after {
                right: 46% !important;
            }
        }*/
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {

}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

}