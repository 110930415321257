/*! _app-stories.scss | Friendkit | © Css Ninja. 2020-2021 */

/* ==========================================================================
Stories styles
========================================================================== */

/*
    0. Stories Sidebar (Desktop)
    1. StoriesLayout
*/

/* ==========================================================================
0. Stories Sidebar (Desktop)
========================================================================== */

.stories-wrapper {
    .stories-sidebar {
        position: fixed;
        top: 57px;
        left: 0;
        height: calc(100% - 56px);
        background: lighten($fade-grey, 7%);
        border-right: 1px solid $fade-grey;
        transform: translateX(-100%);
        z-index: 10;
        transition: all .3s;

        &.is-active {
            transform: translateX(0);
        }

        .stories-sidebar-inner {
            position: relative;
            height: 100%;
            width: 100%;

            .user-block {
                position: relative;
                margin: 20px 20px 0 20px;
                border-bottom: 1px solid darken($fade-grey, 4%);
                text-align: center;
                height: 200px;

                .close-stories-sidebar {
                    position: absolute;
                    top: -12px;
                    right: -10px;
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    display: none;
                    align-items: center;
                    justify-content: center;
                    transition: all .3s;

                    &:hover {
                        background: $white;

                        svg {
                            stroke: $dark-text;
                        }
                    }

                    svg {
                        height: 20px;
                        width: 20px;
                        color: $muted-grey;
                        transition: stroke .3s;
                    }
                }

                .avatar-wrap {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 74px;
                    height: 74px;
                    min-width: 74px;
                    border: 1.4px solid lighten($placeholder, 4%);
                    border-radius: 50%;
                    margin: 0 auto 6px auto;

                    img {
                        display: block;
                        width: 58px;
                        height: 58px;
                        min-width: 58px;
                        border-radius: 50%;
                    }

                    .badge {
                        position: absolute;
                        bottom: 2px;
                        right: -3px;
                        height: 24px;
                        width: 24px;
                        border-radius: 50%;
                        border: 2px solid $white-smoke;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: $green;

                        svg {
                            height: 10px;
                            width: 10px;
                            stroke: $white-smoke;
                            stroke-width: 4px;
                        }
                    }
                }

                h4 {
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 700;
                    font-size: .9rem;
                    color: $dark-text;
                }

                p {
                    font-size: .8rem;
                    color: $muted-grey;
                }

                .user-stats {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    padding: 20px 0;

                    .stat-block {
                        text-align: center;

                        span {
                            display: block;

                            &:first-child {
                                text-transform: uppercase;
                                font-weight: 600;
                                font-size: .65rem;
                                color: $dark-text;
                            }

                            &:last-child {
                                font-size: .8rem;
                                color: $muted-grey;
                            }
                        }
                    }
                }
            }

            .user-menu {
                position: absolute;
                top: 200px;
                left: 0;
                width: 100%;
                height: calc(100% - 230px);

                .user-menu-inner {
                    position: relative;
                    height: 100%;
                    max-height: 100%;
                    width: 100%;
                    overflow-y: auto;

                    .separator {
                        margin: 0 30px;
                        border-bottom: 1px solid darken($fade-grey, 4%);
                    }

                    .menu-block {
                        padding: 20px 0;

                        ul {
                            li {
                                display: flex;
                                align-items: center;
                                padding: 14px 40px;
                                border-left: 4px solid transparent;

                                &.is-active {
                                    border-color: $accent;
                                }

                                a {
                                    display: flex;
                                    align-items: center;

                                    span {
                                        display: block;
                                        color: $dark-text;
                                        font-family: 'Montserrat', sans-serif;
                                        font-size: .8rem;
                                        font-weight: 600;
                                    }

                                    svg {
                                        height: 18px;
                                        width: 18px;
                                        stroke: $placeholder;
                                        margin-right: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ==========================================================================
1. Stories Layout
========================================================================== */

.stories-wrapper {
    padding-left: 0;
    padding-right: 0;
    
    &.is-full {
        .inner-wrapper {
            .stories-content {
                padding: 30px 0;
            }
        }
    }

    .inner-wrapper {
        position: relative;
        top: -2px;

        .mobile-sidebar-trigger {
            position: absolute;
            z-index: 9;
            top: 30px;
            left: 30px;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            display: none;
            align-items: center;
            justify-content: center;
            transition: all .3s;

            &.is-home-v2 {
                position: absolute;
                top: 30px;
                left: 34px;
                z-index: 5;

                svg {
                    stroke: $muted-grey;
                }
            }

            &:hover {
                svg {
                    stroke: $white;
                }
            }

            svg {
                height: 20px;
                width: 20px;
                color: $white-smoke;
                transition: stroke .3s;
            }
        }

        .stories-content {
            padding: 30px 40px;

            .section-title {
                display: flex;
                align-items: center;
                justify-content: space-between;

                h2 {
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 700;
                    font-size: 1.3rem;
                    color: $dark-text;
                }

                .view-all {
                    font-size: .8rem;
                    padding: 4px 12px;
                    border-radius: 100px;
                    border: 1px solid transparent;
                    background: lighten($fade-grey, 3%);
                    color: $muted-grey;
                    transition: all .3s;

                    &:hover {
                        border-color: darken($accent, 3%);
                        background: $white;
                        color: $accent;
                        box-shadow: $light-box-shadow;
                    }
                }

                .dropdown {
                    &:hover {
                        .button {
                            border-color: darken($fade-grey, 3%);
                            box-shadow: $light-box-shadow;
                        }
                    }

                    .button {
                        border-color: transparent;
                    }

                    .dropdown-menu {
                        margin-top: 6px;
                    }
                }
            }

            .stories-container {
                padding: 20px 0;

                .container-inner {
                    display: flex;

                    .story {
                        margin: 8px;
                        width: calc(10% - 16px);

                        .story-avatar {
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 70px;
                            width: 70px;
                            background: $white;
                            border-radius: 50%;
                            border: 1.6px solid $placeholder;
                            transition: all .3s;

                            &:hover {
                                border-color: $primary;
                            }

                            .add-overlay {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                height: 58px;
                                width: 58px;
                                transform: translate(-50%, -50%);
                                border-radius: 50%;
                                background: rgba($accent, 0.6);
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                svg {
                                    height: 16px;
                                    width: 16px;
                                    stroke-width: 3px;
                                    stroke: $white;
                                }
                            }

                            img {
                                display: block;
                                height: 58px;
                                width: 58px;
                                border-radius: 50%;
                            }
                        }
                    }
                }
            }

            .stories-feed {
                padding: 20px 0;

                .story-feed-item {
                    margin-bottom: .75rem;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &.is-medium {
                        .featured-image {
                            min-height: 250px;
                        }
                    }

                    &.is-large {
                        .featured-image {
                            min-height: 370px;
                        }
                    }

                    .featured-image {
                        display: block;
                        border-radius: 16px;
                        min-height: 190px;
                        object-fit: cover;
                    }

                    .item-meta {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 12px 0;

                        .user-info {
                            display: flex;
                            align-items: center;

                            .small-avatar {
                                position: relative;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 32px;
                                width: 32px;
                                border-radius: 50%;
                                border: 1px solid $placeholder;
                                transition: all .3s;

                                img {
                                    display: block;
                                    height: 24px;
                                    width: 24px;
                                    border-radius: 50%;
                                    box-shadow: $light-box-shadow;
                                }
                            }

                            span {
                                display: block;
                                margin-left: 10px;
                                font-family: 'Montserrat', sans-serif;
                                font-size: .75rem;
                                font-weight: 600;
                                color: $dark-text;
                            }
                        }

                        .item-stats {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;

                            .stat-item {
                                display: flex;
                                align-items: center;
                                padding: 0 4px;

                                svg {
                                    height: 16px;
                                    width: 16px;
                                    stroke-width: 1.4px;
                                    stroke: $dark-text;
                                }

                                span {
                                    display: block;
                                    margin-left: 4px;
                                    font-family: 'Montserrat', sans-serif;
                                    font-size: .75rem;
                                    font-weight: 600;
                                    color: $dark-text;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ==========================================================================
3. Story Post
========================================================================== */

.story-post-sidebar {
    position: fixed;
    top: 58px;
    right: 0;
    height: calc(100% - 58px);
    width: 360px;
    background: lighten($fade-grey, 4%);

    &.is-full {
        position: static;
        width: 100%;
        height: auto;
        background: none;
    }

    .header {
        position: relative;
        height: 80px;
        display: flex;
        align-items: center;
        padding: 0 30px;

        h2 {
            font-family: 'Montserrat', sans-serif;
            font-size: 1.1rem;
            font-weight: 700;
            color: $dark-text;
        }
    }

    .related-posts {
        position: relative;
        height: calc(100% - 80px);
        width: 100%;
        padding: 0 30px;

        .related-post {
            display: flex;
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }

            img {
                display: block;
                height: 56px;
                width: 56px;
                min-width: 56px;
                object-fit: cover;
                border-radius: 12px;
                box-shadow: $light-box-shadow;
            }

            .meta {
                margin-left: 12px;

                h3 {
                    font-family: 'Montserrat', sans-serif;
                    font-size: .85rem;
                    line-height: 1.3;
                    font-weight: 600;
                    color: lighten($dark-text, 4%);
                    margin-bottom: 6px;
                }

                .user-line {
                    display: flex;
                    align-items: center;

                    img {
                        display: block;
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        object-fit: cover;
                        border-radius: 50%;
                    }

                    span {
                        display: block;
                        font-family: 'Montserrat', sans-serif;
                        font-size: .7rem;
                        font-weight: 600;
                        color: $muted-grey;
                        margin-left: 6px;
                    }
                }
            }
        }
    }
}

.story-post-wrapper {
    width: calc(100% - 360px);
    margin-right: 360px;

    &.is-full {
        width: 100%;
        margin-right: 0;
        
        .story-post {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .story-post {
        width: 100%;
        max-width: 740px;
        margin: 0 auto;
        padding: 10px 40px 40px 40px;

        .post-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 0;

            h2 {
                font-family: 'Montserrat', sans-serif;
                font-size: 1.6rem;
                font-weight: 700;
                color: $dark-text;
            }
        }

        .post-image-wrap {
            .post-image {
                display: block;
                border-radius: 24px;
            }
        }

        .post-meta {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 10px;

            .post-author {
                display: flex;
                align-items: center;

                .story-avatar {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 46px;
                    width: 46px;
                    background: $white;
                    border-radius: 50%;
                    border: 1.6px solid $placeholder;
                    transition: all .3s;

                    &:hover {
                        border-color: $primary;
                    }

                    img {
                        display: block;
                        height: 36px;
                        width: 36px;
                        border-radius: 50%;
                    }
                }

                .meta {
                    margin-left: 12px;

                    span {
                        display: block;
                        line-height: 1.3;

                        &:first-child {
                            font-family: 'Montserrat', sans-serif;
                            font-size: .9rem;
                            font-weight: 700;
                            color: $dark-text;
                        }

                        &:nth-child(2) {
                            font-size: .9rem;
                            color: $muted-grey;
                        }
                    }
                }
            }

            .post-stats {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .stat-item {
                    display: flex;
                    align-items: center;
                    padding: 0 4px;

                    svg {
                        height: 20px;
                        width: 20px;
                        stroke-width: 1.4px;
                        stroke: $dark-text;
                    }

                    span {
                        display: block;
                        margin-left: 4px;
                        font-family: 'Montserrat', sans-serif;
                        font-size: .85rem;
                        font-weight: 600;
                        color: $dark-text;
                    }
                }
            }
        }

        .post-text {
            padding: 0 12px 12px 12px;
            margin-bottom: 0;

            p {
                font-size: .95rem;
            }
        }

        .post-tags {
            display: flex;
            align-items: center;
            padding: 0 12px 12px 12px;

            .tags {
                .tag {
                    cursor: pointer;
                }
            }
        }

        .post-compose {
            margin: 12px;
            padding: 16px;
            border-radius: 8px;
            border: 1px solid darken($fade-grey, 4%);
            background: $white;
            box-shadow: $light-box-shadow;

            .control {
                .textarea {
                    border: none;
                    resize: none;
                }
            }

            .compose-controls {
                display: flex;
                align-items: center;
                border-top: 1px solid darken($fade-grey, 2%);
                padding: 12px 0 0 0;

                img {
                    display: block;
                    height: 32px;
                    width: 32px;
                    border-radius: 50%;
                }

                .compose-actions {
                    padding: 0 12px;
                    display: flex;
                    align-items: center;

                    .action {
                        height: 36px;
                        width: 36px;
                        border-radius: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: lighten($fade-grey, 5%);
                        margin: 0 4px;
                        transition: all .3s;

                        &:hover {
                            background: lighten($fade-grey, 2%);

                            svg {
                                stroke: $dark-text;
                            }
                        }

                        svg {
                            height: 14px;
                            width: 14px;
                            stroke: $placeholder;
                            transition: stroke .3s;
                        }
                    }
                }

                .button {
                    margin-left: auto;
                }
            }
        }

        .comments-wrap {
            padding: 12px;

            .is-comment {
                padding: 16px 0;
                margin: 0 !important;

                .avatar-wrap {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 54px;
                    height: 54px;
                    min-width: 54px;
                    border: 1.4px solid $placeholder;
                    border-radius: 50%;

                    &.is-smaller {
                        width: 44px;
                        height: 44px;
                        min-width: 44px;
                        border-width: 1px;

                        img {
                            width: 32px;
                            height: 32px;
                            min-width: 32px;
                        }

                        .badge {
                            right: -3px;
                            height: 18px;
                            width: 18px;

                            svg {
                                height: 8px;
                                width: 8px;
                                stroke-width: 4px;
                            }
                        }
                    }

                    img {
                        display: block;
                        width: 40px;
                        height: 40px;
                        min-width: 40px;
                        border-radius: 50%;
                    }

                    .badge {
                        position: absolute;
                        top: -2px;
                        right: -5px;
                        height: 22px;
                        width: 22px;
                        border-radius: 50%;
                        border: 2px solid $white-smoke;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: $green;

                        svg {
                            height: 10px;
                            width: 10px;
                            stroke: $white-smoke;
                            stroke-width: 4px;
                        }
                    }
                }

                .comment-meta {
                    h4 {
                        a {
                            font-weight: 600;
                            font-size: .9rem;
                            color: $dark-text;
                            transition: color .3s;

                            &:hover {
                                color: $accent;
                            }
                        }

                        small {
                            color: $muted-grey;
                            font-size: .85rem;
                        }
                    }

                    p {
                        max-width: 640px;
                        font-size: .95rem;
                        color: lighten($dark-text, 23%);

                        .mention {
                            color: $accent;
                            font-weight: 500;
                        }
                    }
                }

                .comment-stats-wrap {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    max-width: 640px;

                    .comment-stats {
                        display: flex;

                        .stat {
                            display: flex;
                            align-items: center;
                            padding: 0 4px;

                            &.is-likes {
                                svg {
                                    position: relative;
                                    top: -2px;
                                }
                            }

                            &.is-dislikes {
                                svg {
                                    position: relative;
                                    top: 2px;
                                }
                            }

                            span {
                                display: block;
                                font-size: .9rem;
                                color: $muted-grey;
                            }

                            svg {
                                height: 18px;
                                width: 18px;
                                min-width: 20px;
                                stroke: $placeholder;
                            }
                        }
                    }

                    .comment-actions {
                        display: flex;
                        align-items: center;
                        height: 40px;
                        width: 96px;

                        .comment-action {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 30px;
                            width: 30px;
                            min-width: 30px;
                            margin: 0 2px;
                            border: 1px solid $fade-grey;
                            border-radius: 50%;
                            transition: all .3s;

                            &:nth-child(2) {
                                svg {
                                    position: relative;
                                    top: 2px;
                                }
                            }

                            &.is-like {
                                &:hover {
                                    border-color: $green;

                                    svg {
                                        stroke: $green;
                                    }
                                }
                            }

                            &.is-dislike {
                                &:hover {
                                    border-color: $red;

                                    svg {
                                        stroke: $red;
                                    }
                                }
                            }

                            &.is-reply {
                                &:hover {
                                    border-color: $dark-text;

                                    svg {
                                        stroke: $dark-text;
                                    }
                                }
                            }

                            svg {
                                height: 14px;
                                width: 14px;
                                stroke: $placeholder;
                                transition: stroke .3s;
                            }
                        }
                    }
                }

                .nested-replies {
                    .header {
                        display: flex;
                        align-items: center;
                        padding: 0 4px;

                        &.is-active {
                            svg {
                                transform: rotate(180deg);
                            }
                        }

                        span {
                            display: block;
                            font-size: .9rem;
                            font-weight: 500;
                            color: $dark-text;
                        }

                        svg {
                            height: 16px;
                            width: 16px;
                            margin-left: 4px;
                            stroke: $dark-text;
                            transition: all .3s;
                        }
                    }

                    .nested-comments {
                        display: none;
                        padding: 10px 0;

                        .is-nested {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

/* ==========================================================================
4. Media Queries
========================================================================== */

@media (min-width: 1301px) {
    .stories-sidebar {
        width: 280px;
    }

    .stories-wrapper {

        &.is-full {
            .inner-wrapper {
                width: 100% !important;
                margin-left: 0 !important;
            }
        }

        .inner-wrapper {
            width: calc(100% - 280px);
            margin-left: 280px;
        }
    }
}

@media (max-width: 1300px) {
    .stories-sidebar {
        width: 250px;
    }

    .stories-wrapper {
        width: calc(100% - 250px);
        margin-left: 250px;

        &.is-full {
            width: 100% !important;
            margin-left: 0 !important;

            .inner-wrapper {
                width: 100% !important;
                margin-left: 0 !important;
            }
        }
    }
}

@media (max-width: 767px) {
    .stories-sidebar {
        .stories-sidebar-inner {
            .user-block {
                .close-stories-sidebar {
                    display: flex !important;
                }
            }
        }
    }

    .stories-wrapper {
        width: 100% !important;
        margin: 0 !important;

        .inner-wrapper {
            width: 100% !important;
            margin: 0 !important;

            .mobile-sidebar-trigger {
                display: flex;

                &.is-home-v2 {
                    left: 20px;
                }

                &.is-story-post {
                    top: 24px;
                    left: 10px;
                }
            }

            .stories-content {
                padding: 30px 20px;

                .main-section-title {
                    h2 {
                        padding-left: 38px !important;
                    }
                }

                .stories-container {
                    .container-inner {
                        overflow-x: auto;

                        .story {
                            margin-right: 14px;

                            .story-avatar {
                                border-color: transparent;
                            }
                        }
                    }
                }
            }
        }
    }

    .story-post-sidebar {
        display: none;
    }

    .story-post-wrapper {
        width: 100%;
        margin-right: 0;

        .story-post {
            padding: 10px 16px 16px 16px;

            .post-title {
                padding-left: 32px;
                font-size: 1.4rem;

                h2 {
                    padding-left: 0;
                    font-size: 1.4rem;
                }

                .dropdown {
                    display: none;
                }
            }

            .post-image-wrap {
                .post-image {
                    min-height: 230px;
                }
            }

            .post-compose {
                .compose-controls {
                    img {
                        display: none;
                    }

                    .compose-actions {
                        padding-left: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .stories-sidebar {
        .stories-sidebar-inner {
            .user-block {
                .close-stories-sidebar {
                    display: flex !important;
                }
            }
        }
    }

    .stories-wrapper {
        width: 100% !important;
        margin: 0 !important;

        .inner-wrapper {
            width: 100% !important;
            margin: 0 !important;

            .mobile-sidebar-trigger {
                display: flex;

                &.is-home-v2 {
                    left: 20px;
                }

                &.is-story-post {
                    top: 25px;
                    left: 90px;                
                }
            }

            .stories-content {
                .main-section-title {
                    h2 {
                        padding-left: 38px !important;
                    }
                }

                .stories-feed {
                    .columns {
                        display: flex;
                    }
                }
            }
        }
    }

    .story-post-sidebar {
        display: none;
    }

    .story-post-wrapper {
        width: 100%;
        margin-right: 0;

        .story-post {
            max-width: 610px;

            .post-title {
                padding-left: 32px;

                h2 {
                    padding-left: 0;
                    font-size: 1.4rem;
                }

                .dropdown {
                    display: none;
                }
            }

            .post-image-wrap {
                .post-image {
                    min-height: 230px;
                }
            }

            .post-compose {
                .compose-controls {
                    img {
                        display: none;
                    }

                    .compose-actions {
                        padding-left: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .stories-wrapper {

        &.is-full {
            .inner-wrapper {
                .stories-content {
                    padding: 30px 0 !important;
                }
            }
        }

        .inner-wrapper {
            .stories-content {
                padding: 30px;
            }
        }
    }

    .story-post-sidebar {
        width: 280px;

        .header {
            padding: 0 20px;
        }

        .related-posts {
            padding: 0 20px;

            .related-post {
                img {
                    height: 40px;
                    width: 40px;
                    min-width: 40px;
                }
            }
        }
    }

    .story-post-wrapper {
        width: calc(100% - 280px);
        margin-right: 280px;

        .story-post {
            padding-left: 20px;
            padding-right: 20px;

            .post-title {
                h2 {
                    font-size: 1.4rem;
                }
            }
        }
    }
}
