/*! _layout.scss | Friendkit | © Css Ninja. 2020-2021 */

/* ==========================================================================
Main layout styles
========================================================================== */

/*
    1. Body
    2. Global Overlay
    3. Container
    4. View Wrapper
    5. Columns
    6. Footer
    7. Copyright
    8. Media Queries
*/

/* ==========================================================================
1. Body
========================================================================== */

body {
    background: lighten(#eaeaea, 4%);
    height: 100%;
    width: 100%;
    min-height: 100vh;
    font-family: Roboto,-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif;
    color: $sidebar;
    overflow: hidden;

    &.is-frozen {
        overflow: hidden !important;
        position: fixed;
        top: 0;
        left: 0;
    }

    &.is-white {
        background: $white;
    }
}



/* ==========================================================================
2. Global Overlay
========================================================================== */

.app-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: -1;
    opacity: 0;

    &.is-active {
        opacity: 1 !important;
        z-index: 1000 !important;
    }
}

/* ==========================================================================
3. Container
========================================================================== */

.container {
    &.is-fluid {
        margin: 0 !important;
    }

    &.is-desktop {
        max-width: 1180px !important;
    }

    &.sidebar-boxed {
        max-width: 1040px !important;
    }
}

/* ==========================================================================
4. View Wrappers
========================================================================== */

.view-wrapper {
    padding: 60px 12px;
    transition: all .3s;

    &.is-side {
        width: calc(100% - 70px);
        margin-left: 70px;
    }

    &.is-full {
        padding: 60px 0 0 0;
    }

    &.is-sidebar-v1 {
        position: relative;
        margin-left: 280px;
        width: calc(100% - 280px);
        padding-top: 1.5rem;

        &.is-fold {
            width: 100%;
            margin-left: 0;
        }
    }

    &.is-map {
        position: relative;
        top: 57px;
        padding: 0;
        min-height: calc(100vh - 57px);
    }

    &.is-map-pushed {
        position: relative;
        top: 150px;
        padding: 0;
        min-height: calc(100vh - 150px);
    }

    .view-wrap {
        padding: 20px 0;

        &.is-headless {
            padding-top: 10px;
        }
    }
}

.navbar-v2-wrapper {
    padding-top: 6rem;
}

.inbox-wrapper {
    position: absolute;
    top: 58px;
    left: 0;
    width: 100%;
    height: calc(100% - 58px);
}

#profile {
    padding: 80px 0;
}

.main-container {
    width: 100%;
    transition: all .3s;

    &.has-sidebar {
        width: calc(100% - 300px);
        margin-left: 300px;
    }
}

/* ==========================================================================
5. Columns
========================================================================== */

.columns {
    &.no-mb {
        margin-bottom: 0 !important;
    }
}

.column {
    &.is-paddingless {
        padding: 0;
    }

    &.no-padding-left {
        padding-left: 0;
    }

    &.no-padding-right {
        padding-right: 0;
    }
}

.card-row {
    display: flex;
    flex-wrap: wrap;

    .card-flex {
        width: calc(25% - 16px);
        margin: 8px;
        background: $white;
        border: 1px solid darken($fade-grey, 4%);
        border-radius: 6px;
    }
}

/* ==========================================================================
6. Footer
========================================================================== */

.footer-light-medium {
    padding-bottom: 0 !important;
    padding-top: 4rem !important;
    background: lighten($fade-grey, 5%);

    .footer-head {
        padding-bottom: 3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid darken($fade-grey, 4%);

        .head-text {
            h3 {
                font-family: 'Montserrat', sans-serif;
                font-size: 1.6rem;
                color: $dark-text;
            }

            p {
                font-size: 1rem;
                color: $muted-grey;
            }
        }

        .head-action {
            .buttons {
                .button {
                    &.action-button {
                        height: 36px;
                        min-width: 140px;
                    }

                    &.chat-button {
                        background: transparent;
                        border: none;
                        outline: none;
                        box-shadow: none;
                        color: $primary;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    .footer-body {
        padding-top: 3rem;

        .footer-column {
            padding-top: 20px;

            .column-header {
                font-family: 'Montserrat', sans-serif;
                text-transform: uppercase;
                color: $dark-text;
                font-size: .9rem;
                font-weight: 700;
                margin: 10px 0;
            }

            .column-item {
                padding-bottom: 10px;

                a {
                    font-size: .9rem;
                    color: $muted-grey;

                    &:hover {
                        color: $primary;
                    }
                }
            }
        }

        .social-links {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 10px 0;

            a {
                color: $muted-grey;
                margin: 0 5px;

                &:hover {
                    color: $primary;
                }

                svg {
                    height: 18px;
                    width: 18px;
                    stroke-width: 1.4px;
                }
            }
        }

        .footer-description {
            color: $muted-grey;
            padding: 10px 0;
        }

        .moto {
            color: $muted-grey;
            font-size: .85rem;

            svg {
                height: 14px;
                width: 14px;
                fill: $red;
                stroke: $red;
                margin: 0 2px;
            }
        }

        .small-footer-logo {
            height: 30px;
        }
    }

    .footer-copyright {
        color: $muted-grey;
        padding: 4rem 0 2rem 0;
        font-size: .9rem;

        a {
            color: $muted-grey;

            &:hover {
                color: $primary;
            }
        }
    }
}

/* ==========================================================================
8. Media Queries
========================================================================== */

@media only screen and (max-width: 767px) {
    .view-wrapper {
        &.is-sidebar-v1 {
            margin-left: 0 !important;
            width: 100% !important;
        }
    }

    .view-wrap {
        &.is-headless {
            padding-top: 10px !important;
        }
    }

    .column {
        &.no-padding-left {
            padding-left: .75rem;
        }

        &.no-padding-right {
            padding-right: .75rem;
        }

        &.no-side-padding-mobile {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
    

    .control {
        &.is-grouped {
            margin-bottom: 8px;
        }
    }

    .footer-light-medium {
        .footer-head {
            flex-direction: column;
            text-align: center;

            .head-text {
                padding-bottom: 20px;
            }
        }

        .footer-body {
            padding: 1rem !important;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {

    .view-wrapper {
        &.is-sidebar-v1 {
            margin-left: 0 !important;
            width: 100% !important;
        }
    }

    .columns {
        &.has-portrait-padding {
            padding: 0 40px;
        }
    }

    .column {
        &.no-padding-left {
            padding-left: .75rem;
        }

        &.no-padding-right {
            padding-right: .75rem;
        }
    }

    .footer-light-medium {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;

        .footer-head {
            padding: 1rem !important;
        }

        .footer-body {
            display: flex;
            padding: 2rem 1rem !important;

            .column.is-4 {
                width: 42%;
                max-width: 42%;
                flex-shrink: 1;
            }

            .is-offset-2 .columns {
                display: flex !important;
            }
        }

        .columns {
            .column {
                &.is-4 {
                    text-align: center;

                    > div {
                        max-width: 430px;
                        margin: 20px auto;
                    }

                    .moto {
                        margin: 0;
                    }
                }

                &.is-offset-2 {
                    .columns {
                        display: flex;
                        text-align: center;

                        .footer-column {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }

    .copyright {
        .inner {
            justify-content: center;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .container {
        max-width: 100%;

        .column {
            padding: .5rem;
        }
    }

    .view-wrapper {
        &.is-sidebar-v1 {
            margin-left: 250px;
            width: calc(100% - 250px);
            padding-top: 1.5rem;
        }
    }

    .is-hidden-landscape {
        display: none !important;
    }
}

@media screen and (min-width: 1297px) {
    .container.is-custom {
        max-width: 1240px;
        width: 1240px;
    }
}

@media screen and (min-width: 1088px) {
    .container.is-custom {
        max-width: 1140px;
        width: 1140px;
    }
}

@media screen and (max-width: 1300px) {
    #main-feed {
        max-width: 1240px;
    }
}