/*! _app-inbox.scss | Friendkit | © Css Ninja. 2020-2021 */

/* ==========================================================================
Inbox styles
========================================================================== */

/*
    0. Inbox Layout
    1. Action buttons
    2. Media Queries
*/

//import mixins
@import "../abstracts/mixins";

/* ==========================================================================
0. Inbox layout
========================================================================== */

.inbox-wrapper {
    .inbox-wrapper-inner {
        position: relative;
        width: 100%;
        height: 100%;

        .inbox-left-sidebar {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 16%;
            background: lighten(#eaeaea, 4%) !important;
            border-right: 1px solid darken($fade-grey, 5%);
            z-index: 1;
            transition: all .3s;

            .inbox-left-sidebar-inner {
                width: 100%;
                height: 100%;

                .compose {
                    padding: 0 20px;
                    min-height: 60px;
                    display: flex;
                    align-items: center;

                    .button {
                        height: 40px;
                    }
                }

                .left-menu {
                    padding: 10px 0;

                    a {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        margin: 0 20px;
                        padding: 10px;
                        color: darken($placeholder, 14%);
                        font-size: .95rem;
                        border-radius: 4px;

                        &.is-active {
                            background-color: $accent;
                            color: $white-light;
                            box-shadow: $accent-box-shadow;
                        }

                        span {
                            display: block;
                            margin-left: 10px;
                        }

                        svg {
                            height: 18px;
                            width: 18px;
                        }
                    }
                }

                .close-menu {
                    padding: 20px 50px;
                    text-align: center;

                    a {
                        display: flex;
                        align-items: center;
                        color: $muted-grey;
                        cursor: pointer;

                        svg {
                            height: 20px;
                            width: 20px;
                            margin-right: 4px;
                        }
                    }
                }
            }
        }

        .inbox-center-container {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: $white;
            transition: all .3s;

            &.is-left-opened {
                width: calc(100% - 280px);
                left: 280px;
            }

            &.is-both-opened {
                width: 34%;
                left: 16%;
                right: 50%;
            }

            .inbox-center-container-inner {
                position: relative;
                width: 100%;
                height: 100%;

                .messages {
                    display: block;
                    background-color: $white;

                    .action-buttons {
                        min-height: 60px;
                        display: flex;
                        align-items: center;
                        padding: 0 20px;
                        border-bottom: 1px solid $fade-grey;

                        .button {
                            max-height: 32px;
                            padding: 4px 8px;

                            svg {
                                height: 16px;
                                width: 16px;
                                stroke: $grey-text;
                            }
                        }
                    }
                }

                .message-count {
                    font-size: .9rem;
                    color: $muted-grey;
                    margin-left: 12px;
                }

                .inbox-messages {
                    position: absolute;
                    top: 0;
                    left: 0;
                    margin-top: 60px;
                    height: calc(100% - 60px);
                    max-height: calc(100% - 60px);
                    overflow-y: auto;

                    .inbox-messages-inner {
                        padding: 20px;

                        .message {
                            display: block;
                            background-color: $white;
                        }

                        .card {
                            width: 100%;
                            cursor: pointer;
                            transition: all .3s;

                            &.is-active {
                                background-color: lighten($fade-grey, 8%);
                                box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.11) !important;

                                .msg-from a {
                                    color: $accent;
                                }

                                .msg-attachment svg {
                                    stroke: $accent;
                                }
                            }

                            &.has-attachment {
                                .msg-attachment {
                                    svg {
                                        display: block;
                                        transition: stroke .3s;
                                    }
                                }
                            }

                            .msg-from {
                                color: $muted-grey;

                                a {
                                    font-weight: 500;
                                    color: $muted-grey;
                                    transition: color .3s;
                                }
                            }

                            .msg-timestamp {
                                float: right;
                                padding: 0 20px;
                                color: $muted-grey;
                                font-size: .9rem;
                            }

                            .msg-attachment {
                                float: right;

                                svg {
                                    display: none;
                                    height: 16px;
                                    width: 16px;
                                    stroke: $muted-grey;
                                }
                            }

                            .msg-subject {
                                display: flex;
                                align-items: center;
                                padding: 10px 0;

                                span {
                                    display: block;
                                    font-family: 'Montserrat', sans-serif;
                                    font-weight: 600;
                                    font-size: .9rem;
                                    color: $dark-text;
                                    margin: 0 4px;
                                }

                                svg {
                                    position: relative;
                                    top: -1px;
                                    height: 16px;
                                    width: 16px;
                                    stroke: $muted-grey;
                                    fill: none;
                                    cursor: pointer;
                                    transform: rotate(0);
                                    transition: all .3s;

                                    &.is-active {
                                        transform: rotate(360deg);
                                        stroke: $primary;
                                        fill: $primary;
                                    }
                                }
                            }

                            .msg-snippet {
                                color: $muted-grey;
                                font-size: .9rem;
                                padding-right: 20px;

                                p {
                                    @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 2, $bgColor: $white);
                                }
                            }
                        }

                        strong {
                            color: #5D5D5D;
                        }

                        .msg-check {
                            padding: 0 20px;
                        }
                    }
                }
            }
        }

        .inbox-message-container {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 50%;
            background: $white;
            transition: all .3s;
            border-left: 1px solid darken($fade-grey, 5%);

            .inbox-message-container-inner {
                position: relative;
                height: 100%;
                width: 100%;

                .message-head {
                    .action-buttons {
                        min-height: 60px;
                        display: flex;
                        align-items: center;
                        padding: 0 20px;
                        border-bottom: 1px solid $fade-grey;

                        .button {
                            max-height: 32px;
                            padding: 4px 8px;

                            svg {
                                height: 16px;
                                width: 16px;
                                stroke: $grey-text;
                            }
                        }
                    }
                }

                .message-body {
                    position: absolute;
                    top: 0;
                    left: 0;
                    margin-top: 60px;
                    height: calc(100% - 60px);
                    max-height: calc(100% - 60px);
                    width: 100%;
                    overflow-y: auto;
                    border-left: none;
                    padding: 1.25em !important;

                    .message-body-inner {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        display: none;
                        transition: all .3s;
                        animation-name: fadeInLeft;
                        animation-duration: .5s;

                        &.is-active {
                            display: block;
                        }

                        .message-preview-transition {
                            &.is-first {
                                margin-top: -36px !important;
                            }

                            .mail {
                                position: relative;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin: 60px auto;
                                height: 40px;
                                width: 40px;
                                border-radius: 50%;
                                color: $white;
                                border: 1px solid $accent-grey;

                                &:before {
                                    content: '';
                                    position: absolute;
                                    top: -30px;
                                    left: 0;
                                    right: 0;
                                    height: 1px;
                                    width: 40px;
                                    background: $grey-text;
                                    transform: rotate(90deg);
                                }

                                &:after {
                                    content: '';
                                    position: absolute;
                                    bottom: -30px;
                                    left: 0;
                                    right: 0;
                                    height: 1px;
                                    width: 40px;
                                    background: $grey-text;
                                    transform: rotate(90deg);
                                }

                                svg {
                                    width: 20px;
                                    height: 20px;
                                    stroke: $grey-text;
                                }
                            }
                        }

                        .message-preview {
                            margin: 0 0 20px 0;
                            padding: 30px;
                            box-shadow: $light-box-shadow;
                            border: 1px solid $fade-grey;

                            .avatar {
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                img {
                                    width: 48px;
                                    border-radius: 50px;
                                    border: 2px solid #999;
                                    padding: 2px;
                                }
                            }

                            .box-inner {
                                .header {
                                    display: flex;
                                    align-items: center;

                                    .meta {
                                        display: block;
                                        padding: 0 20px;

                                        .name {
                                            font-weight: 500;
                                            color: $dark-text;
                                        }

                                        .date {
                                            font-size: .9rem;
                                            color: $muted-grey;
                                        }
                                    }

                                    .meta-right {
                                        text-align: right;
                                        margin-left: auto;

                                        .tag {
                                            font-weight: 500;
                                            background: lighten($fade-grey, 6%);
                                            color: $dark-text;
                                        }

                                        svg {
                                            position: relative;
                                            top: 2px;
                                            height: 16px;
                                            width: 16px;
                                            stroke: $placeholder;
                                        }

                                        small {
                                            color: $muted-grey;
                                            font-size: .95rem;
                                        }
                                    }
                                }
                            }
                        }

                        .attachments {
                            padding-bottom: 40px;

                            .attachments-header {
                                .paperclip {
                                    position: relative;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    margin: 60px auto;
                                    height: 40px;
                                    width: 40px;
                                    border-radius: 50%;
                                    color: $white;
                                    border: 1px solid $accent-grey;

                                    &:before {
                                        content: '';
                                        position: absolute;
                                        top: -30px;
                                        left: 0;
                                        right: 0;
                                        height: 1px;
                                        width: 40px;
                                        background: $grey-text;
                                        transform: rotate(90deg);
                                    }

                                    &:after {
                                        content: '';
                                        position: absolute;
                                        bottom: -30px;
                                        left: 0;
                                        right: 0;
                                        height: 1px;
                                        width: 40px;
                                        background: $grey-text;
                                        transform: rotate(90deg);
                                    }

                                    svg {
                                        width: 20px;
                                        height: 20px;
                                        stroke: $grey-text;
                                    }
                                }
                            }

                            .attachments-inner {
                                display: flex;
                                flex-wrap: wrap;
                                max-width: 380px;
                                margin: 0 auto;

                                .box {
                                    width: calc(50% - 20px);
                                    margin: 0 10px 16px 10px;
                                    padding: 0;
                                    box-shadow: $light-box-shadow;
                                    border: 1px solid $fade-grey;
                                    border-radius: 4px;

                                    &.has-file {
                                        justify-content: center;
                                        align-items: center;

                                        .box-inner {
                                            text-align: center;
                                            padding: 22px 12px;

                                            svg {
                                                stroke-width: 1.2px;
                                            }

                                            h5 {
                                                font-weight: 500;
                                                font-size: .9rem;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                            }
                                        }

                                        .actions-group {
                                            display: flex;
                                            border-top: 1px solid $fade-grey;

                                            .action {
                                                width: 50%;
                                                height: 34px;
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                                transition: all .3s;

                                                &:first-child {
                                                    border-right: 1px solid $fade-grey;
                                                }

                                                &:hover {
                                                    background: lighten($fade-grey, 7%);

                                                    svg {
                                                        stroke: $dark-text;
                                                    }
                                                }

                                                svg {
                                                    height: 18px;
                                                    width: 18px;
                                                    stroke: $grey-text;
                                                    transition: stroke .3s;
                                                }
                                            }
                                        }
                                    }

                                    img {
                                        display: block;
                                        border-radius: 2px;
                                    }
                                }
                            }
                        }

                        .reply-wrapper {
                            padding: 40px;

                            .reply-title {
                                font-size: 1.1rem;
                                color: $dark-text;
                                font-weight: 500;
                                margin-bottom: 20px;
                                display: flex;
                                align-items: center;

                                svg {
                                    height: 18px;
                                    width: 18px;
                                    stroke: $muted-grey;
                                    margin-left: 8px;
                                    cursor: pointer;
                                }
                            }

                            .reply-wrapper-inner {
                                width: 100%;
                                border: 1px solid $fade-grey;
                                border-radius: 4px;
                                padding: 30px;
                                box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);

                                .flex-form {
                                    display: flex;
                                }

                                img {
                                    margin-top: 2px;
                                    height: 44px;
                                    width: 44px;
                                    border-radius: 50%;
                                }

                                .control {
                                    position: relative;
                                    width: 100%;
                                    margin-left: 10px;

                                    .textarea {
                                        border: none !important;
                                        margin-bottom: 20px;
                                    }

                                    .reply-textarea {
                                        margin-bottom: 20px;
                                        min-height: 120px;

                                        p {
                                            font-style: normal;
                                            font-size: 1rem;
                                            font-family: 'Roboto', sans-serif;
                                        }
                                    }

                                    .ql-editor::before {
                                        font-style: normal;
                                        font-size: 1rem;
                                        color: $placeholder;
                                    }
                                }

                                .send-message {
                                    line-height: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ==========================================================================
1. Action Buttons
========================================================================== */

.action-buttons {
    .control {
        &.is-grouped {
            display: inline-block;
            margin-right: 16px;

            &:last-child {
                margin-right: 0;
            }

            .button {
                margin-right: -5px;
                border-radius: 0;
                background: $white;
                transition: all .3s;

                &:first-child {
                    border-radius: 5px 0 0 5px;
                }

                &:last-child {
                    border-radius: 0 5px 5px 0;
                }

                &:hover {
                    background: lighten($fade-grey, 5%);
                }

                &.is-solo {
                    border-radius: 5px !important;
                }

                svg {
                    transition: all .3s;
                }
            }
        }
    }
}

/* ==========================================================================
2. Media Queries
========================================================================== */

@media only screen and (max-width: 1300px) {
    .inbox-wrapper {
        .message-count {
            display: none;
        }
    }
}

@media (max-width: 767px) {
    .inbox-wrapper {
        overflow: hidden;
        .action-buttons {
            .is-grouped {
                margin-right: 0 !important;
                margin-bottom: 0 !important;
            }
        }

        .inbox-message-container {
            width: 100% !important;
            transform: translateX(100%);

            &.is-opened-mobile {
                transform: translateX(0);
            }

            .message-body {
                padding: 1.25em .5em !important;
    
                .message-preview {
                    .header {
                        flex-direction: column !important;
                        text-align: center !important;
    
                        .meta-right {
                            margin: 10px 0 !important;
                            text-align: center !important;
                        }
                    }
                }
            }

            .action-buttons {
                .is-grouped {
                    &.is-hidden-landscape {
                        margin-left: auto !important;
                    }
                }
            }
        }

        .inbox-center-container {
            width: 100% !important;
            left: 0 !important;

            .action-buttons {
                padding: 10px 20px !important;

                .is-grouped {
                    &.is-hidden-landscape {
                        margin-right: auto !important;
                    }
                }
            }
        }

        .reply-wrapper {
            padding: 20px 10px !important;

            .reply-wrapper-inner {
                padding: 10px !important;
            }
        }
    }

    .inbox-left-sidebar {
        width: 260px !important;
        transform: translateX(-260px);

        &.is-opened-mobile {
            transform: translateX(0);
        }

        .inbox-left-sidebar-inner {
            .close-menu {
                padding: 0 30px !important;
            }
        }
    }

    .is-vhidden {
        display: none !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .inbox-wrapper {
        overflow: hidden;
    }

    .inbox-left-sidebar {
        width: 260px !important;
        transform: translateX(-260px);

        &.is-opened-mobile {
            transform: translateX(0);
        }
    }

    .inbox-center-container {
        width: 100% !important;
        left: 0 !important;

        .action-buttons {
            padding: 10px 20px !important;
        }
    }

    .inbox-message-container {
        width: 100% !important;
        transform: translateX(100%);

        &.is-opened-mobile {
            transform: translateX(0);
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .inbox-left-sidebar {
        width: 21% !important;

        .compose {
            padding: 20px 10px !important;
        }

        .left-menu {
            a {
                margin: 0 10px !important;
                padding: 10px !important;
            }
        }
    }

    .inbox-message-container {
        width: 45% !important;

        .inbox-message-container-inner {
            .message-body {
                padding: 1.25em !important;

                .message-preview {
                    padding: 20px !important;
                }
            }
        }
    }

    .inbox-center-container {
        left: 21% !important;

        .action-buttons {
            .is-grouped {
                &.is-arrows {
                    display: none !important;
                }
            }
        }
    }

    .reply-wrapper {
        padding: 20px 0 !important;

        .reply-wrapper-inner {
            padding: 10px !important;
        }
    }

    .action-buttons .button {
        margin-bottom: 4px !important;
    }
}
