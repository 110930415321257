/*! _special-mixins.scss | Friendkit | © Css Ninja. 2020-2021 */

/* ==========================================================================
Friendkit additional mixins
========================================================================== */

/*
    0. Multiline Ellipsis
*/

/* ==========================================================================
0. Multiline Ellipsis mixin
========================================================================== */

//Usage: @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 3, $bgColor: $light-grey);

@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
    overflow: hidden;
    position: relative;
    line-height: $lineHeight;
    max-height: $lineHeight * $lineCount;
    text-align: justify;
    margin-right: -1em;
    padding-right: 1em;

    &:before {
        content: '...';
        position: absolute;
        right: 0;
        bottom: 0;
    }

    &:after {
        content: '';
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        margin-top: 0.2em;
        background: $bgColor;
    }
}
