/*! _app-news.scss | Friendkit | © Css Ninja. 2020-2021 */

/* ==========================================================================
News / Blogging layout styles
========================================================================== */

/*
    0. News Header
    1. News Grid
    2. Media Queries
*/

/* ==========================================================================
0. News header
========================================================================== */

.news-header {
    position: relative;
    top: -2px;
    width: 100%;
    height: calc(100vh - 60px);

    .news-hero-wrapper {
        position: absolute;
        height: 100%;
        width: calc(100% - 360px);
        margin-right: 360px;

        &.is-primary {
            .overlay-layer {
                background: rgba($primary, 0.6);

                &:hover {
                    background: rgba($primary, 0.85);
                }
            }
        }

        &.is-accent {
            .overlay-layer {
                background: rgba($accent, 0.6);

                &:hover {
                    background: rgba($accent, 0.85);
                }
            }
        }

        &.is-blue {
            .overlay-layer {
                background: rgba($blue, 0.6);

                &:hover {
                    background: rgba($blue, 0.85);
                }
            }
        }

        &.is-green {
            .overlay-layer {
                background: rgba($green, 0.6);

                &:hover {
                    background: rgba($green, 0.85);
                }
            }
        }

        &.is-red {
            .overlay-layer {
                background: rgba($red, 0.6);

                &:hover {
                    background: rgba($red, 0.85);
                }
            }
        }

        .overlay-layer {
            position: relative;
            width: 100%;
            height: 100%;
            background: rgba($dark-text, 0.6);
            z-index: 1;
            transition: all .5s;

            &:hover {
                background: rgba($dark-text, 0.85);
            }

            .text-layer {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 2;
                display: flex;
                align-items: center;

                .hero-text {
                    padding: 0 60px;

                    .tag {
                        margin-bottom: 20px;
                        text-transform: uppercase;
                        height: 26px;
                        border-radius: 50px;
                        padding-left: 16px;
                        padding-right: 16px;
                        background: transparent;
                        border: 1.2px solid white;
                        color: white;
                        margin-right: 6px;
                    }

                    h2 {
                        font-family: Montserrat, sans-serif;
                        font-size: 3.5rem;
                        font-weight: 800;
                        max-width: 640px;
                        color: $white-smoke;
                        line-height: 1;
                    }

                    p {
                        font-family: Montserrat, sans-serif;
                        font-size: 1.3rem;
                        font-weight: 300;
                        max-width: 640px;
                        color: $white-smoke;
                        margin: 10px 0;
                    }

                    .cta-wrap {
                        padding: 10px 0;

                        .button {
                            font-size: 1rem;
                            height: 47px;
                            background: transparent;
                            color: $white;
                            border: 1.4px solid $white;
                            min-width: 140px;
                            margin-right: 6px;

                            &:hover {
                                background: $white;
                                color: $dark-text;
                            }
                        }
                    }
                }
            }
        }
    }

    .news-hero-menu {
        position: absolute;
        top: 0;
        right: 0;
        width: 360px;
        height: 100%;
        background: $white;

        .stories {
            position: relative;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: stretch;

            .story {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                height: 20%;
                border-right: 4px solid transparent;
                border-bottom: 1px solid $fade-grey;
                padding: 0 20px;

                &.is-active {
                    border-right-color: $dark-text;

                    &:before {
                        display: block;
                    }
                }

                &:before {
                    content: '';
                    display: none;
                    position: absolute;
                    top: 47%;
                    left: -21px;
                    height: 24px;
                    width: 24px;
                    background: $white;
                    z-index: 2;
                    transform: rotate(45deg) translateY(-50%);
                }

                &.is-primary {
                    &.is-active {
                        border-right-color: $primary;

                        .avatar-wrap {
                            border-color: $primary;
                        }
                    }
                }

                &.is-accent {
                    &.is-active {
                        border-right-color: $accent;

                        .avatar-wrap {
                            border-color: $accent;
                        }
                    }
                }

                &.is-blue {
                    &.is-active {
                        border-right-color: $blue;

                        .avatar-wrap {
                            border-color: $blue;
                        }
                    }
                }

                &.is-green {
                    &.is-active {
                        border-right-color: $green;

                        .avatar-wrap {
                            border-color: $green;
                        }
                    }
                }

                &.is-red {
                    &.is-active {
                        border-right-color: $red;

                        .avatar-wrap {
                            border-color: $red;
                        }
                    }
                }

                .avatar-wrap {
                    height: 48px;
                    width: 48px;
                    min-width: 48px;
                    border-radius: 50%;
                    border: 1.4px solid $placeholder;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: all .3s;

                    img {
                        display: block;
                        height: 36px;
                        width: 36px;
                        border-radius: 50%;
                    }
                }

                .meta {
                    margin-left: 20px;

                    span {
                        display: block;

                        &:first-child {
                            text-transform: uppercase;
                            font-size: .85rem;
                            font-weight: 500;
                            color: $muted-grey;
                        }

                        &:nth-child(2) {
                            font-size: .92rem;
                            font-family: Montserrat, sans-serif;
                            color: $dark-text;
                            font-weight: 600;
                        }

                        &:nth-child(3) {
                            font-size: .8rem;
                            font-family: Montserrat, sans-serif;
                            color: $muted-grey;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}

/* ==========================================================================
1. News Grid
========================================================================== */

.is-news {
    padding: 1rem;
}

.news-grid {
    display: flex;
    flex-wrap: wrap;

    .news-card {
        position: relative;
        background: $white;
        border: 1px solid darken($fade-grey, 4%);
        border-radius: 6px;
        margin: 10px;
        width: calc(25% - 20px);

        .category {
            border-radius: 100px;
            padding-left: 0.95em;
            padding-right: 0.95em;
            background: lighten($fade-grey, 3%);
            color: $dark-text;
        }

        &.is-default {
            display: block;
            flex-direction: column;

            &.is-inverted {
                background: darken($sidebar, 10%);

                .news-content {
                    .news-title {
                        font-weight: 600;
                        color: $white-smoke;
                    }

                    p {
                        font-weight: 400;
                        color: darken($white-smoke, 8%);
                    }

                    .button-wrap {

                        .button {
                            background: darken($sidebar, 16%);
                            border-color: darken($sidebar, 16%);
                            color: $white;

                            &:hover {
                                background: $primary;
                                border-color: $primary;
                            }
                        }

                        .actions {
                            .action {
                                &:hover {
                                    background: darken($sidebar, 4%);

                                    svg {
                                        stroke: $white-smoke;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &:hover {
                .avatar-wrap {
                    height: 44px;
                    width: 44px;
                    min-width: 44px;
                    border-color: $primary;
                }
            }

            .avatar-wrapper {
                position: absolute;
                top: 20px;
                right: 30px;
                height: 44px;
                width: 44px;
                min-width: 44px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .avatar-wrap {
                height: 36px;
                width: 36px;
                min-width: 36px;
                border-radius: 50%;
                border: 1.4px solid transparent;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all .3s;

                img {
                    display: block;
                    height: 32px;
                    width: 32px;
                    border-radius: 50%;
                    border: 1.6px solid $white-smoke;
                }
            }

            img {
                display: block;
                border-radius: 6px 6px 0 0;
                height: 160px;
                width: 100%;
                object-fit: cover;
            }

            .category {
                position: absolute;
                top: 30px;
                left: 30px;
            }

            .news-content {
                padding: 20px 30px 30px 30px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: calc(100% - 160px);

                .news-title {
                    font-family: Montserrat, sans-serif;
                    font-weight: 700;
                    color: $dark-text;
                    font-size: 1rem;
                    margin: 0 0 10px 0;
                }

                p {
                    margin-top: auto;
                    color: $muted-grey;
                    font-size: .9rem;
                }

                .button-wrap {
                    margin-top: 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;

                    .button {
                        &:hover {
                            background: $primary !important;
                            border-color: $primary !important;
                            color: $white !important;
                        }
                    }

                    .actions {
                        height: 38px;
                        display: flex;
                        align-items: center;

                        .action {
                            height: 38px;
                            width: 38px;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            &:hover {
                                background: lighten($fade-grey, 5%);

                                svg {
                                    stroke: $dark-text;
                                }
                            }

                            &.is-like {
                                &.is-active {
                                    svg {
                                        stroke: $red !important;
                                        fill: $red !important;
                                    }
                                }
                            }

                            svg {
                                height: 18px;
                                width: 18px;
                                stroke: $muted-grey;
                            }
                        }
                    }
                }
            }
        }

        &.is-wide {
            display: flex;
            align-items: center;
            padding: 30px;
            width: calc(50% - 20px);

            &.has-absolute-image {
                img {
                    display: block;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    max-height: 100%;
                    max-width: 45%;
                    object-fit: contain;
                }

                .news-content {
                    display: flex;
                    flex-direction: column;
                    max-width: 55%;
                    height: 100%;

                    .category {
                        position: absolute;
                    }

                    .news-title {
                        font-family: Montserrat, sans-serif;
                        font-weight: bold;
                        color: $dark-text;
                        font-size: 1.3rem;
                        margin: 26px 0 10px 0;
                    }

                    p {
                        margin-top: auto;
                        color: $muted-grey;
                        font-size: .9rem;

                        + p {
                            margin-top: 10px;
                        }
                    }

                    .button-wrap {
                        margin-top: 20px;
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;

                        .button {
                            &:hover {
                                background: $primary !important;
                                border-color: $primary !important;
                                color: $white !important;
                            }
                        }

                        .actions {
                            height: 38px;
                            display: flex;
                            align-items: center;

                            .action {
                                height: 38px;
                                width: 38px;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                transition: all .3s;

                                &:hover {
                                    background: lighten($fade-grey, 5%);

                                    svg {
                                        stroke: $dark-text;
                                    }
                                }

                                &.is-like {
                                    &.is-active {
                                        svg {
                                            stroke: $red !important;
                                            fill: $red !important;
                                        }
                                    }
                                }

                                svg {
                                    height: 18px;
                                    width: 18px;
                                    stroke: $muted-grey;
                                    transition: all .3s;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ==========================================================================
2. Media Queries
========================================================================== */

//Mobile
@media (max-width: 767px) {
    .news-header {
        .news-hero-wrapper {
            width: 100%;
            margin: 0;

            .overlay-layer {
                .text-layer {
                    .hero-text {
                        padding: 0 30px;

                        h2 {
                            font-size: 2rem;
                        }
                    }
                }
            }
        }

        .news-hero-menu {
            display: none;
        }
    }

    .news-grid {
        .news-card {
            &.is-default {
                width: calc(100% - 20px);
            }

            &.is-wide {
                width: calc(100% - 20px);

                &.has-absolute-image {
                    img {
                        display: none;
                    }

                    .news-content {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

//Tablet portrait
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .news-header {
        .news-hero-wrapper {
            width: 100%;
            margin-right: 0;

            .overlay-layer {
                .text-layer {
                    .hero-text {
                        padding: 0 40px;

                        h2 {
                            font-size: 3.4rem;
                        }
                    }
                }
            }
        }

        .news-hero-menu {
            display: none;
        }
    }

    .news-grid {
        .news-card {
            &.is-default {
                width: calc(50% - 20px);
            }

            &.is-wide {
                width: calc(50% - 20px);

                &.has-absolute-image {
                    img {
                        display: none;
                    }

                    .news-content {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

//Tablet landscape
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .news-header {
        .news-hero-wrapper {
            width: calc(100% - 280px);
            margin-right: 280px;

            .overlay-layer {
                .text-layer {
                    .hero-text {
                        padding: 0 40px;

                        h2 {
                            font-size: 3.4rem;
                        }
                    }
                }
            }
        }

        .news-hero-menu {
            width: 280px;

            .stories {
                .story {
                    .avatar-wrap {
                        height: 40px;
                        width: 40px;
                        min-width: 40px;

                        img {
                            height: 28px;
                            width: 28px;
                        }
                    }

                    .meta {
                        margin-left: 10px;

                        span {
                            &:first-child {
                                font-size: .75rem;
                            }

                            &:nth-child(2) {
                                font-size: .8rem;
                            }

                            &:nth-child(3) {
                                font-size: .7rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .news-grid {
        .news-card {
            &.is-default {
                width: calc(33.3% - 20px);
            }

            &.is-wide {
                width: calc(66.6% - 20px);

                &.has-absolute-image {
                    img {
                        max-height: 78%;
                    }

                    .news-content {
                        max-width: 60%;
                    }
                }
            }
        }
    }
}
