/*! _switch.scss | Friendkit | © Css Ninja. 2020-2021 */

/* ==========================================================================
Switches and toggles styles
========================================================================== */

/*
    1. Animated toggle
    2. Switch
*/

/* ==========================================================================
1. Animated toggle
========================================================================== */

.animated-toggle {
    width: 56px;
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked ~ .toggler {
            border-color: $accent;

            .dark, .light {
                transform: translateX(100%) rotate(360deg);
            }

            .dark {
                opacity: 1;
            }

            .light {
                opacity: 0;
            }
        }
    }

    .toggler {
        position: relative;
        display: block;
        height: 31px;
        width: 53px;
        border: 2px solid $primary;
        border-radius: 100px;
        transition: all .3s;

        .dark, .light {
            position: absolute;
            top: 2px;
            left: 2px;
            height: 22px;
            width: 22px;
            border-radius: 50%;
            background: black;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translateX(0) rotate(0);
            transition: all .3s ease;

            svg {
                stroke: $white;
                height: 14px !important;
                width: 14px !important;
            }
        }

        .light {
            background: $primary;
            border-color: $primary;
            opacity: 1;
            z-index: 1;
        }

        .dark {
            background: $accent;
            border-color: $accent;
            opacity: 0;
            z-index: 0;
        }
    }
}

/* ==========================================================================
2. Switch
========================================================================== */

.f-switch {
    position: relative;
    display: inline-block;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    &.is-primary {
        input {
            &:checked + i {
                background-color: $primary;
            }
        }
    }

    &.is-accent {
        input {
            &:checked + i {
                background-color: $accent;
            }
        }
    }

    &.is-red {
        input {
            &:checked + i {
                background-color: $red;
            }
        }
    }

    &.is-blue {
        input {
            &:checked + i {
                background-color: $blue;
            }
        }
    }

    i {
        position: relative;
        display: inline-block;
        margin-right: .5rem;
        width: 46px;
        height: 26px;
        background-color: #e6e6e6;
        border-radius: 23px;
        vertical-align: text-bottom;
        transition: all 0.3s linear;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            width: 42px;
            height: 22px;
            background-color: $white;
            border-radius: 11px;
            transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
            transition: all 0.25s linear;
        }

        &::after {
            content: "";
            position: absolute;
            left: 0;
            width: 22px;
            height: 22px;
            background-color: $white;
            border-radius: 11px;
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
            transform: translate3d(2px, 2px, 0);
            transition: all 0.2s ease-in-out;
        }
    }

    &:active {
        i::after {
            width: 28px;
            transform: translate3d(2px, 2px, 0);
        }

        input {
            &:checked + i::after {
                transform: translate3d(16px, 2px, 0);
            }
        }
    }

    input {
        position: absolute;
        opacity: 0;
        pointer-events: none;

        &:checked + i {
            background-color: $green;

            &::before {
                transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
            }

            &::after {
                transform: translate3d(22px, 2px, 0);
            }
        }
    }

    small {
        color: $muted-grey;
        position: relative;
        top: -4px;
    }
}


/* ==========================================================================
3. Theme Toggle
========================================================================== */

.theme-toggle {
    width: 54px;
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
    transform: scale(0.9);

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked ~ .toggler {
            border-color: $accent;

            .dark, .light {
                transform: translateX(98%) rotate(360deg);
            }

            .dark {
                opacity: 1 !important;
            }

            .light {
                opacity: 0 !important;
            }
        }
    }

    .toggler {
        position: relative;
        display: block;
        height: 31px;
        width: 53px;
        border: 1px solid darken($fade-grey, 3%);
        background: $white;
        box-shadow: $light-box-shadow;
        border-radius: 100px;
        transition: all .3s;

        .dark, .light {
            position: absolute;
            top: 2px;
            left: 2px;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            background: black;
            display: flex !important;
            justify-content: center;
            align-items: center;
            transform: translateX(0) rotate(0);
            transition: all .3s ease;

            svg {
                stroke:  #edc31c !important;
                height: 14px !important;
                width: 14px !important;
                opacity: 1 !important;
            }
        }

        .light {
            background: $white;
            border-color: darken($fade-grey, 4%);
            opacity: 1;
            z-index: 1;
        }

        .dark {
            background: $accent;
            border-color: $accent;
            opacity: 0;
            z-index: 0;

            svg {
                stroke: $white !important;
            }
        }
    }
}