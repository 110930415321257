/*! _app-profile.scss | Friendkit | © Css Ninja. 2020-2021 */

/* ==========================================================================
Profile styles
========================================================================== */

/*
    1. Profile Layout
    2. Profile Menu
    3. Box Headers
    4. Profile timeline
    5. Profile about
    6. User Profile about
    7. Page Profile about
    8. Page Community widgets
    9. Photo Library
    10. Custom Lightbox
    11. Profile friends
    12. Media Queries
*/

/* ==========================================================================
1. Profile Layout
========================================================================== */

.cover-bg {
    position: relative;

    &:hover {
        .cover-overlay {
            background: rgba($dark-text, 0.5);
        }

        .cover-edit {
            border-color: $white-smoke;
            background: rgba($white-smoke, 0.1);

            i {
                transform: scale(1);
            }

            span {
                opacity: 1;
            }
        }
    }

    .cover-image {
        display: block;
        border-radius: 3px;
        object-position: top;
        object-fit: cover;
        width: 100%;
        max-height: 328px;
    }

    .avatar {
        position: absolute;
        bottom: -50px;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 110px;
        width: 110px;
        border-radius: 50%;
        z-index: 1;

        .avatar-image {
            position: relative;
            object-fit: cover;
            height: 110px;
            width: 110px;
            border-radius: 50%;
            box-shadow: 0px 15px 32px rgba(0, 0, 0, 0.18) !important;
            z-index: 1;
        }

        .avatar-button {
            position: absolute;
            bottom: 0;
            right: 0;
            height: 34px;
            width: 34px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $primary;
            box-shadow: $primary-box-shadow;
            transform: rotate(0);
            transition: all .3s;
            cursor: pointer;
            z-index: 1;

            &:hover {
                background: lighten($primary, 6%);
            }

            &.is-active {
                transform: rotate(135deg);
            }

            svg {
                height: 18px;
                width: 18px;
                stroke: $white-smoke;
            }
        }

        .pop-button {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            margin: 0 auto;
            height: 34px;
            width: 34px;
            border-radius: 50%;
            background: $white;
            box-shadow: $accent-box-shadow;
            z-index: 0;
            transform: translate(0, 10px) rotate(260deg);
            transition: all .3s;
            cursor: pointer;

            .inner {
                position: relative;
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &.pop-shift {
                .inactive-icon {
                    display: block;
                }

                .active-icon {
                    display: none;
                }

                &.is-shifted {
                    .inactive-icon {
                        display: none;
                    }

                    .active-icon {
                        display: block;
                    }
                }
            }

            &.is-center, &.is-far-right, &.is-left, &.is-far-left {
                background: $white;
                box-shadow: $light-box-shadow;

                &:hover {
                    background: $accent;
                    box-shadow: $accent-box-shadow;

                    svg {
                        stroke: $white-light;
                    }
                }

                svg {
                    stroke: $medium-text;
                }
            }

            &.is-right {
                background: $primary;
                box-shadow: $primary-box-shadow;

                svg {
                    stroke: $white-light;
                }
            }

            &.is-active {
                &.is-far-left {
                    transform: translate(-240%, 30%) rotate(0);
                }

                &.is-left {
                    transform: translate(-160%, -110%) rotate(0);
                }

                &.is-center {
                    transform: translate(0, -170%) rotate(0);
                }

                &.is-right {
                    transform: translate(160%, -110%) rotate(0);
                }

                &.is-far-right {
                    transform: translate(240%, 30%) rotate(0);
                }
            }

            svg {
                height: 18px;
                width: 18px;
                stroke: $medium-text;
                transition: all .3s;
            }
        }
    }

    .cover-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 1px;
        background: rgba($dark-text, 0.18);
        z-index: 0;
        transition: all 0.3s;
    }

    .cover-edit {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        margin: 10px;
        padding: 2px 8px;
        border: 1.4px solid transparent;
        border-radius: 3px;
        background: transparent;
        cursor: pointer;
        transition: all .3s;

        i {
            font-size: 20px;
            color: $white-smoke;
            transition: all .3s;
            transform: scale(1.2);
        }

        span {
            opacity: 0;
            display: block;
            color: $white-smoke;
            font-weight: 400;
            font-size: .85rem;
            margin: 0 10px;
            transition: all .3s;
        }
    }

    .timeline-mobile-dropdown {
        position: absolute;
        top: 10px;
        right: 10px;

        div > .button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 38px;
            height: 38px;
            background: transparent;
            border-radius: 50%;
            border: 1.4px solid $white;
            padding: 0;

            svg {
                height: 20px;
                width: 20px;
                stroke: $white;
            }
        }
    }
}

/* ==========================================================================
2. Profile Menu
========================================================================== */

.profile-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0 0 0;

    .button {
        font-size: .9rem;
        padding: 20px 22px;
        background: $white-smoke;
        color: $muted-grey;

        &.is-active {
            background: $accent;
            border-color: $accent;
            color: $white-smoke;
            box-shadow: $accent-box-shadow;
        }

        &.has-min-width {
            width: 140px;
        }
    }

    .dropdown {
        > .button {
            svg {
                height: 20px;
                width: 20px;
            }
        }
    }
}

.profile-subheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    .subheader-start, .subheader-end {
        width: 25%;
    }

    .subheader-start {
        svg {
            height: 18px;
            width: 18px;
            stroke: $accent;
            display: none;
        }

        span {
            display: block;
            font-weight: 500;

            &:first-child {
                font-size: 1.6rem;
                font-family: 'Montserrat', sans-serif;
                font-weight: bold;
                color: $dark-text;
                line-height: 1.2;
            }

            &:nth-child(2) {
                text-transform: uppercase;
                font-size: .7rem;
                font-weight: 500;
                color: $muted-grey;
            }
        }
    }

    .subheader-end {
        text-align: right;
    }

    .subheader-middle {
        text-align: center;

        h2 {
            font-size: 1.4rem;
            font-weight: 600;
            color: $dark-text;
            line-height: 1.2;
        }

        span {
            display: block;
            font-size: .9rem;
            color: $muted-grey;
        }
    }
}

/* ==========================================================================
3. Box Headers
========================================================================== */

.box-heading {
    width: 100%;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid $fade-grey;
    background: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
        font-weight: 500;
        padding: 0 6px;
    }

    .heading-search {
        position: relative;
        margin-left: 10px;

        input {
            padding-left: 38px;

            &:focus {
                + .search-icon {
                    svg {
                        stroke: $accent;
                    }
                }
            }
        }

        .search-icon {
            position: absolute;
            top: 0;
            left: 2px;
            height: 36px;
            width: 36px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                height: 16px;
                width: 16px;
                stroke: $placeholder;
                transition: stroke .3s;
            }
        }
    }

    .dropdown {
        &.photos-dropdown, &.friends-dropdown {
            &.is-active {
                div > .button {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }

            div > .button {
                width: auto !important;
                padding: 0 8px 0 12px;
                height: 34px;
                min-width: 110px;
                background: lighten($fade-grey, 5%);
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 100px;

                svg {
                    height: 16px;
                    width: 16px;
                    stroke: $dark-text;
                    transition: all .3s;
                }
            }

            .dropdown-menu {
                margin-top: 6px;
            }
        }

        .button {
            width: 38px !important;
            height: 38px;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border-color: transparent;

            svg {
                height: 20px;
                width: 20px;
                stroke: $grey-text;
            }
        }
    }

    .button-wrap {
        .button {
            line-height: 0;
            width: 90px;
            font-weight: 500;

            &.is-active {
                color: $accent;
                border-color: $accent;
            }
        }
    }
}

/* ==========================================================================
4. Profile timeline
========================================================================== */

.profile-timeline {
    padding: 10px 0;

    .profile-post {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        margin-bottom: 30px;

        &.is-simple {
            .post-actions {
                flex-direction: row-reverse !important;
            }
        }

        .time {
            padding-right: 30px;
            position: relative;

            .img-container {
                height: 48px;
                width: 48px;
                border-radius: 50%;
                background: $white;
                display: flex;
                justify-content: center;
                align-items: center;

                &:after {
                    content: '';
                    position: absolute;
                    top: 60px;
                    left: 24px;
                    width: 1.4px;
                    height: calc(100% - 30px);
                    background: darken($fade-grey, 1%);
                }

                img {
                    display: block;
                    height: 44px;
                    width: 44px;
                    min-width: 44px;
                    border-radius: 50%;
                }
            }
        }

        .is-post {
            margin-bottom: 0;
            width: 100%;
        }
    }
}

.basic-infos-wrapper {
    padding-top: 10px;

    .is-profile-info {
        .info-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 16px;

            &:not(:last-child) {
                border-bottom: 1px solid $fade-grey;
            }

            span, a {
                display: block;
            }

            span {
                font-size: .85rem;
                font-weight: 500;
                color: $dark-text;
            }

            a {
                font-size: .9rem;
                font-weight: 400;
                color: $muted-grey;
            }

            i {
                font-size: 20px;
                color: $placeholder;
                margin: 0 4px;
            }
        }
    }

    .is-community, .is-about {
        padding: 16px;

        h4 {
            margin-bottom: 16px;
            color: $muted-grey;
        }

        .flex-block {
            display: flex;
            align-items: center;
            margin: 16px 0;

            a {
                color: $dark-text;
                font-weight: 500;

                &:hover {
                    color: $accent;
                }
            }

            p {
                font-size: .8rem;
                margin-left: 12px;
            }

            svg {
                height: 16px;
                width: 16px;
            }
        }
    }

    .is-friendkit {
        padding: 16px;

        .title-wrap {
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            img {
                display: block;
                max-width: 30px;
            }

            h4 {
                color: $muted-grey;
            }
        }

        p {
            font-size: .8rem;
        }

        .created {
            display: flex;
            align-items: center;
            margin-top: 16px;

            svg {
                height: 16px;
                width: 16px;
                stroke: $muted-grey;
                margin-right: 8px;
            }

            span {
                display: block;
                color: $muted-grey;
                font-size: .8rem;
            }
        }
    }
}

.is-photos-widget {
    padding: 10px 0 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    img {
        display: block;
        width: calc(25% - 6px);
        margin-bottom: 12px;
        object-fit: cover;
        border-radius: 4px;
        transition: all .3s;

        &:hover {
            box-shadow: 0px 15px 32px rgba(0, 0, 0, 0.18) !important;
        }
    }
}

.is-videos-widget {
    padding: 10px 0 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .video-container {
        position: relative;
        display: block;
        width: calc(33% - 6px);
        min-height: 60px;
        margin-bottom: 12px;
        border-radius: 4px;
        transition: all .3s;

        &:hover {
            img {
                box-shadow: 0px 15px 32px rgba(0, 0, 0, 0.18) !important;
            }
        }

        img {
            display: block;
            object-fit: cover;
            border-radius: 4px;
            width: 100%;
            height: 100%;
            transition: all .3s;
        }

        .video-overlay {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border-radius: 6px;
            background: rgba($sidebar, 0.6);
        }

        .video-button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 34px;
            width: 34px;
            background: $primary;
            border-radius: 50%;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 2;
            cursor: pointer;

            img {
                position: relative;
                display: block;
                width: 16px;
                height: 16px;
                left: 1px;
                z-index: 3;
            }

            &:after {
                content: '';
                position: absolute;
                top: calc(50% - 12px);
                left: calc(50% - 12px);
                height: 24px;
                width: 24px;
                border-radius: 50%;
                background: $primary;
                animation: wave 1.6s infinite;
                animation-duration: 2s;
                transform-origin: center center;
                z-index: 0;
            }
        }
    }
}

.friend-cards-list {
    padding-top: 10px;

    .is-friend-card {
        .friend-item {
            padding: 12px;
            display: flex;
            align-items: center;

            &:not(:last-child) {
                border-bottom: 1px solid $fade-grey;
            }

            img {
                display: block;
                height: 44px;
                width: 44px;
                border-radius: 50%;
            }

            .text-content {
                margin: 0 10px;

                span, a {
                    display: block;
                }

                a {
                    &:first-child {
                        font-weight: 500;
                        font-size: .9rem;
                        color: $dark-text;
                    }

                    &:nth-child(3) {
                        font-size: .8rem;
                        font-weight: 500;
                        color: $accent;
                    }
                }

                span {
                    font-size: .75rem;
                    color: $muted-grey;
                }
            }

            .star-friend {
                height: 44px;
                width: 44px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                cursor: pointer;

                &.is-active {
                    svg {
                        transform: rotate(360deg);
                        stroke: $primary;
                        fill: $primary;
                    }
                }

                svg {
                    height: 20px;
                    width: 20px;
                    stroke: $muted-grey;
                    transition: all .3s;
                    transform: rotate(0);
                }
            }
        }
    }
}

.trip-cards-list {
    padding: 10px 0 0 0;

    .is-trip-card {
        .trip-item {
            padding: 12px;
            display: flex;
            align-items: center;

            &:not(:last-child) {
                border-bottom: 1px solid $fade-grey;
            }

            img {
                display: block;
                height: 42px;
                width: 42px;
                border-radius: 50%;
            }

            .text-content {
                margin: 0 10px;

                span, a {
                    display: block;
                }

                a {
                    &:first-child {
                        font-weight: 500;
                        font-size: .9rem;
                        color: $dark-text;
                    }

                    &:nth-child(3) {
                        font-size: .8rem;
                        font-weight: 500;
                        color: $accent;
                    }
                }

                span {
                    font-size: .85rem;
                    color: $muted-grey;
                }
            }
        }
    }
}

/* ==========================================================================
5. Profile about
========================================================================== */

.profile-about {
    width: 100%;
    border-radius: 6px;
    display: flex;

    .left-menu {
        min-width: 260px;

        .left-menu-inner {
            width: 100%;
            border-radius: 6px 0 0 6px;
        }

        .menu-item {
            display: flex;
            align-items: center;
            margin-bottom: 6px;
            transition: all .3s;
            cursor: pointer;

            &.is-active {
                .menu-icon {
                    background: $accent;
                    box-shadow: $accent-box-shadow;

                    i, span {
                        color: $white-smoke;
                    }
                }
            }

            .menu-icon {
                display: flex;
                align-items: center;
                height: 42px;
                width: 100%;
                padding: 0 12px;
                border-radius: 6px;
                background: transparent;
                transition: all .3s;
            }

            i {
                font-size: 1.4rem;
                color: $placeholder;
                transition: all .3s;
            }

            span {
                margin: 0 8px;
                display: block;
                font-size: .9rem;
                font-weight: 500;
                color: $placeholder;
                transition: color .3s;
            }
        }
    }

    .right-content {
        padding-left: 30px;
        width: calc(100% - 260px);
        min-height: 420px;

        .content-section {
            display: none;
            animation: fadeInLeft .5s;

            &.is-active {
                display: block;
            }

            .flex-block {
                display: flex;
                align-items: center;
                transition: all .3s;
                padding: 12px;
                margin-bottom: 10px;
                border-radius: 6px;
                cursor: pointer;
                border: 1px solid $fade-grey;
                background: $white;

                &:hover {
                    .flex-block-meta {
                        .action-link {
                            opacity: 1;
                            pointer-events: all;

                            &:hover {
                                color: $medium-text;
                            }
                        }
                    }

                    .go-button {
                        transform: scale(1) rotate(360deg);
                        opacity: 1;
                        box-shadow: $accent-box-shadow;

                        &:hover {
                            opacity: .9;
                        }
                    }
                }

                img {
                    height: 42px;
                    width: 42px;
                    border-radius: 50%;
                    object-fit: cover;
                }

                .flex-block-meta {
                    margin-left: 10px;

                    span, .action-link {
                        display: block;
                    }

                    span {
                        color: $muted-grey;
                        font-size: .9rem;

                        a {
                            color: $dark-text;
                            font-size: .85rem;
                            font-weight: 500;
                        }
                    }

                    .action-link {
                        font-size: .75rem;
                        color: $placeholder;
                        pointer-events: none;
                        transition: all .3s;
                    }
                }

                .go-button {
                    margin-left: auto;
                    height: 42px;
                    width: 42px;
                    border-radius: 50%;
                    background: $accent;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: all .3s;
                    transform: scale(0) rotate(0);
                    opacity: 0;

                    svg {
                        height: 18px;
                        width: 18px;
                        stroke: $white-smoke;
                    }
                }
            }
        }
    }

    .about-summary {
        transition: all .3s;
        padding: 30px;
        margin-bottom: 10px;
        border-radius: 6px;
        cursor: pointer;
        border: 1px solid $fade-grey;
        background: $white;

        h3 {
            font-family: 'Montserrat', sans-serif;
            font-size: 1.1rem;
        }

        p {
            font-size: .95rem;
            color: $medium-text;
        }
    }

    .glider-dots {
        padding: 20px 0;

        .glider-dot {
            width: 8px;
            height: 8px;
            margin: 4px;

            &.active {
                background: $accent !important;
            }
        }
    }

    .slider-timeline {
        position: relative;

        .timeline-item {
            position: relative;
            padding: 20px;
            background: $white;
            border-radius: 6px;
            border: 1px solid darken($fade-grey, 4%);
            margin-right: 1rem;

            .image-container {
                position: relative;

                > img {
                    display: block;
                    min-height: 160px;
                    object-fit: cover;
                    border-radius: 2px;
                }

                .logo-container {
                    position: absolute;
                    bottom: -30px;
                    right: 10px;
                    height: 50px;
                    width: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: $white;
                    border-radius: 50%;

                    img {
                        display: block;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                    }
                }
            }

            h3 {
                margin-top: 12px;
                font-size: .9rem;
                font-family: 'Montserrat', sans-serif;
                font-weight: 500;
            }

            > p {
                font-size: .8rem;
                color: $muted-grey;
            }

            .more {
                margin-top: 6px;

                p {
                    font-size: .9rem;
                    color: lighten($dark-text, 15%);
                }
            }

            .date {
                margin-top: 6px;
                font-size: .8rem;
                color: $muted-grey;
            }
        }
    }
}

.friends-columns {
    .column {
        padding: .5rem;
    }
}

/* ==========================================================================
6. User Profile about
========================================================================== */

.about-card {
    width: 100%;
    border-radius: 6px;
    border: 1px solid darken($fade-grey, 4%);
    background: $white;

    &:not(:last-child) {
        margin-bottom: 30px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;

        .icon-title {
            display: flex;
            align-items: center;

            i {
                font-size: 22px;
                color: $placeholder;
            }

            h3 {
                color: $dark-text;
                font-weight: 500;
                font-size: 1.1rem;
                margin-left: 8px;
            }
        }

        .actions {
            display: flex;
            align-items: center;

            .button-wrapper {
                position: relative;

                .indicator {
                    position: absolute;
                    top: -8px;
                    left: -8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 24px;
                    min-width: 24px;
                    padding: 0 4px;
                    background: $accent;
                    border: 2px solid $white;
                    border-radius: 50%;

                    span {
                        display: block;
                        font-size: .8rem;
                        font-weight: 600;
                        color: $white-smoke;
                    }
                }
            }

            .button:first-child {
                margin-right: 8px;
            }
        }

        .dropdown {
            div > .button {
                margin-right: 0 !important;
            }
        }
    }

    .body {
        padding: 0 20px 20px 20px;

        &.has-flex-list {
            padding-left: calc(20px - .5rem);
            padding-right: calc(20px - .5rem);
            padding-bottom: calc(20px - .5rem);
        }

        .friend-small-card {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 12px;
            border-radius: 6px;
            border: 1px solid darken($fade-grey, 4%);
            background: $white;
            transition: all .3s;

            &:hover {
                box-shadow: $light-box-shadow;

                .dropdown {
                    opacity: 1;
                    pointer-events: all;
                }
            }

            img {
                height: 40px;
                width: 40px;
                border-radius: 50%;
            }

            .meta {
                margin-left: 12px;

                span {
                    display: block;

                    &:first-child {
                        font-size: .9rem;
                        font-weight: 500;
                        color: $dark-text;
                    }

                    &:nth-child(2) {
                        font-size: .9rem;
                        color: $muted-grey;
                    }
                }
            }

            .dropdown {
                margin-left: auto;
                opacity: 0;
                pointer-events: none;
                transition: opacity .3s;

                > div .button {
                    padding: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 32px;
                    width: 32px;
                    border: none;
                    border-radius: 50%;
                    transition: all .3s;

                    &:hover {
                        background: lighten($fade-grey, 3%);
                    }

                    svg {
                        height: 20px;
                        width: 20px;
                    }
                }
            }
        }

        .photo-list, .video-list {
            .photo-wrapper, .video-wrapper {
                &:hover {
                    .photo-overlay, .video-overlay {
                        opacity: .5;
                    }

                    .small-like {
                        transform: translateY(0);
                        opacity: 1;
                        pointer-events: all;
                    }
                }

                .photo-overlay, .video-overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    border-radius: 4px;
                    background: $dark-text;
                    opacity: 0;
                    transition: opacity .3s;
                }

                .small-like {
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                    z-index: 5;
                    transition: all .3s;
                    transform: translateY(20px);
                    opacity: 0;
                    pointer-events: none;
                    cursor: pointer;

                    &.is-active {
                        .inner {
                            .like-overlay {
                                transform: scale(1);
                            }

                            svg {
                                stroke: $white;
                                fill: $white;
                            }
                        }
                    }

                    .inner {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 36px;
                        width: 36px;
                        background: $white;
                        border-radius: 50%;

                        .like-overlay {
                            position: absolute;
                            top: 0;
                            left: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 36px;
                            width: 36px;
                            background: $red;
                            border-radius: 50%;
                            transform: scale(0);
                            transition: all .3s;
                        }

                        svg {
                            position: relative;
                            height: 14px;
                            width: 14px;
                            z-index: 1;
                            transition: all .3s;
                        }
                    }
                }
            }
        }

        .photo-list {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            margin-top: -.5rem;

            .photo-wrapper {
                position: relative;
                width: calc(20% - 1rem);
                margin: .5rem;
                overflow: hidden;

                img {
                    display: block;
                    object-fit: cover;
                    width: 100%;
                    border-radius: 4px;
                }
            }
        }

        .video-list {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            margin-top: -.5rem;

            .video-wrapper {
                position: relative;
                width: calc(25% - 1rem);
                margin: .5rem;
                overflow: hidden;

                img {
                    display: block;
                    object-fit: cover;
                    width: 100%;
                    border-radius: 4px;
                }

                .video-overlay {
                    opacity: .5 !important;
                }

                .video-length {
                    position: absolute;
                    bottom: 10px;
                    left: 10px;
                    color: $white-smoke;
                    font-weight: 500;
                    font-size: .8rem;
                }

                .video-button {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    height: 34px;
                    width: 34px;
                    background: $accent;
                    border-radius: 50%;
                    margin: 0 auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 2;
                    cursor: pointer;

                    img {
                        position: relative;
                        display: block;
                        width: 16px;
                        height: 16px;
                        left: 1px;
                        z-index: 3;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        top: calc(50% - 12px);
                        left: calc(50% - 12px);
                        height: 24px;
                        width: 24px;
                        border-radius: 50%;
                        background: $accent;
                        animation: wave 1.6s infinite;
                        animation-duration: 2s;
                        transform-origin: center center;
                        z-index: 0;
                    }
                }
            }
        }

        .place-list {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            margin-top: -.5rem;

            .place-wrapper {
                position: relative;
                width: calc(33% - 1rem);
                margin: .5rem;
                overflow: hidden;
                padding: 12px;
                background: $white;
                border: 1px solid darken($fade-grey, 4%);
                border-radius: 4px;
                transition: all .3s;

                &:hover {
                    box-shadow: $light-box-shadow;
                }

                img {
                    display: block;
                    object-fit: cover;
                    width: 100%;
                    border-radius: 4px;
                }

                .foot {
                    .place-name {
                        display: block;
                        margin-top: 4px;
                        font-size: .9rem;
                        font-weight: 500;
                        color: $dark-text;
                        transition: color .3s;

                        &:hover {
                            color: $accent;
                        }
                    }

                    .rating {
                        line-height: 1;

                        svg {
                            height: 14px;
                            width: 14px;
                            stroke: $placeholder;
                            fill: $placeholder;
                            transition: all .3s;

                            &.is-checked {
                                stroke: $primary;
                                fill: $primary;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ==========================================================================
7. Page Profile about
========================================================================== */

.page-about-card {
    padding: 20px;

    .card-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        h4 {
            font-family: 'Montserrat', sans-serif;
            color: $dark-text;
            font-size: .9rem;
        }
    }

    .about-body {
        .about-block {
            .block-header {
                border-bottom: 1px solid $fade-grey;
                padding: 10px 0;

                h4 {
                    text-transform: uppercase;
                    font-size: .75rem;
                    font-weight: 500;
                    color: $muted-grey;
                }
            }

            .block-content {
                padding: 10px 0;

                .flex-inner {
                    display: flex;
                    align-items: center;
                    margin: 10px 0;

                    &.has-meta {
                        align-items: flex-start;
                        margin: 16px 0;

                        .meta {
                            margin-left: 12px;

                            span {
                                display: block;
                                padding: 0;

                                &:first-child {
                                    font-weight: 500;
                                    color: $dark-text;
                                }

                                &:nth-child(2) {
                                    color: $muted-grey;
                                    font-size: .85rem;
                                }
                            }
                        }
                    }

                    .mdi {
                        font-size: 24px;
                        color: $muted-grey;
                    }

                    svg {
                        height: 16px;
                        width: 16px;
                        stroke: $muted-grey;
                    }

                    span {
                        display: block;
                        font-size: .9rem;
                        padding: 0 10px;

                        a {
                            color: $muted-grey;

                            &:hover {
                                color: $accent;
                            }
                        }
                    }
                }

                .history-block {
                    display: flex;
                    margin-bottom: 10px;

                    .date {
                        font-weight: 500;
                        font-size: .9rem;
                        color: $dark-text;
                        margin-left: 8px;
                    }

                    .timeline {
                        ul {
                            list-style-type: disc;
                            padding-left: 30px;

                            li {
                                font-size: .85rem;
                                color: $muted-grey;
                                margin-bottom: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ==========================================================================
8. Page community widgets
========================================================================== */

.community-content {
    display: flex;
    justify-content: space-between;

    .community-info {
        width: 59%;

        .page-followers {
            background: $white;
            padding: 16px;
            border-radius: 8px;
            border: 1px solid darken($fade-grey, 4%);

            .header {
                margin-bottom: 20px;

                h3 {
                    font-family: 'Montserrat', sans-serif;
                    color: $dark-text;
                    font-size: .9rem;
                    font-weight: 600;
                }
            }

            .followers {
                display: flex;
                flex-wrap: wrap;

                .follower {
                    width: calc(20% - 16px);
                    margin: 8px;

                    &:hover {
                        img {
                            filter: grayscale(0);
                            opacity: 1;
                        }
                    }

                    img {
                        display: block;
                        border-radius: 50%;
                        filter: grayscale(4);
                        opacity: .8;
                        transition: all .3s;
                    }
                }
            }

            .more-followers {
                padding: 30px 0 10px 0;
                text-align: center;

                a {
                    padding: 8px 24px;
                    border-radius: 100px;
                    background: lighten($fade-grey, 6%);
                    color: $muted-grey;
                    font-size: .9rem;
                    transition: all .3s;

                    &:hover {
                        background: lighten($fade-grey, 2%);
                        color: $dark-text;
                    }
                }
            }
        }
    }

    .community-widgets {
        width: 39%;

        .page-counts {
            background: $white;
            padding: 16px;
            border-radius: 8px;
            border: 1px solid darken($fade-grey, 4%);
            display: flex;
            margin-bottom: 20px;

            .like-count, .follow-count {
                margin: 10px 0;
                width: 50%;

                &:first-child {
                    border-right: 1px solid $fade-grey;
                }

                span {
                    display: block;
                    text-align: center;

                    &:first-child {
                        font-family: 'Montserrat', sans-serif;
                        font-weight: bold;
                        color: $dark-text;
                        font-size: 1.4rem;
                    }

                    &:nth-child(2) {
                        text-transform: uppercase;
                        font-size: .8rem;
                        color: $muted-grey;
                    }
                }
            }
        }

        .page-invites {
            background: $white;
            padding: 16px;
            border-radius: 8px;
            border: 1px solid darken($fade-grey, 4%);

            .header {
                h3 {
                    font-size: .9rem;
                }

                .control {
                    margin-top: 8px;
                    position: relative;

                    input {
                        padding-left: 36px;

                        &:focus {
                            border-color: $fade-grey;
                        }

                        &:focus + .search-icon svg {
                            stroke: $accent;
                        }
                    }

                    .search-icon {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 36px;
                        width: 36px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        svg {
                            height: 16px;
                            width: 16px;
                            stroke: $placeholder;
                            transition: stroke .3s;
                        }
                    }
                }
            }

            .suggestions {
                padding: 10px 0 0 0;

                .suggestion {
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid $fade-grey;
                    padding: 10px 0;

                    &:last-child {
                        border-bottom: none;
                    }

                    img {
                        display: block;
                        height: 36px;
                        width: 36px;
                        border-radius: 50%;
                    }

                    .meta {
                        margin-left: 10px;

                        span {
                            display: block;
                            font-size: .85rem;
                            font-weight: 500;
                        }
                    }

                    .button {
                        margin-left: auto;

                        &:hover {
                            border-color: $accent;
                            background: $accent;
                            color: $white-smoke;
                            box-shadow: $accent-box-shadow;
                        }
                    }
                }
            }
        }
    }
}

/* ==========================================================================
9. Photo Library
========================================================================== */

.image-grid-wrap {
    padding: 6px 0;

    .flex-1 {
        flex: 1;
    }

    .flex-2 {
        flex: 2;
    }

    .flex-3 {
        flex: 3;
    }

    .image-row {
        display: flex;
    }

    .image-row > div {
        position: relative;
        margin: 4px;
        height: 280px;
        border-radius: 2px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        cursor: pointer;

        &:hover {
            .overlay {
                opacity: .6;
                pointer-events: all;
            }

            .image-owner {
                .avatar {
                    transform: scale(1);
                }

                .name {
                    transform: translateY(0);
                    opacity: 1;
                }
            }

            .photo-time {
                transform: translateY(0);
                opacity: 1 !important;
            }

            .photo-like {
                transform: scale(1);
            }
        }

        .overlay {
            position: absolute;
            top: -4px;
            left: -4px;
            height: 100%;
            width: 100%;
            margin: 4px;
            background: $dark-text;
            opacity: 0;
            pointer-events: none;
            z-index: 1;
            transition: all .3s;
        }

        .image-owner {
            position: absolute;
            bottom: 14px;
            left: 14px;
            max-height: 40px;
            display: flex;
            align-items: center;
            z-index: 2;

            .avatar {
                display: block;
                height: 38px;
                width: 38px;
                border-radius: 50%;
                border: 1.4px solid $white;
                transform: scale(0);
                transition: all .3s;
            }

            .name {
                color: $white;
                font-size: .9rem;
                margin-left: 8px;
                opacity: 0;
                transform: translateY(5px);
                transition: all .3s;
            }
        }

        .photo-time {
            position: absolute;
            top: 20px;
            left: 14px;
            color: $white;
            font-size: .85rem;
            margin-left: 8px;
            opacity: 0;
            transform: translateY(5px);
            z-index: 2;
            transition: all .3s;
        }

        .photo-like {
            position: absolute;
            top: 14px;
            right: 14px;
            height: 38px;
            width: 38px;
            border-radius: 50%;
            background: $white;
            z-index: 2;
            transform: scale(0);
            transition: all .3s;

            &:hover {
                .inner {
                    svg {
                        stroke: $red;
                    }
                }
            }

            &.is-liked {
                box-shadow: $red-box-shadow;

                .inner {
                    .like-overlay {
                        transform: scale(1);
                    }

                    svg {
                        stroke: $white;
                        fill: $white;
                    }
                }
            }

            .inner {
                position: relative;
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                overflow: hidden;

                .like-overlay {
                    position: absolute;
                    top: -2px;
                    left: -2px;
                    height: calc(100% + 4px);
                    width: calc(100% + 4px);
                    border-radius: 50%;
                    background: darken($red, 8%);
                    transform: scale(0);
                    transition: all .3s;
                    z-index: 1;
                }

                svg {
                    position: relative;
                    height: 16px;
                    width: 16px;
                    stroke: $dark-text;
                    z-index: 2;
                    transition: all .3s;
                }
            }
        }
    }
}

/* ==========================================================================
10. Custom Lightbox
========================================================================== */

.custom-profile-lightbox {
    position: fixed;
    top: 58px;
    left: 0;
    height: calc(100% - 58px);
    width: 100%;
    background: $white;
    z-index: -1;
    transform: translateY(20px);
    opacity: 0;
    pointer-events: none;
    transition: transform .3s, opacity .3s;

    &.is-active {
        transform: translateY(0);
        opacity: 1;
        pointer-events: all;
        z-index: 50;
    }

    &.is-fullscreen {
        top: 0;
        height: 100%;
    }

    .lightbox-inner {
        position: relative;
        height: 100%;
        width: 100%;

        .image-side {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: calc(100% - 380px);
            background: $lightbox;

            .image-side-inner {
                position: relative;
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .image-wrap {
                    position: relative;
                    max-width: calc(100% - 160px);

                    &:hover {
                        .meta-overlay {
                            pointer-events: all;
                            opacity: .6;
                            z-index: 1;
                        }

                        .meta-block {
                            h3, p {
                                opacity: 1;
                                transform: translateY(0);
                            }
                        }

                        .action-block {
                            .action-button {
                                opacity: 1;
                                transform: translateY(0);
                                pointer-events: all;
                            }
                        }
                    }

                    .image-loader {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: darken($lightbox, 8%);
                        opacity: 0;
                        pointer-events: none;
                        z-index: 2;
                        transition: all .3s;

                        &.is-active {
                            pointer-events: all;
                            opacity: 1;
                        }

                        .loader {
                            height: 3rem;
                            width: 3rem;
                        }
                    }

                    .meta-overlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: darken($lightbox, 8%);
                        opacity: 0;
                        pointer-events: none;
                        z-index: -1;
                        transition: all .3s;
                    }

                    .meta-block {
                        position: absolute;
                        bottom: 20px;
                        left: 20px;
                        z-index: 2;

                        h3 {
                            font-family: 'Montserrat', sans-serif;
                            color: $color-white;
                            font-size: .8rem;
                            max-width: 440px;
                            opacity: 0;
                            transform: translateY(5px);
                            transition: all .3s;
                        }

                        p {
                            padding-top: 8px;
                            color: $color-white;
                            font-size: .8rem;
                            max-width: 540px;
                            opacity: 0;
                            transform: translateY(5px);
                            transition: all .3s;
                            transition-delay: .2s;
                        }
                    }

                    .action-block {
                        position: absolute;
                        top: 20px;
                        right: 20px;
                        z-index: 2;
                        display: flex;
                        align-items: center;

                        .action-button {
                            height: 36px;
                            width: 36px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 50%;
                            border: 1.2px solid $white;
                            margin: 0 5px;
                            opacity: 0;
                            transform: translateY(-5px);
                            pointer-events: none;
                            transition: all .3s;

                            &:hover {
                                svg {
                                    stroke: $primary;
                                }
                            }

                            &:nth-child(2) {
                                transition-delay: .1s;
                            }

                            &:nth-child(3) {
                                transition-delay: .2s;
                            }

                            &:nth-child(4) {
                                transition-delay: .3s;
                            }

                            svg {
                                height: 18px;
                                width: 18px;
                                stroke: $white;
                                transition: stroke .3s;
                            }
                        }
                    }

                    img {
                        display: block;
                        border-radius: 4px;
                    }
                }
            }
        }

        .sidebar-side {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 380px;
            background: $white;

            .comments-loader {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: $white;
                opacity: 0;
                pointer-events: none;
                z-index: 10;
                transition: all .3s;

                &.is-active {
                    pointer-events: all;
                    opacity: 1;
                }

                .loader {
                    height: 3rem;
                    width: 3rem;
                }
            }

            .header {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 12px;
                width: 100%;

                img {
                    height: 42px;
                    width: 42px;
                    border-radius: 50%;
                }

                .user-meta {
                    margin: 0 10px;

                    span {
                        display: block;

                        &:first-child {
                            font-size: .9rem;
                            font-weight: 500;

                            small {
                                color: $muted-grey;
                            }
                        }

                        &:nth-child(2) {
                            font-size: .8rem;
                            color: $muted-grey;
                        }
                    }
                }

                .button {
                    line-height: 0;
                    margin-left: auto;
                    padding: 14px 18px;
                }

                .dropdown {
                    .button {
                        padding: 18px 6px;
                        border: none;
                        background: transparent;

                        svg {
                            height: 18px;
                            width: 18px;
                        }

                        img {
                            border-radius: 50%;
                        }
                    }

                    .dropdown-menu {
                        margin-top: 10px;
                    }
                }
            }

            .inner-content {
                padding: 12px 12px 0 12px;

                .control {
                    width: 100%;

                    input {
                        padding-left: 34px;

                        &:focus {
                            + .icon svg {
                                stroke: $accent;
                            }
                        }
                    }

                    .icon {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 32px;
                        width: 32px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        svg {
                            height: 18px;
                            width: 18px;
                            stroke: $placeholder;
                            transition: all .3s;
                        }
                    }
                }
            }

            .live-stats {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 16px 0;
                border-bottom: 1px solid $fade-grey;

                .social-count {
                    display: flex;
                    align-items: stretch;

                    .shares-count, .comments-count, .likes-count, .views-count {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        margin: 0 3px;

                        span {
                            display: block;
                            font-size: .8rem;
                            color: $grey-text;
                            margin: 0 5px;

                            &.views {
                                margin: 0 2px;
                            }
                        }

                        svg {
                            height: 14px;
                            width: 14px;
                            stroke: $grey-text;
                        }
                    }
                }
            }

            .actions {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 16px 0;

                .action {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    span {
                        display: block;
                        font-size: .8rem;
                        margin: 0 4px;
                        transition: all .3s;
                    }

                    svg {
                        height: 16px;
                        width: 16px;
                        stroke: $grey-text;
                        transition: all .3s;
                    }

                    &:hover {
                        span {
                            color: $accent;
                        }

                        svg {
                            stroke: $accent;
                        }
                    }
                }
            }

            .comments-list {
                background: $light-grey;
                height: calc(100% - 232px);
                padding: 20px 14px;
                overflow-y: auto;

                .is-comment {
                    border: none !important;
                    padding-top: 0 !important;

                    &.is-nested {
                        margin-left: 40px;
                    }

                    .media-left {
                        margin-right: 10px;

                        img {
                            border-radius: 50%;
                        }
                    }

                    .media-content {
                        background: $white;
                        padding: 12px;
                        border-radius: 8px;

                        .username {
                            font-size: .8rem;
                            font-weight: 500;
                        }

                        p {
                            font-size: .75rem;
                            color: $muted-grey;
                        }

                        .comment-actions {
                            display: flex;
                            align-items: center;
                            padding-top: 8px;

                            span, a {
                                display: block;
                                font-size: .75rem;
                                color: $dark-text;
                            }

                            span {
                                margin: 0 10px;
                                color: $muted-grey;
                            }

                            .likes-count {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                margin-left: auto;

                                span {
                                    display: block;
                                    font-size: .75rem;
                                    color: $grey-text;
                                    margin: 0 5px;

                                    &.views {
                                        margin: 0 2px;
                                    }
                                }

                                svg {
                                    height: 12px;
                                    width: 12px;
                                    stroke: $grey-text;
                                }
                            }
                        }
                    }
                }
            }

            .comment-controls {
                position: absolute;
                bottom: 0;
                left: 0;
                background: lighten($light-grey, 2%);
                height: 50px;
                width: 100%;
                border-radius: 0 0 6px 0;
                border-top: 1px solid darken($light-grey, 8%);

                .controls-inner {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 0 16px;

                    img {
                        height: 32px;
                        width: 32px;
                        border-radius: 50%;
                    }

                    .control {
                        width: 100%;

                        .textarea, .emojionearea-editor {
                            resize: none;
                            height: 36px;
                            max-height: 36px;
                            min-height: 36px;
                            border-radius: 100px;
                            overflow: hidden;
                            line-height: 1.6;
                            font-size: .8rem;
                            padding-left: 16px;
                            margin: 0 6px;
                        }
                    }

                    .emojionearea-editor {
                        padding-left: 0 !important;

                        img {
                            height: 18px;
                            width: 18px;
                            min-height: 18px;
                            max-height: 18px;
                        }
                    }

                    .emojionearea {
                        overflow: visible !important;
                    }

                    .emojionearea-picker {
                        top: -230px;
                        position: absolute;
                        left: -50px;
                        width: 310px;

                        .emojionearea-wrapper {
                            width: 310px;

                            img {
                                height: 22px;
                                width: 22px;
                                min-height: 22px;
                                max-height: 22px;
                            }
                        }
                    }

                    .emojionearea-scroll-area {
                        width: 310px;
                    }

                    .emojionearea .emojionearea-button > div.emojionearea-button-open {
                        background-position: 0 -22px;
                    }

                    .emojionearea .emojionearea-picker .emojionearea-scroll-area .emojibtn {
                        width: 24px !important;
                        height: 24px !important;
                    }
                }
            }
        }
    }
}

/* ==========================================================================
11. Profile friends
========================================================================== */

.friends-grid {
    padding: 20px 0;

    .columns {
        .column {
            padding: .5rem;
        }
    }

    .friend-item {
        display: block;
        width: 100%;
        padding: 30px;
        border: 1px solid $fade-grey;
        border-radius: 6px;
        background: $white;
        transition: all .3s;

        &:hover {
            box-shadow: $light-box-shadow;

            .avatar-wrap {
                .circle {
                    transform: scale(1);
                }

                .chat-button {
                    transform: scale(1);
                    opacity: 1;
                }
            }
        }

        .avatar-wrap {
            position: relative;
            height: 96px;
            width: 96px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;

            .circle {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                border-radius: 50%;
                border: 1.4px solid $placeholder;
                transform: scale(0.4);
                transition: all .3s;
                z-index: 1;
            }

            .chat-button {
                position: absolute;
                bottom: 0;
                right: 0;
                height: 36px;
                width: 36px;
                border-radius: 50%;
                border: 3px solid $white;
                background: $accent;
                display: flex;
                justify-content: center;
                align-items: center;
                transform: scale(0);
                opacity: 0;
                transition: all .3s;
                z-index: 3;

                svg {
                    height: 14px;
                    width: 14px;
                    stroke: $white;
                    fill: $white;
                }
            }

            img {
                position: relative;
                display: block;
                height: 80px;
                width: 80px;
                border-radius: 50%;
                z-index: 2;
            }
        }

        h3 {
            font-family: 'Montserrat', sans-serif;
            color: $dark-text;
            font-size: .9rem;
            font-weight: 500;
            line-height: 1.2;
            margin-top: 6px;
        }

        p {
            color: $muted-grey;
            font-size: .8rem;
        }
    }
}

/* ==========================================================================
12. Media Queries
========================================================================== */

@media (max-width: 767px) {
    .cover-bg {
        .cover-image {
            min-height: 180px !important;
        }
    }

    .profile-subheader {
        justify-content: center !important;
        margin-top: 50px !important;
    }

    .profile-about {
        flex-direction: column;
        margin-top: -30px;
        padding: 20px 0;

        .left-menu {
            width: 100%;
            max-width: 100%;
            min-width: 100%;
            margin-bottom: 20px;

            .left-menu-inner {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .menu-item {
                    padding: 0 10px;

                    .menu-icon {
                        padding: 0 16px;
                    }

                    span {
                        display: none;
                    }
                }
            }
        }

        .right-content {
            padding: 0;
            width: 100% !important;
        }
    }

    .about-timeline {
        .timeline-item {
            .button {
                display: none;
            }
        }
    }

    .about-card {
        .video-wrapper {
            width: calc(50% - 8px) !important;
        }

        .place-wrapper {
            width: calc(100% - 8px) !important;
        }
    }

    .community-content {
        flex-direction: column-reverse;

        .community-widgets {
            margin-bottom: 20px;
        }

        .community-info, .community-widgets {
            width: 100% !important;
        }
    }

    .custom-profile-lightbox {
        .lightbox-inner {
            .image-side {
                width: 100%;
                height: 180px;

                .image-side-inner {
                    .image-wrap {
                        max-width: calc(100% - 80px);
                    }
                }
            }

            .sidebar-side {
                width: 100%;
                top: 180px;
                height: calc(100% - 180px);
                z-index: 10;
                background: $white;
            }
        }
    }

    .image-grid-wrap .image-row > div {
        min-height: 280px;
    }
}

@media screen and (max-width: 400px) {
    .image-row {
        flex-direction: column;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .profile-about {
        .left-menu {
            max-width: 220px;
            min-width: 220px;
        }

        .right-content {
            width: calc(100% - 220px) !important;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .cover-bg {
        .cover-image {
            min-height: 180px !important;
        }
    }

    .profile-subheader {
        justify-content: center !important;
        margin-top: 50px !important;
    }

    .profile-about {
        flex-direction: column;
        margin-top: -30px;
        padding: 20px 0;

        .left-menu {
            width: 100%;
            max-width: 100%;
            min-width: 100%;
            margin-bottom: 20px;

            .left-menu-inner {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .menu-item {
                    padding: 0 10px;

                    .menu-icon {
                        padding: 0 16px;
                    }
                }
            }
        }

        .right-content {
            padding: 0;
            width: 100% !important;
        }
    }

    .custom-profile-lightbox {
        .lightbox-inner {
            .image-side {
                width: 100%;
                height: 340px;

                .image-side-inner {
                    .image-wrap {
                        max-width: calc(100% - 80px);
                    }
                }
            }

            .sidebar-side {
                width: 100%;
                top: 340px;
                height: calc(100% - 340px);
                z-index: 10;
                background: $white;
            }
        }
    }

    .friends-grid {
        .columns {
            display: flex;

            .friend-item {
                min-height: 216px;
            }
        }
    }
}
