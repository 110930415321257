/*! _uploaders.scss | Friendkit | © Css Ninja. 2020-2021 */

/* ==========================================================================
Uploaders styles
========================================================================== */

/*
    1. Modal uploader (Album upload)
*/

/* ==========================================================================
1. Modal uploader (Album upload)
========================================================================== */

.modal-uploader {
    .button {
        line-height: 0;

        &.has-icon {
            svg {
                height: 18px;
                width: 18px;
            }
        }
    }

    .file-count {
        font-size: .85rem;
        color: $muted-grey;
        height: 38px;
        vertical-align: middle;
        display: inline-flex;
        align-items: center;
        margin: 0 10px;

        span {
            margin: 0 4px;
        }
    }

    #total-progress {
        opacity: 0;
        transition: opacity 0.3s linear;
    }

    #previews .dz-success .progress {
        opacity: 0;
        transition: opacity 0.3s linear;
    }

    #previews .delete {
        display: none;
    }

    #previews .is-template.dz-success .start,
    #previews is-template.dz-success .cancel {
        display: none;
    }

    #previews is-template.dz-success .delete {
        display: block;
    }

    .preview-box {
        position: relative;
        background: $white;
        width: 100%;
        border-radius: 3px;

        &:hover {
            .remove-button {
                opacity: 1;
                background: lighten($placeholder, 10%);
            }
        }

        .remove-button {
            position: absolute;
            top: -10px;
            right: -12px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            border: 3px solid $white;
            background: transparent;
            cursor: pointer;
            opacity: 0;

            &:hover {
                background: $red;

                i {
                    color: $white-light;
                }
            }

            i {
                font-size: 14px;
                color: $muted-grey;
            }
        }

        .preview img {
            display: block;
        }

        .preview-body {
            padding: 8px;
            border: 1px solid $fade-grey;

            .name {
                display: block;
                max-width: 145px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .item-meta {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: .85rem;
                font-weight: 500;
            }

            .textarea {
                padding: 0;
                border: none;
                resize: none;
                color: $muted-grey;
            }
        }
    }

    progress {
        vertical-align: baseline;
    }

    .progress {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 0.2rem;
        margin: .4rem 0;
        overflow: hidden;
        font-size: 0.75rem;
        background-color: #e9ecef;
        border-radius: 0.25rem;
    }

    .progress-bar {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        color: #fff;
        text-align: center;
        background-color: $accent;
        transition: width 0.6s ease;
    }

    .progress-bar-striped {
        background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
        background-size: 1rem 1rem;
    }

    .progress-bar-animated {
        -webkit-animation: progress-bar-stripes 1s linear infinite;
        animation: progress-bar-stripes 1s linear infinite;
    }

    .upload-item-actions {
        margin-left: 20px;
    }

    .upload-item-progress {
        margin-left: auto;
        min-width: 120px;
    }
}
