/*! all.scss | Friendkit | © Css Ninja. 2020-2021 */

/* ==========================================================================
Core SCSS file
========================================================================== */

/*
    0. Components imports
*/

/* ==========================================================================
0. Components imports
========================================================================== */

@import "./pageloader";
@import "./hamburger";
@import "./cards";
@import "./widgets";
@import "./buttons";
@import "./dropdowns";
@import "./emoji-picker";
@import "./tabs";
@import "./modals";
@import "./uploaders";
@import "./video";
@import "./datepicker";
@import "./forms";
@import "./radios";
@import "./switch";
@import "./popovers";
@import "./tooltips";
@import "./checkboxes";
@import "./tables";
@import "./animations";
@import "./placeholders";
@import "./utils";